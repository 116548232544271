import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { IUser } from './../shared/interfaces/user/user.interface';
import { UserService } from './../shared/services/user/user.service';

@Injectable()
export class UserResolver {
  constructor(private userService: UserService) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
      Observable<IUser> {
      return this.userService.getUserData();
  }
}
