import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {CxFloatingButtonModule} from "@bbraun/cortex/floating-button";

import { MaterialModule } from '../../shared/modules/material/material.module';
import { SharedModule } from '../../shared/shared.module';
import {
    AdminActiveProjectsComponent
} from './admin-projects-tabs-menu/admin-active-projects/admin-active-projects.component';
import {
    AdminFavoritesComponent
} from './admin-projects-tabs-menu/admin-favorites/admin-favorites.component';
import { AdminLockedProjectsComponent } from './admin-projects-tabs-menu/admin-locked-projects/admin-locked-projects.component';
import { ProjectsComponent } from './projects.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        CxFloatingButtonModule
    ],
  declarations: [
    ProjectsComponent,
    AdminActiveProjectsComponent,
    AdminFavoritesComponent,
    AdminLockedProjectsComponent
  ],
  exports: [
    ProjectsComponent
  ]
})
export class ProjectsModule { }
