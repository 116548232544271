<div class="container-form">
    <div class="top-margin-form full-width">
        <h3>Chargeable activities will be closed for further modifications</h3>
        <h3> They will be exported in a file and later imported in the central controlling system</h3>
        <span *ngIf="exportData">Last export done by <strong>{{exportData.user.username}}</strong> on <strong>{{exportData.exportDate | date:'dd/MM/yyyy'}}</strong></span>
        <span *ngIf="!exportData">Nothing has been exported yet</span>
    </div>
    <app-button (click)="onClick()" [icon]="'get_app'" [submit]="true"></app-button>
</div>

<app-footer-admin></app-footer-admin>
