import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { messages } from '../../modules/shared/constants/mesages.constants';
import * as tableParams from '../../modules/shared/constants/parameters.for.calls.constants';
import * as fromApp from '../../store';
import { ForceHide, SetTableLoading } from '../actions/layout.actions';
import { MemberService } from './../../modules/member/services/member.service';
import { SnackBarService } from './../../modules/shared/services/snack-bar/snack-bar.service';
import * as activitiesNotExportedActions from './../actions/activities-not-exported.actions';
import { LoadActivitiesNotExported, SearchActivitiesNotExported } from './../actions/activities-not-exported.actions';

@Injectable()
export class ActivitiesNotExportedEffects {

  constructor(
    private actions$: Actions,
    private memberService: MemberService,
    private store: Store<fromApp.AppState>,
    private snackBarService: SnackBarService
    ) { }

  loadActivitiesNotExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(activitiesNotExportedActions.LoadActivitiesNotExported),
    switchMap((action) => {
      this.store.dispatch(SetTableLoading({loading: true}));
      this.store.dispatch(ForceHide({forceHide: true}));
      return this.memberService
        .getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          false,
          action.startDate,
          action.endDate)
        .pipe(
          map(data => {
            this.store.dispatch(SetTableLoading({loading: false}));
            this.store.dispatch(ForceHide({forceHide: false}));
            return activitiesNotExportedActions.LoadActivitiesNotExportedDataSuccessAction({
              notExportedTableItems: data
            });
          }),
          catchError((err, caught) => {
            return EMPTY;
          }));
    })
  ));

  scrollActivitiesNotExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(activitiesNotExportedActions.ScrollActivitiesNotExported),
    switchMap((action) => {
      this.store.dispatch(SetTableLoading({loading: true}));
      return this.memberService
        .getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          false,
          action.startDate,
          action.endDate)
        .pipe(
          map(data => {
            this.store.dispatch(SetTableLoading({loading: false}));
            return activitiesNotExportedActions.LoadActivitiesNotExportedDataSuccessAction({
              notExportedTableItems: data
            });
          }),
          catchError((err, caught) => {
            return EMPTY;
          }));
    })
  ));

  sortActivitiesNotExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(activitiesNotExportedActions.SortActivitiesNotExported),
    switchMap((action) => {
      return this.memberService
        .getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          false,
          action.startDate,
          action.endDate)
        .pipe(
          map(data => {
            return activitiesNotExportedActions.LoadActivitiesNotExportedDataSuccessAction({
              notExportedTableItems: data
            });
          }),
          catchError((err, caught) => {
            return EMPTY;
          }));
    })
  ));

  searchActivitiesNotExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(activitiesNotExportedActions.SearchActivitiesNotExported),
    switchMap((action) => {
      this.store.dispatch(SetTableLoading({loading: true}));
      this.store.dispatch(ForceHide({forceHide: true}));
      return this.memberService
        .getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          false,
          action.startDate,
          action.endDate,
          action.search)
        .pipe(
          map(data => {
            this.store.dispatch(SetTableLoading({loading: false}));
            this.store.dispatch(ForceHide({forceHide: false}));
            return activitiesNotExportedActions.LoadActivitiesNotExportedDataSuccessAction({
              notExportedTableItems: data
            });
          }),
          catchError((err, caught) => {
            return EMPTY;
          }));
    })
  ));

  searchSortActivitiesNotExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(activitiesNotExportedActions.SearchSortActivitiesNotExported),
    switchMap((action) => {
      return this.memberService
        .getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          false,
          action.startDate,
          action.endDate,
          action.search)
        .pipe(
          map(data => {
            return activitiesNotExportedActions.LoadActivitiesNotExportedDataSuccessAction({
              notExportedTableItems: data
            });
          }),
          catchError((err, caught) => {
            return EMPTY;
          }));
    })
  ));

  searchScrollActivitiesNotExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(activitiesNotExportedActions.SearchScrollActivitiesNotExported),
    switchMap((action) => {
      this.store.dispatch(SetTableLoading({loading: true}));
      return this.memberService
        .getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          false,
          action.startDate,
          action.endDate,
          action.search)
        .pipe(
          map(data => {
            this.store.dispatch(SetTableLoading({loading: false}));
            return activitiesNotExportedActions.LoadActivitiesNotExportedDataSuccessAction({
              notExportedTableItems: data
            });
          }),
          catchError((err, caught) => {
            return EMPTY;
          }));
    })
  ));

  deleteActivitiy$ = createEffect(() => this.actions$.pipe(
    ofType(activitiesNotExportedActions.DeleteEntryAction),
    switchMap((action) => {
      this.store.dispatch(SetTableLoading({loading: true}));
      this.store.dispatch(ForceHide({forceHide: true}));
      return this.memberService
      .removeEntry(action.projectId, action.entryId)
        .pipe(
          map(_ => {
            this.snackBarService.openSimpleSnackBar(`${messages.activity} deleted`, 2000);
            this.store.dispatch(SetTableLoading({loading: false}));
            this.store.dispatch(ForceHide({forceHide: false}));
            if (action.isSearching) {
              return SearchActivitiesNotExported(
                {
                  page: tableParams.parametersForNotExported.page,
                  size: tableParams.parametersForNotExported.size,
                  sort: action.sort,
                  startDate: action.startDate,
                  endDate: action.endDate,
                  search: action.search
                }
            );
            }
            return LoadActivitiesNotExported(
              {
                page: tableParams.parametersForNotExported.page,
                size: tableParams.parametersForNotExported.size,
                sort: action.sort,
                startDate: action.startDate,
                endDate: action.endDate
              }
          );
          }),
          catchError((err, caught) => {
            return EMPTY;
          }));
    })
  ));
}
