import {CommonModule, DatePipe} from '@angular/common';
import { NgModule } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";

import {CxDialogService} from "@bbraun/cortex/dialog";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import {MY_DATE_FORMATS} from "../../constants/date-format.constants";
import { HttpParamsFactory } from '../shared/factory/http-params/http-params.factory';
import { PagingFactory } from '../shared/factory/paging/paging.factory';
import { BackendDataService } from '../shared/services/backend-data-service/backend-data-service.service';
import { SharedModule } from '../shared/shared.module';
import { MemberRoutingModule } from './member.routing.module';
import { MemberProjectsModule } from './member-projects/member-projects.module';
import { NewEntryTimeComponent } from './new-entry-time/new-entry-time.component';
import { MemberEntryAndProjectResolver } from './resolvers/member-entry-and-project.resolver';
import { MemberProjectResolver } from './resolvers/member-project.resolver';
import { MemberService } from './services/member.service';

@NgModule({
  imports: [
    CommonModule,
    MemberRoutingModule,
    MemberProjectsModule,
    NgxMaterialTimepickerModule,
    SharedModule
  ],
  declarations: [
    NewEntryTimeComponent
  ],
  providers: [
    MemberService,
    HttpParamsFactory,
    PagingFactory,
    BackendDataService,
    MemberEntryAndProjectResolver,
    MemberProjectResolver,
    CxDialogService,
    DatePipe,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ]
})
export class MemberModule { }
