import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { IMemberProject } from '../../shared/interfaces/member/member-projects.interface';
import { MemberService } from '../services/member.service';


@Injectable()
export class MemberProjectResolver {
  constructor(
    private memberService: MemberService
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
      Observable<IMemberProject> {
        return this.memberService.getProject(route.params.projectId);
  }
}
