import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CxDialogService } from "@bbraun/cortex/dialog";

import { messages } from '../../constants/mesages.constants';

@Injectable()
export class AppNavigateService {

  constructor(private location: Location,
              private router: Router,
              private dialogService: CxDialogService) {
  }

  public goBack(): void {
    this.location.back();
  }

  public goBackWithConfirmation(): void {
    const title = 'Are you sure you want to go back';
    const message = 'Click Cancel if you want to stay on the screen, or Yes to go back';
    const confirmAction = messages.confirmation;
    const cancelAction = messages.cancel;

    this.dialogService.openConfirmDialog(title, message, confirmAction, cancelAction)
      .subscribe((responseFromDialog: boolean) => {
        if (responseFromDialog) {
          this.location.back();
        } else {
          return;
        }
    });
  }

  public goToRouteWithConfirmation(url: string): void {
    const title = 'Are you sure you want to go back';
    const message = 'Click Cancel if you want to stay on the screen, or Yes to go back';

    this.dialogService.openConfirmDialog(title, message, 'Yes').subscribe((responseFromDialog: boolean) => {
      if (responseFromDialog) {
        this.router.navigate([url]);
      } else {
        return;
      }
    });
  }

  public goHome(): void {
    this.router.navigate(['/']);
  }

  public goToUrl(route: string, params?: Array<string>): void {
    let commands: Array<any>;
    if (params) {
      commands = [route, ...params];
    } else {
      commands = [route];
    }

    this.router.navigate(commands);
  }
}
