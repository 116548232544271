import { createReducer, on } from '@ngrx/store';

import { parametersForAdminActiveTable } from '../../modules/shared/constants/parameters.for.calls.constants';
import * as AdminProjectsActiveActions from '../actions/admin-projects-active.actions';
import { IAdminProjectDataContentSets } from './../../modules/shared/interfaces/admin/admin-project-set-paging.interface';

export interface AdminPojectsActiveState {
  value: IAdminProjectDataContentSets;
  sort: string;
  isScrolling: boolean;
  isSearching: boolean;
  searchParam: string;
}

const initialState: AdminPojectsActiveState = {
    value: {
      content: [],
      first: true,
      last: false,
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 1,
      sort: {
        ascending: true,
        descending: false,
        direction: 'ASC',
        ignoreCase: false,
        nullHandling: 'NATIVE',
        property: 'lastExported'
      }
    },
    sort: parametersForAdminActiveTable.sort,
    isScrolling: false,
    searchParam: '',
    isSearching: false
};

export const adminProjectsActiveDataReducer = createReducer<AdminPojectsActiveState>(
  initialState,
  on(AdminProjectsActiveActions.LoadAdminProjectsActive, (state: AdminPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false,
      isSearching: false,
      searchParam: ''
    };
  }),
  on(AdminProjectsActiveActions.SortAdminProjectsActive, (state: AdminPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(AdminProjectsActiveActions.ScrollAdminProjectsActive, (state: AdminPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: true,
      isSorting: false
    };
  }),
  on(AdminProjectsActiveActions.SearchAdminProjectsActive, (state: AdminPojectsActiveState, { search }) => {
    return {
      ...state,
      isScrolling: false,
      isSearching: true,
      searchParam: search
    };
  }),
  on(AdminProjectsActiveActions.SearchSortAdminProjectsActive, (state: AdminPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(AdminProjectsActiveActions.SearchScrollAdminProjectsActive, (state: AdminPojectsActiveState, { search }) => {
    return {
      ...state,
      isScrolling: true,
      isSearching: true,
      searchParam: search
    };
  }),
  on(AdminProjectsActiveActions.LoadAdminProjectsActiveDataSuccessAction, (
    state: AdminPojectsActiveState, {adminProjectActiveTableItems}) => {
    const value = {...adminProjectActiveTableItems};

    if (state.isScrolling) {
      value.content =
        state.value.content.concat(value.content);
    }

    return {
      ...state,
      value
    };
  })
);

export const getAdminTableProjectsActive = (state: AdminPojectsActiveState) => state;
export const getAdminTableSortSearchParamActive = (state: AdminPojectsActiveState) => {
  return {
  sort: state.sort,
  searchParam: state.searchParam
  };
};
