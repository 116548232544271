import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {SetHeaderWithBack} from 'src/main/typescript/app/store/actions/header-options.action';

import * as fromApp from '../../../store';
import { CreateCostsCenter, UpdateCostsCenter } from '../../../store/actions/costs-center.actions';
import { messages } from '../../shared/constants/mesages.constants';
import { ICostsCenter } from '../../shared/interfaces/ICostsCenter';
import { IUser } from '../../shared/interfaces/user/user.interface';
import { projects } from '../../shared/models/router.constants';
import { SnackBarService } from '../../shared/services/snack-bar/snack-bar.service';
import {IEntryFormBody} from "../../shared/interfaces/member/entry-form-object.interface";
import {SetUnsavedChangesAction} from "../../../store/actions/layout.actions";

@Component({
  selector: 'app-create-edit-costs-center',
  templateUrl: './create-edit-costs-center.component.html',
  styleUrls: ['./create-edit-costs-center.component.sass']
})
export class CreateEditCostsCenterComponent implements OnInit, OnDestroy {

  public mainForm: UntypedFormGroup;
  public costsCenter: ICostsCenter;
  public paramFromURL = window.location.href.split('/')[window.location.href.split('/').length - 1];

  private onDestroy$ = new Subject<void>();
  private costsCenters: Array<ICostsCenter>;
  private formDirtyFlag = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.paramFromURL === 'new') {
      this.store.dispatch(SetHeaderWithBack({ title: 'New' }));
    }
    this.store.select(fromApp.getUserData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((user: IUser) => {
      this.costsCenters = user.costsCenters.map(x => x);
      // check if costsCenter exists
      if (this.paramFromURL !== 'new') {
        this.costsCenter = user.costsCenters.find(x => x.costsCenterId === this.paramFromURL);
        // remove current costs center from array because of duplication of label
        const index = this.costsCenters.indexOf(this.costsCenter);
        if (index > -1) {
          this.costsCenters.splice(index, 1);
        }
        this.costsCenter ?
          this.store.dispatch(SetHeaderWithBack({ title: this.costsCenter.costsCenterLabel }))
          : this.router.navigate([projects.costsCenters, 'new']);
      }
    });
    this.buildForm();

      this.mainForm.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((res: IEntryFormBody) => {
              if (this.mainForm.dirty && !this.formDirtyFlag) {
                  this.areUnsavedChanges(true);
                  this.formDirtyFlag = true;
              }
          });
  }

  ngOnDestroy() {
    this.areUnsavedChanges(false);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

    private areUnsavedChanges(value: boolean): void {
        this.store.dispatch(SetUnsavedChangesAction({areUnsavedChanges: value}));
    }

  private buildForm() {
    this.mainForm = this.fb.group(
      {
        costsCenterId: [this.costsCenter ? this.costsCenter.costsCenterId : '', [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]],
        costsCenterLabel: [this.costsCenter ? this.costsCenter.costsCenterLabel : '', [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]]
      }
    );
  }

  public onSubmit() {
    if (this.mainForm.valid) {
      const formValue: ICostsCenter = this.mainForm.value;
      if (this.costsCenters.find(
        x => x.costsCenterLabel === formValue.costsCenterLabel)) {
          this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} label duplication`, 2000);
        return;
      }
      // check if we are in edit or create mode
      this.paramFromURL === 'new' ?
      this.store.dispatch(CreateCostsCenter({ costsCenter: this.mainForm.value })) :
      this.store.dispatch(UpdateCostsCenter({
        costsCenter: this.mainForm.value,
        id: this.paramFromURL
      }));
    }
  }

}
