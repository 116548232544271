import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import * as moment from "moment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SetHeaderForActivityCreate, SetHeaderForEntry } from '../../../store/actions/header-options.action';
import { SetHeader } from '../../../store/actions/header-options.action';
import { SetUnsavedChangesAction } from '../../../store/actions/layout.actions';
import { messages } from '../../shared/constants/mesages.constants';
import { IEntryFormBody } from '../../shared/interfaces/member/entry-form-object.interface';
import { IMembersDataContent } from '../../shared/interfaces/member/member.data.content.interface';
import { IMemberProject } from '../../shared/interfaces/member/member-projects.interface';
import { IUser } from '../../shared/interfaces/user/user.interface';
import { CreateNewEntryFormModel } from '../../shared/models/create-new-entry.model';
import { MinuteSecondsPipe } from '../../shared/pipes/minuteToHoursAndMinutes.pipe';
import { SnackBarService } from '../../shared/services/snack-bar/snack-bar.service';
import { MemberService } from '../services/member.service';
import * as fromApp from './../../../store';

@Component({
  selector: 'app-new-entry-time',
  templateUrl: './new-entry-time.component.html',
  styleUrls: ['./new-entry-time.component.sass'],
  providers: [MinuteSecondsPipe]
})
export class NewEntryTimeComponent implements OnInit, OnDestroy {
  constructor(
    private fb: UntypedFormBuilder,
    private memberService: MemberService,
    private snackBarService: SnackBarService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private routerSnapshot: ActivatedRoute,
    private minutesPipe: MinuteSecondsPipe
  ) {}

  public defaultDate: Date | string;
  public defaultTime: string;

  public buttonDisabled = false;
  public mainForm: UntypedFormGroup;
  public mainFormObject: IEntryFormBody;

  // edit mode resolver
  private resolvedEdit: [IMembersDataContent, IMemberProject] = this.routerSnapshot.snapshot.data['edit'];
  public projectEdit: IMemberProject = this.resolvedEdit ? this.resolvedEdit[1] : null;
  private entryEdit: IMembersDataContent = this.resolvedEdit ? this.resolvedEdit[0] : null;

  // copy mode resolver
  public resolvedCopy: [IMembersDataContent, IMemberProject] = this.routerSnapshot.snapshot.data['copy'];
  public projectCopy: IMemberProject = this.resolvedCopy ? this.resolvedCopy[1] : null;
  private entryCopy: IMembersDataContent = this.resolvedCopy ? this.resolvedCopy[0] : null;

  // new mode resolver
  public projectNew: IMemberProject = this.routerSnapshot.snapshot.data['project'];

  public userInfoData: IUser = { user: {chargeable: false}};
  private formDirtyFlag = false;
  private onDestroy$ = new Subject<void>();
  private previousRoute: string;

  ngOnInit() {
    this.dispachHeaderOptions();
    this.getPreviousRoute();
    this.getUserInfo();
      if (!this.projectEdit && !this.resolvedCopy) {
          this.subscribeToOnTimeTracker();
      }
    this.buildForm();

    this.mainFormObject = this.mainForm.getRawValue();

    this.mainForm.valueChanges
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((res: IEntryFormBody) => {
      this.mainFormObject = {
        chargeable: this.mainFormObject?.chargeable,
        ...res
      };
      this.mainFormObject.description.length ? this.store.dispatch(SetHeader({
        title: this.mainFormObject?.description
      })) :
      this.store.dispatch(SetHeader({title:'New ' + messages.activity}));

      if (this.mainForm.dirty && !this.formDirtyFlag) {
        this.areUnsavedChanges(true);
        this.formDirtyFlag = true;
      }
    });
  }

  ngOnDestroy() {
    this.areUnsavedChanges(false);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onSubmit() {
    if (this.projectEdit && this.mainForm.valid && !this.buttonDisabled) {
      this.buttonDisabled = true;
      const sendFormObj =
            new CreateNewEntryFormModel(
              this.mainFormObject,
              this.projectEdit?.project?.projectId,
              this.entryEdit.entryId);
      this.memberService.updateActivity(sendFormObj, this.projectEdit?.project?.projectId, this.entryEdit.entryId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.snackBarService.openSimpleSnackBar(`${messages.activity} updated`, 2000);
          this.router.navigate([this.previousRoute]);
        },
        (err) => {
            this.buttonDisabled = false;
            this.snackBarService.openSimpleSnackBar(messages.contactAdmin, 2000);
        }
      );
    } else if (this.resolvedCopy && this.mainForm.valid && !this.buttonDisabled) {
      this.buttonDisabled = true;
      const sendFormObj =
              new CreateNewEntryFormModel(
                this.mainFormObject,
                this.projectCopy?.project?.projectId,
                null,
                this.projectCopy?.project?.title);
      this.memberService.createNewEntry(sendFormObj, this.projectCopy?.project?.projectId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.snackBarService.openSimpleSnackBar(`${messages.activity} created`, 2000);
          this.router.navigate([this.previousRoute]);
        },
        (err) => {
            this.buttonDisabled = false;
            this.snackBarService.openSimpleSnackBar(messages.contactAdmin, 2000);
        }
      );
    } else if (this.projectNew && this.mainForm.valid && !this.buttonDisabled) {
      this.buttonDisabled = true;
      const sendFormObj =
        new CreateNewEntryFormModel(
          this.mainFormObject,
          this.projectNew?.project?.projectId,
          null,
          this.projectNew?.project?.title);
      this.memberService.createNewEntry(sendFormObj, this.projectNew?.project?.projectId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.snackBarService.openSimpleSnackBar(`${messages.activity} created`, 2000);
          this.router.navigate([this.previousRoute]);
        },
        (err) => {
            this.buttonDisabled = false;
            this.snackBarService.openSimpleSnackBar(messages.contactAdmin, 2000);
        }
      );
    } else {
      this.buttonDisabled = false;
    }
  }

  private getPreviousRoute() {
    this.store.select(fromApp.getLayoutPreviousRoute)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((url: string) => {
     this.previousRoute = url;
    });
  }
  private getUserInfo() {
    this.store.select(fromApp.getUserData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((data: IUser) => {
      if (data.user) {
        this.userInfoData = data;
      }
    });
  }

  private subscribeToOnTimeTracker() {
      this.store.select(fromApp.getTimeData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.defaultTime = res;
        this.mainForm?.controls['loggedTime']?.setValue(res);
      });
  }

  private buildForm() {
    if (this.resolvedEdit) {
      this.defaultTime = this.minutesPipe.transform(this.entryEdit.loggedTime);

      this.mainForm = this.fb.group(
        {
          title: new UntypedFormControl({value: this.projectEdit?.project?.title, disabled: true}),
          chargeable: new UntypedFormControl({value:
            this.projectEdit?.project?.chargeable,
            disabled: true}),
          creationDate: [
            moment.utc(new Date(this.entryEdit.creationDate)), [
            Validators.required
          ]],
          loggedTime: [this.defaultTime, [
            Validators.required,
            this.customValidatorDate
          ]],
          description: [this.entryEdit.description, [
            Validators.required
          ]]
        }
      );
    } else if (this.resolvedCopy) {
      this.defaultTime = this.minutesPipe.transform(this.entryCopy.loggedTime);

      this.mainForm = this.fb.group(
        {
          title: new UntypedFormControl({value: this.projectCopy?.project?.title, disabled: true}),
          chargeable: new UntypedFormControl({value: this.projectCopy?.project?.chargeable,
            disabled: true}),
          creationDate: [
            moment.utc(new Date(this.entryCopy?.creationDate)), [
            Validators.required
          ]],
          loggedTime: [this.defaultTime, [
            Validators.required,
            this.customValidatorDate
          ]],
          description: [this.entryCopy.description, [
            Validators.required
          ]]
        }
      );
    } else if (this.projectNew) {
      // new mode
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear();
      const date = today.getDate();

      this.mainForm = this.fb.group(
        {
          title: {value: this.projectNew?.project?.title, disabled: true},
          chargeable: new UntypedFormControl({
              value: this.projectNew?.project?.chargeable,
              disabled: true}),
          creationDate: [moment(this.defaultDate), [
            Validators.required
          ]],
          loggedTime: [this.defaultTime, [
            Validators.required,
            this.customValidatorDate
          ]],
          description: ['', [
            Validators.required
          ]]
        });
    }
  }

  private customValidatorDate(control: AbstractControl) {
    if (typeof control.value === 'string' && control.value.includes('00:00')) {
      return { hour: true };
    }

    return null;
  }

  private areUnsavedChanges(value: boolean): void {
    this.store.dispatch(SetUnsavedChangesAction({areUnsavedChanges: value}));
  }

  private dispachHeaderOptions() {
    if (this.entryEdit) {
      this.store.dispatch(SetHeaderForEntry({ title: this.entryEdit.description }));
    } else {
      this.store.dispatch(SetHeaderForActivityCreate());
    }
  }
}
