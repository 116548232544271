import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {CxDialogService} from "@bbraun/cortex/dialog";
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromApp from '../../store';
import { DeleteCostsCenter } from '../../store/actions/costs-center.actions';
import { SetHeaderWithOnlyTitle } from '../../store/actions/header-options.action';
import { messages } from '../shared/constants/mesages.constants';
import { ICostsCenter } from '../shared/interfaces/ICostsCenter';
import { IUser } from '../shared/interfaces/user/user.interface';
import { projects } from '../shared/models/router.constants';

@Component({
  selector: 'app-costs-centers-maintenance',
  templateUrl: './costs-centers-maintenance.component.html',
  styleUrls: ['./costs-centers-maintenance.component.sass']
})
export class CostsCentersMaintenanceComponent implements OnInit, OnDestroy {

  public costsCenters: Array<ICostsCenter>;
  public displayedColumns = ['costsCenterId', 'costsCenterLabel', 'costCenterChargeable', 'deleteCostsCenter', 'goTo'];

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<fromApp.AppState>,
        private dialogService: CxDialogService,
        private router: Router
  ) { }

  ngOnInit() {
    this.store.dispatch(SetHeaderWithOnlyTitle({ title:messages.costsCenter }));
    this.store.select(fromApp.getUserData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((user: IUser) => {
      this.costsCenters = user.costsCenters;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onGoToCostsCenter(id: number) {
    this.router.navigate([projects.costsCenters + '/' + id]);
  }

  public onDeleteCostsCenter(id: number) {
    const title = '';
    const message = `Are you sure you want to delete this ${messages.costsCenter}?`;
    const confirmAction = messages.confirmation;
    const cancelAction = messages.cancel;

    this.dialogService.openDeleteDialog(title, message, confirmAction, cancelAction)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          if (res) {
              this.store.dispatch(DeleteCostsCenter({ id: id }));
            }
          });
  }

  public redirectToNewCostsCenter() {
    this.router.navigate([projects.costsCenters + '/new']);
  }

}
