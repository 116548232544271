import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { BackendDataService } from '../../shared/services/backend-data-service/backend-data-service.service';
import { IAdminExported } from './../../shared/interfaces/admin/admin-exported.interface';

@Injectable()
export class AdminExportService {

  constructor(
    private backendDataService: BackendDataService
  ) { }

  public getLastExported(): Observable<IAdminExported> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.get(httpParams, `rest/admin-export-projects/_lastExport`);
  }

  public getExport(): Observable<any> {
    return this.backendDataService.getBlob(`rest/admin-export-projects/_export`);
  }

}
