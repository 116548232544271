<div class="container">

    <mat-tab-group class="tab-content" [selectedIndex]="currentTab" (selectedTabChange)="onTabChange($event)">

        <mat-tab label="Favorite">
            <app-favorites
                *ngIf="currentTab === MemberProjectsTabEnum.favorite"
                [data]="data"
                [currentSortOption]="currentSortOption"
                [loading]="loading | async"
                [noContentText]="noContentText"
                (dataSourceChangeInfinityScroll)="onDataSourceChangeInfinityScroll($event)"
                (sortOptions)="onSortOptions($event)"
                (goTo)="onGoTo($event)"
                (createFavorite)="onCreateFavorite($event)"
                (deleteFavorite)="onDeleteFavorite($event)"
                (add)="onAdd($event)"
            ></app-favorites>
        </mat-tab>
        <mat-tab label="Open">
            <app-all-active
                *ngIf="currentTab === MemberProjectsTabEnum.active"
                [data]="data"
                [currentSortOption]="currentSortOption"
                [loading]="loading | async"
                [noContentText]="noContentText"
                (dataSourceChangeInfinityScroll)="onDataSourceChangeInfinityScroll($event)"
                (sortOptions)="onSortOptions($event)"
                (goTo)="onGoTo($event)"
                (createFavorite)="onCreateFavorite($event)"
                (deleteFavorite)="onDeleteFavorite($event)"
                (add)="onAdd($event)"
            ></app-all-active>
        </mat-tab>
        <mat-tab label="Locked">
            <app-member-locked
                *ngIf="currentTab === MemberProjectsTabEnum.locked"
                [data]="data"
                [currentSortOption]="currentSortOption"
                [loading]="loading | async"
                [noContentText]="noContentText"
                (dataSourceChangeInfinityScroll)="onDataSourceChangeInfinityScroll($event)"
                (sortOptions)="onSortOptions($event)"
                (goTo)="onGoTo($event)"
                (createFavorite)="onCreateFavorite($event)"
                (deleteFavorite)="onDeleteFavorite($event)"
                (add)="onAdd($event)"
            ></app-member-locked>
        </mat-tab>
    </mat-tab-group>
    <app-footer-member></app-footer-member>
</div>
