import { messages } from '../../../constants/mesages.constants';
import {
    FooterFactory
} from '../footer-factory/footer.factory';
import * as rc from './../../../models/router.constants';

export const returnFooterOption = (previousUrl: string, currentURL: string, isExportAdmin = false, filledCostsCenter = '') => {
    const footerFactory = new FooterFactory();

    if (previousUrl && currentURL) {
        // coming from admin
        if (currentURL.includes(rc.profile.home) && previousUrl.includes(rc.projects.blank)) {

            return footerFactory.createFooterWithOptions(true, true, false, isExportAdmin, false, filledCostsCenter);
        } else if (currentURL.includes(rc.profile.home)) {

            return footerFactory.createFooterWithOptions(true, false, true, false, true, filledCostsCenter);
        } else if (currentURL.includes(rc.projects.create)) {

            return footerFactory.createFooterWithOptions(false);
        } else if (currentURL.includes(rc.member.projects + '/')) {

            return footerFactory.createFooterWithOptions(false);
        } else if (currentURL.includes(rc.activities.home) && currentURL.includes(messages.entries)) {

            return footerFactory.createFooterWithOptions(false);
        } else if (currentURL.includes(rc.projects.export) || currentURL.includes(rc.projects.uploadLogs)) {

            return footerFactory.createFooterWithOptions(true, true, false, isExportAdmin, false, filledCostsCenter);
        } else if (currentURL.includes(rc.projects.home + '/')) {

            return footerFactory.createFooterWithOptions(false);
        } else if (currentURL.includes(rc.member.projects)) {

            return footerFactory.createFooterWithOptions(true, false, true, false, true, filledCostsCenter);
        } else if (currentURL.includes(rc.projects.home)) {

            return footerFactory.createFooterWithOptions(true, true, false, isExportAdmin, false, filledCostsCenter);
        } else if (currentURL.includes(rc.activities.create)) {

            return footerFactory.createFooterWithOptions(false);
        } else if (currentURL.includes(rc.activities.home)) {

            return footerFactory.createFooterWithOptions(true, false, true, false, true, filledCostsCenter);
        } else if (currentURL.includes(rc.projects.costsCenters + '/')) {

            return footerFactory.createFooterWithOptions(false);
        } else if (currentURL.includes(rc.projects.costsCenters)) {

            return footerFactory.createFooterWithOptions(true, true, false, isExportAdmin, false, filledCostsCenter);
        } else if (currentURL.includes('/')) {

            return footerFactory.createFooterWithOptions(false);
        }

    }
};
