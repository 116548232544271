import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {CxDialogService} from "@bbraun/cortex/dialog";
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SetHeaderForExportAdmin } from '../../../store/actions/header-options.action';
import { messages } from '../../shared/constants/mesages.constants';
import { IAdminExported } from '../../shared/interfaces/admin/admin-exported.interface';
import * as fromApp from './../../../store';
import { SnackBarService } from './../../shared/services/snack-bar/snack-bar.service';
import { AdminExportService } from './../services/admin-export.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.sass']
})
export class ExportComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<fromApp.AppState>,
    private dialogService: CxDialogService,
    private adminExportService: AdminExportService,
    private routerSnapshot: ActivatedRoute,
    private snackBarService: SnackBarService
  ) { }

  private onDestroy$ = new Subject<void>();
  public exportData: IAdminExported = this.routerSnapshot.snapshot.data['lastExported'];

  ngOnInit() {
    this.store.dispatch(SetHeaderForExportAdmin());
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onClick() {
    const title = 'Export activities';
    const message = 'Are you sure you want to Export activities';
    const confirmAction = messages.confirmation;
    const cancelAction = messages.cancel;

    this.dialogService.openConfirmDialog(title, message, confirmAction, cancelAction)
      .subscribe((responseFromDialog: boolean) => {
        if (responseFromDialog) {
          this.adminExportService.getExport()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (res) => {
              if (res) {
                this.downloadFile(res);
              } else {
                this.snackBarService.openSimpleSnackBar('There are no Activities for Export', 3000);
              }
            });
        }
    });
}

  private downloadFile(blob) {
    const date = new Date();
    const fileName = `export_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xls`;

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }
}
