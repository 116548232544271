import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';

import {CxDialogService} from "@bbraun/cortex/dialog";
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IDateRange } from '../../helper-functions/get-range.functions';
import { visualizeNoContentExported, visualizeNoContentNotExported } from '../../helper-functions/visualize-no-content-text.functions';
import * as fromApp from '../../store';
import { LoadActivitiesExported,
    ScrollActivitiesExported,
    SearchActivitiesExported,
    SearchScrollActivitiesExported,
    SearchSortActivitiesExported,
    SortActivitiesExported
} from '../../store/actions/activities-exported.actions';
import {
  ClearNotExportedActivities,
  DeleteEntryAction,
  LoadActivitiesNotExported,
  ScrollActivitiesNotExported,
  SortActivitiesNotExported
} from '../../store/actions/activities-not-exported.actions';
import { SetHeaderForActivityHome } from '../../store/actions/header-options.action';
import {LayoutDateFilter, SetActivitiesTabChangeAction} from '../../store/actions/layout.actions';
import { ActivitiesExportedState } from '../../store/reducers/activities-exported.reducer';
import { ActivitiesNotExportedState } from '../../store/reducers/activities-not-exported.reducer';
import { MemberService } from '../member/services/member.service';
import { messages } from '../shared/constants/mesages.constants';
import { timePeriod } from '../shared/constants/time-period.constants';
import { ExportedTabEnums } from '../shared/enum/exported-tabs.enum';
import { MemberActivitiesTabEnum } from '../shared/enum/member-activities-tab.enum';
import { IMembersDataContent } from '../shared/interfaces/member/member.data.content.interface';
import { ITimeDestribution } from '../shared/interfaces/member/time-destribution.interface';
import {
    SearchActivitiesNotExported,
    SearchScrollActivitiesNotExported,
    SearchSortActivitiesNotExported } from '../../store/actions/activities-not-exported.actions';
import * as tableParams from './../shared/constants/parameters.for.calls.constants';
import * as routerConstants from './../shared/models/router.constants';


@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.sass']
})
export class ActivitiesComponent implements OnInit, OnDestroy {

    constructor(
        private store: Store < fromApp.AppState >,
        private router: Router,
        private dialogService: CxDialogService,
        private memberService: MemberService
    ) {}

    public enums = ExportedTabEnums;

    public data: Array<IMembersDataContent>;
    public loading: Observable<boolean>;
    public noContentText = 'No items';
    public currentTab: number;
    public currentSortOption = tableParams.parametersForExported.sort;
    public currentPage = tableParams.parametersForExported.page;
    public totalPages: number;
    public currentSearch = '';
    public currentIsSearching = false;

    public timeDestribution: ITimeDestribution;
    public startDateMilis = 0;
    public endDateMilis = 0;
    public optionsArr = [timePeriod.today, timePeriod.week, timePeriod.month, timePeriod.all];
    public selectedOpiton: string;

    private onDestroy$ = new Subject <void>();
    private onDestroyDateEnd$ = new Subject <void>();
    private onDestroyEntry$ = new Subject <void>();
    private onDestroyInitialLoad$ = new Subject < void >();

    ngOnInit() {
        this.store.dispatch(SetHeaderForActivityHome());
        // Initial Load
        this.store.select(fromApp.getLayoutActivitiesDateFilter)
            .pipe(takeUntil(this.onDestroyDateEnd$))
            .subscribe((res: IDateRange) => {
                this.selectedOpiton = res.dateFilter;
                this.startDateMilis = res.startDate;
                this.endDateMilis = res.endDate;
            });

        this.store.select(fromApp.getLayoutActivitiesSelectedTab)
            .pipe(takeUntil(this.onDestroyInitialLoad$))
            .subscribe((res: number) => {
                this.currentTab = res;
                this.loadCorrectTab();
            });

        this.loading = this.store.select(fromApp.getLayoutTableLoading);
        this.onDestroyInitialLoad$.next();
        this.onDestroyInitialLoad$.complete();
        this.getTimeDestribution();
    }

    ngOnDestroy() {
        this.onDestroy$.next();

        this.store.dispatch(ClearNotExportedActivities());
        this.onDestroy$.complete();
        this.onDestroyDateEnd$.next();
        this.onDestroyDateEnd$.complete();
    }

    public onCopy(data) {
        this.router.navigate([routerConstants.activities.copy, data.projectId, 'entries', data.entryId]);

    }
    public onGoToEntry(data) {
        this.router.navigate([routerConstants.activities.home, data.projectId, 'entries', data.entryId]);
    }

    public onSelect(ev: MatSelectChange) {
        this.selectedOpiton = ev.value;
        this.store.dispatch(LayoutDateFilter({dateFilter: this.selectedOpiton}));
        this.currentPage = 0;
        this.getTimeDestribution();
        this.loadCorrectTab();
    }

    public onTabChange(ev: MatTabChangeEvent): void {
        this.currentTab = ev.index;
        this.store.dispatch(SetActivitiesTabChangeAction({ activitiesTab: this.currentTab }));
        this.currentPage = 0;
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.onDestroy$ = new Subject <void>();
        this.loadCorrectTab();
    }

    public onSortOptions(sort) {
        this.currentPage = 0;
        this.currentSortOption = sort;

        if (this.currentIsSearching) {
            switch (this.currentTab) {
                case MemberActivitiesTabEnum.notExported:
                        this.store.dispatch(SearchSortActivitiesNotExported(
                            {
                            page: tableParams.parametersForNotExported.page,
                            size: tableParams.parametersForNotExported.size,
                            sort,
                            startDate: this.startDateMilis,
                            endDate: this.endDateMilis,
                            search: this.currentSearch
                            }
                        ));
                    break;
                case MemberActivitiesTabEnum.exported:
                        this.store.dispatch(SearchSortActivitiesExported(
                            {
                                page: tableParams.parametersForExported.page,
                                size: tableParams.parametersForExported.size,
                                sort,
                                startDate: this.startDateMilis,
                                endDate: this.endDateMilis,
                                search: this.currentSearch
                            }
                        ));
                    break;
                default:
                    break;
            }

        } else {
            switch (this.currentTab) {
                case MemberActivitiesTabEnum.notExported:
                        this.store.dispatch(SortActivitiesNotExported(
                            {
                            page: tableParams.parametersForNotExported.page,
                            size: tableParams.parametersForNotExported.size,
                            sort,
                            startDate: this.startDateMilis,
                            endDate: this.endDateMilis
                            }
                        ));
                    break;
                case MemberActivitiesTabEnum.exported:
                        this.store.dispatch(SortActivitiesExported(
                            {
                                page: tableParams.parametersForExported.page,
                                size: tableParams.parametersForExported.size,
                                sort,
                                startDate: this.startDateMilis,
                                endDate: this.endDateMilis
                            }
                        ));
                    break;
                default:
                    break;
            }
        }
    }

    public onDataSourceChangeInfinityScroll(status: number) {
        this.currentPage++;
        if (this.totalPages - 1 < this.currentPage) {
          return;
        }
        if (this.currentIsSearching) {
            switch (status) {
                case MemberActivitiesTabEnum.notExported:
                        this.store.dispatch(SearchScrollActivitiesNotExported(
                            {
                                page: this.currentPage,
                                size: tableParams.parametersForNotExported.size,
                                sort: this.currentSortOption,
                                startDate: this.startDateMilis,
                                endDate: this.endDateMilis,
                                search: this.currentSearch
                            }
                        ));
                    break;
                case MemberActivitiesTabEnum.exported:
                        this.store.dispatch(SearchScrollActivitiesExported(
                            {
                                page: this.currentPage,
                                size: tableParams.parametersForExported.size,
                                sort: this.currentSortOption,
                                startDate: this.startDateMilis,
                                endDate: this.endDateMilis,
                                search: this.currentSearch
                            }
                        ));
                    break;
                default:
                    break;
            }
        } else {
            switch (status) {
                case MemberActivitiesTabEnum.notExported:
                        this.store.dispatch(ScrollActivitiesNotExported(
                            {
                            page: this.currentPage,
                            size: tableParams.parametersForNotExported.size,
                            sort: this.currentSortOption,
                            startDate: this.startDateMilis,
                            endDate: this.endDateMilis
                            }
                        ));
                    break;
                case MemberActivitiesTabEnum.exported:
                        this.store.dispatch(ScrollActivitiesExported(
                            {
                                page: this.currentPage,
                                size: tableParams.parametersForExported.size,
                                sort: this.currentSortOption,
                                startDate: this.startDateMilis,
                                endDate: this.endDateMilis
                            }
                        ));
                    break;
                default:
                    break;
            }
        }
    }

    public onDeleteEntry(data: {projectId: number, entryId: number}) {
        const title = '';
        const message = `Are you sure you want to delete this ${messages.activity}?`;
        const confirmAction = messages.confirmation;
        const cancelAction = messages.cancel;

        this.dialogService.openDeleteDialog(title, message, confirmAction, cancelAction)
          .pipe(takeUntil(this.onDestroyEntry$))
          .subscribe((res) => {
            if (res) {
                this.store.dispatch(DeleteEntryAction(
                    {
                        projectId: data.projectId,
                        entryId: data.entryId,
                        sort: this.currentSortOption,
                        startDate: this.startDateMilis,
                        endDate: this.endDateMilis,
                        isSearching: this.currentIsSearching,
                        search: this.currentSearch
                    }));
              }
              this.currentPage = 0;
            });
        }

    private loadExportedTable(): void {
        this.store.select(fromApp.getActivitiesExported)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res: ActivitiesExportedState) => {
                this.noContentText = visualizeNoContentExported(this.selectedOpiton);
                this.data = res.value.content;
                this.totalPages = res.value.totalPages;
                this.currentSearch = res.searchParam;
                this.currentIsSearching = res.isSearching;
                this.currentSortOption = res.sort;
                this.currentPage = res.value.number;
            });


            if (this.currentIsSearching) {
                this.store.dispatch(SearchActivitiesExported(
                    {
                        page: tableParams.parametersForExported.page,
                        size: tableParams.parametersForExported.size,
                        sort: this.currentSortOption,
                        startDate: this.startDateMilis,
                        endDate: this.endDateMilis,
                        search: this.currentSearch
                    }
                ));
            } else {
                this.store.dispatch(LoadActivitiesExported(
                    {
                        page: tableParams.parametersForExported.page,
                        size: tableParams.parametersForExported.size,
                        sort: this.currentSortOption,
                        startDate: this.startDateMilis,
                        endDate: this.endDateMilis
                    }
                ));
            }

    }

    private loadNotExportedTable(): void {
        this.store.select(fromApp.getActivitiesNotExported)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res: ActivitiesNotExportedState) => {
                this.noContentText = visualizeNoContentNotExported(this.selectedOpiton);
                this.data = res.value.content;
                this.totalPages = res.value.totalPages;
                this.currentSearch = res.searchParam;
                this.currentIsSearching = res.isSearching;
                this.currentSortOption = res.sort;
                this.currentPage = res.value.number;
        });

        if (this.currentIsSearching) {
            this.store.dispatch(SearchActivitiesNotExported(
                {
                    page: tableParams.parametersForNotExported.page,
                    size: tableParams.parametersForNotExported.size,
                    sort: this.currentSortOption,
                    startDate: this.startDateMilis,
                    endDate: this.endDateMilis,
                    search: this.currentSearch
                }
            ));
        } else {
            this.store.dispatch(LoadActivitiesNotExported(
                {
                    page: tableParams.parametersForNotExported.page,
                    size: tableParams.parametersForNotExported.size,
                    sort: this.currentSortOption,
                    startDate: this.startDateMilis,
                    endDate: this.endDateMilis
                }
            ));
        }

    }

    private loadCorrectTab() {
        if (this.currentTab === ExportedTabEnums.notExported) {
            this.loadNotExportedTable();
        } else {
            this.currentTab = ExportedTabEnums.exported;
            this.loadExportedTable();
        }
      }

      private getTimeDestribution() {
        this.onDestroyInitialLoad$ = new Subject <void>();
        this.memberService.getTimeDestribution(this.startDateMilis, this.endDateMilis)
        .pipe(takeUntil(this.onDestroyInitialLoad$))
        .subscribe((res: ITimeDestribution) => {
            this.timeDestribution = res;
            this.onDestroyInitialLoad$.next();
            this.onDestroyInitialLoad$.complete();
        });
    }
}
