import { Component, OnInit } from '@angular/core';
import {Event, NavigationEnd, Router, RouterEvent} from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PreviousRoute } from 'src/main/typescript/app/store/actions/layout.actions';
import { SetUserActive } from 'src/main/typescript/app/store/actions/user.actions';

import * as fromRoot from '../../../../../store';
import { IUser } from '../../../interfaces/user/user.interface';
import { AppNavigateService } from '../../../services/navigation/app-navigate.service';
import { returnFooterOption } from '../helper-functions/return-footer-options.function';
import { messages } from './../../../constants/mesages.constants';
import * as routerConstants from './../../../models/router.constants';
import { IFooter } from './../footer-factory/footer.interface';


@Component({
  selector: 'app-footer-member',
  templateUrl: './footer-member.component.html',
  styleUrls: ['./footer-member.component.sass']
})
export class FooterMemberComponent implements OnInit {

  constructor(
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private navigationService: AppNavigateService
  ) { }

  public messages = messages;
  public routeModels = routerConstants;

  private userData$: Observable<IUser>;
  public userInfoData: IUser = {exportAdmin: false};

  public history = [];
  public footerOptions: IFooter;
  public routeConstants = routerConstants;
  public currentURL;
  public isCostCenterSet: boolean;

  private unsavedChanges$: Observable<boolean>;
  private isFormDirty = false;


  ngOnInit() {
    this.unsavedChanges$ = this.store.select(fromRoot.getLayoutUnSavedChanges);
    this.unsavedChanges$
    .subscribe(res => {
      this.isFormDirty = res;
    });

    this.router.events
      .subscribe((event: RouterEvent | Event) => {
        if (event instanceof NavigationEnd) {
          this.history =
          this.history.length ? [...this.history, event.urlAfterRedirects] :
                                [this.routeConstants.activities.home, event.urlAfterRedirects];
          this.currentURL = this.history[this.history.length - 1];
          if (event.urlAfterRedirects !== event.url) {
            this.footerOptions = returnFooterOption(
              event.urlAfterRedirects,
              event.urlAfterRedirects,
              this.userInfoData.exportAdmin,
              this.userInfoData.user.costCenter);
            } else {
              this.footerOptions =
                    returnFooterOption(this.history[this.history.length - 2],
                      event.url, this.userInfoData.exportAdmin, this.userInfoData?.user?.costCenter);
                    }
            this.store.dispatch(PreviousRoute({
              url: this.history[this.history.length - 2]
            }));
        }
        });

        this.userData$ = this.store.select(fromRoot.getUserData);
        this.userData$.subscribe((data: IUser) => {
          if (data.user) {
            this.isCostCenterSet = !!data.user.costCenter;
          } else {
            this.store.dispatch(new SetUserActive());
          }
        });
  }

  public onClickButton(url: string) {
    if (this.isFormDirty) {
      this.navigationService.goToRouteWithConfirmation(url);
    } else {
      this.navigationService.goToUrl(url);
      }

  }
}
