import { createAction, props } from '@ngrx/store';

import { IMemberDataContentSets } from './../../modules/shared/interfaces/member/member-set-paging.interface';

export const LOAD_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] LOAD_MEMBER_PROJECTS_LOCKED_DATA';
export const SCROLL_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] SCROLL_MEMBER_PROJECTS_LOCKED_DATA';
export const LOAD_MEMBER_PROJECTS_LOCKED_DATA_SUCCESS = '[MEMBER-PROJECTS] LOAD_MEMBER_PROJECTS_LOCKED_DATA_SUCCESS';
export const DELETE_FAVORITE_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] DELETE_FAVORITE_MEMBER_PROJECTS_LOCKED_DATA';
export const CREATE_FAVORITE_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] CREATE_FAVORITE_MEMBER_PROJECTS_LOCKED_DATA';
export const SEARCH_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] SEARCH_MEMBER_PROJECTS_LOCKED_DATA';
export const SEARCH_SCROLL_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] SEARCH_SCROLL_MEMBER_PROJECTS_LOCKED_DATA';
export const SORT_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] SORT_MEMBER_PROJECTS_LOCKED_DATA';
export const SEARCH_SORT_MEMBER_PROJECTS_LOCKED_DATA = '[MEMBER-PROJECTS] SEARCH_SORT_MEMBER_PROJECTS_LOCKED_DATA';

export const LoadMemberProjectsLocked = createAction(
  LOAD_MEMBER_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SortMemberProjectsLocked = createAction(
  SORT_MEMBER_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const LoadMemberProjectsLockedDataSuccessAction = createAction(
  LOAD_MEMBER_PROJECTS_LOCKED_DATA_SUCCESS,
  props<{
    memberProjectLockedTableItems: IMemberDataContentSets
  }>()
);

export const ScrollMemberProjectsLocked = createAction(
  SCROLL_MEMBER_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SearchSortMemberProjectsLocked = createAction(
  SEARCH_SORT_MEMBER_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchMemberProjectsLocked = createAction(
  SEARCH_MEMBER_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchScrollMemberProjectsLocked = createAction(
  SEARCH_SCROLL_MEMBER_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);
