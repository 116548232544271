import { createAction, props } from '@ngrx/store';

import { IAdminProjectDataContentSets } from '../../modules/shared/interfaces/admin/admin-project-set-paging.interface';

export const LOAD_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] LOAD_ADMIN_PROJECTS_ACTIVE_DATA';
export const SCROLL_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] SCROLL_ADMIN_PROJECTS_ACTIVE_DATA';
export const LOAD_ADMIN_PROJECTS_ACTIVE_DATA_SUCCESS = '[ADMIN-PROJECTS] LOAD_ADMIN_PROJECTS_ACTIVE_DATA_SUCCESS';
export const DELETE_FAVORITE_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] DELETE_FAVORITE_ADMIN_PROJECTS_ACTIVE_DATA';
export const CREATE_FAVORITE_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] CREATE_FAVORITE_ADMIN_PROJECTS_ACTIVE_DATA';
export const SEARCH_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] SEARCH_ADMIN_PROJECTS_ACTIVE_DATA';
export const SEARCH_SCROLL_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] SEARCH_SCROLL_ADMIN_PROJECTS_ACTIVE_DATA';
export const SORT_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] SORT_ADMIN_PROJECTS_ACTIVE_DATA';
export const SEARCH_SORT_ADMIN_PROJECTS_ACTIVE_DATA = '[ADMIN-PROJECTS] SEARCH_SORT_ADMIN_PROJECTS_ACTIVE_DATA';


export const LoadAdminProjectsActive = createAction(
  LOAD_ADMIN_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SortAdminProjectsActive = createAction(
  SORT_ADMIN_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const LoadAdminProjectsActiveDataSuccessAction = createAction(
  LOAD_ADMIN_PROJECTS_ACTIVE_DATA_SUCCESS,
  props<{
    adminProjectActiveTableItems: IAdminProjectDataContentSets
  }>()
);

export const ScrollAdminProjectsActive = createAction(
  SCROLL_ADMIN_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SearchSortAdminProjectsActive = createAction(
  SEARCH_SORT_ADMIN_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchAdminProjectsActive = createAction(
  SEARCH_ADMIN_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchScrollAdminProjectsActive = createAction(
  SEARCH_SCROLL_ADMIN_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);
