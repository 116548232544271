<cx-heart-beat *ngIf="(loading | async) === true" [loading]="loading"></cx-heart-beat>

<div *ngIf="(loading | async) === false && user.projectAdmin && user.projectMember"
     class="position-fixed d-flex flex-row justify-content-center align-items-center cards-container">
    <mat-card class="card card-projects cursor-pointer" [routerLink]="[routeConstants.projects.home]">
        <mat-card-content class="card-content">
            <span class="font-size-vw">
                Projects Administration
            </span>
        </mat-card-content>
        <mat-card-actions class="mat-card-actions d-flex justify-content-end align-items-end">
            <i class="material-icons">assignment</i>
        </mat-card-actions>
    </mat-card>

    <mat-card class="card cursor-pointer" [routerLink]="[routeConstants.projects.activityProjects]">
        <mat-card-content class="card-content">
            <span class="font-size-vw">
                My
            </span>
            <p class="font-size-vw">
                {{messages.activityLogs}}
            </p>
        </mat-card-content>
        <mat-card-actions class="mat-card-actions d-flex justify-content-end align-items-end">
            <i class="material-icons">note</i>
        </mat-card-actions>
    </mat-card>
</div>

