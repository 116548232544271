import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {MsalGuard} from "@azure/msal-angular";

import { ActivitiesComponent } from '../activities/activities.component';
import {MemberProjectsComponent} from "./member-projects/member-projects.component";
import { NewEntryTimeComponent } from './new-entry-time/new-entry-time.component';
import { MemberEntryAndProjectResolver } from './resolvers/member-entry-and-project.resolver';
import { MemberProjectResolver } from './resolvers/member-project.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'logs', pathMatch: 'full' },
  { path: 'projects', component: MemberProjectsComponent, canActivate: [MsalGuard]},
  { path: 'logs', component: ActivitiesComponent, canActivate: [MsalGuard]},
  { path: 'logs/create/copy/:projectId/entries/:entryId', canActivate: [MsalGuard], component: NewEntryTimeComponent,
          resolve: { copy: MemberEntryAndProjectResolver }},
  { path: 'logs/create/:projectId', canActivate: [MsalGuard], component: NewEntryTimeComponent, resolve: { project: MemberProjectResolver }},
  { path: 'logs/:projectId/entries/:entryId', canActivate: [MsalGuard], component: NewEntryTimeComponent, resolve: { edit: MemberEntryAndProjectResolver }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }
