import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import * as urlJoin from 'url-join';

import { environment } from '../../../../../environments/environment';

@Injectable()
export class BackendDataService {

  constructor(private _http: HttpClient) {
  }

  public get(params?: HttpParams, ...segments: Array<string>): Observable<any> {
    return this._http.get(urlJoin(environment.backendURL, ...segments), {
      headers: this.getHeaders(),
      withCredentials: false,
      params: params
    }).pipe(map(data => data as any));
  }

  public create(obj: any, params?: HttpParams, ...segments: Array<string>): Observable<any> {
    return this._http.post(urlJoin(environment.backendURL, ...segments), obj, {
      headers: this.getHeaders(),
      withCredentials: false,
      params: params
    }).pipe(map(data => data as any));
  }

  public createByUrlOnly( params?: HttpParams, ...segments: Array<string>): Observable<any> {
    return this._http.post(urlJoin(environment.backendURL, ...segments), {}, {
      headers: this.getHeaders(),
      withCredentials: false,
      params: params
    }).pipe(map(data => data as any));
  }

  public update(obj: any, params?: HttpParams, ...segments: Array<string>): Observable<any> {
    return this._http.put(urlJoin(environment.backendURL, ...segments), obj, {
      headers: this.getHeaders(),
      withCredentials: false,
      params: params
    }).pipe(map(data => data as any));
  }

  public remove(obj: any, ...segments: Array<string>): Observable<any> {
    return this._http.delete(urlJoin(environment.backendURL, ...segments), obj).pipe(map(data => data as any));
  }

  public removeByUrlOnly(params?: HttpParams, ...segments: Array<string>): Observable<any> {
    return this._http.delete(urlJoin(environment.backendURL, ...segments), {
      headers: this.getHeaders(),
      withCredentials: false,
      params: params
    }).pipe(map(data => data as any));
  }

  public getBlob(...segments: Array<string>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/csv; charset=utf-8');
    return this._http.get(urlJoin(environment.backendURL, ...segments), {
      headers: httpHeaders,
      withCredentials: false,
      responseType: 'blob'
    }).pipe(map(data => data as any));
  }

  private getHeaders(): HttpHeaders {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json; charset=utf-8');
    return httpHeaders;
  }
}

