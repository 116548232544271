import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PagingSortingSearchingOptions } from '../paging/paging-sorting-searching-options.model';

@Injectable()
export class HttpParamsFactory {


  public returnPagingSortingSearchingParams(
    pagingSortingSearchingOptions?: PagingSortingSearchingOptions,
    params?: HttpParams): HttpParams {

    if (!pagingSortingSearchingOptions) {
      pagingSortingSearchingOptions = new PagingSortingSearchingOptions();
    }
    if (!params) {
      params = new HttpParams();
    }

    if (pagingSortingSearchingOptions.page) {
      params = params.append('page', String(pagingSortingSearchingOptions.page));
    }

    if (pagingSortingSearchingOptions.size) {
      params = params.append('size', String(pagingSortingSearchingOptions.size));
    }

    if (pagingSortingSearchingOptions.sort) {
      params = params.append('sort', String(pagingSortingSearchingOptions.sort));
    }

    if (pagingSortingSearchingOptions.favorite) {
      params = params.append('favorite', String(pagingSortingSearchingOptions.favorite));
    }

    if (pagingSortingSearchingOptions.booked !== undefined && pagingSortingSearchingOptions.booked !== null) {
      params = params.append('booked', String(pagingSortingSearchingOptions.booked));
    }

    if (pagingSortingSearchingOptions.locked !== undefined && pagingSortingSearchingOptions.locked !== null) {
      params = params.append('locked', String(pagingSortingSearchingOptions.locked));
    }

    if (pagingSortingSearchingOptions.searchParam !== undefined
      && pagingSortingSearchingOptions.searchParam !== null) {
      params = params.append('searchParam', String(pagingSortingSearchingOptions.searchParam));
    }

    if (pagingSortingSearchingOptions.startDate) {
      params = params.append('startDate', String(pagingSortingSearchingOptions.startDate));
    }

    if (pagingSortingSearchingOptions.endDate) {
      params = params.append('endDate', String(pagingSortingSearchingOptions.endDate));
    }

    return params;
  }
}
