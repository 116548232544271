import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MemberService } from '../../modules/member/services/member.service';
import * as fromApp from '../../store';
import { SetTableLoading } from '../actions/layout.actions';
import { ForceHide } from './../actions/layout.actions';
import * as MemberProjectsFavoriteActions from './../actions/member-projects-favorite.actions';

@Injectable()
export class MemberProjectsFavoriteEffects {

  constructor(
    private actions$: Actions,
    private memberProjectsService: MemberService,
    private store: Store<fromApp.AppState>
  ) { }

    loadMemberProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsFavoriteActions.LoadMemberProjectsFavorite
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort, true)
          .pipe(
            map(data => {
          this.store.dispatch(ForceHide({forceHide: false}));
          this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsFavoriteActions.LoadMemberProjectsFavoriteDataSuccessAction({
                memberProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    scrollMemberProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsFavoriteActions.ScrollMemberProjectsFavorite
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort, true)
          .pipe(
            map(data => {
          this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsFavoriteActions.LoadMemberProjectsFavoriteDataSuccessAction({
                memberProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );


    sortMemberProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsFavoriteActions.SortMemberProjectsFavorite
      ),
      switchMap((action) => {
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort, true)
          .pipe(
            map(data => {
              return MemberProjectsFavoriteActions.LoadMemberProjectsFavoriteDataSuccessAction({
                memberProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );


    searchMemberProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsFavoriteActions.SearchMemberProjectsFavorite
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, true, null, action.search)
          .pipe(
            map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
             this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsFavoriteActions.LoadMemberProjectsFavoriteDataSuccessAction({
                memberProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );


    searchSortMemberProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsFavoriteActions.SearchSortMemberProjectsFavorite
      ),
      switchMap((action) => {
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, true, null, action.search)
          .pipe(
            map(data => {
              return MemberProjectsFavoriteActions.LoadMemberProjectsFavoriteDataSuccessAction({
                memberProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );


    searchScrollMemberProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsFavoriteActions.SearchScrollMemberProjectsFavorite
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, true, null, action.search)
          .pipe(
            map(data => {
          this.store.dispatch(SetTableLoading({loading: false}));
          return MemberProjectsFavoriteActions.LoadMemberProjectsFavoriteDataSuccessAction({
            memberProjectFavoriteTableItems: data
          });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );
}
