import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';

@Directive({
  selector: '[appTopMargin]'
})
export class TopMarginDirective {
  // Represents the value for how many pixels should the user scrolled to make the element fixed.
  @Input() pixels: number;

  private doc: Document;
  private fixed = false;

  constructor(@Inject(DOCUMENT) doc: Document, private element: ElementRef) {
    this.doc = doc;
  }

  @HostListener("window:scroll", [])

  /**
   * Runs on each user scroll. If the scroll offset from the top is
   * bigger than the passed pixels we should fix the element.
   */
  private onWindowScroll(): void {
    const scroll = this.doc.documentElement.scrollTop;

    if (!this.pixels || isNaN(this.pixels)) {
      console.log('Pass pixels to the scroll to fixed directive.');
      return;
    }

    if (!this.fixed && scroll > this.pixels) {
      this.element.nativeElement.classList.add('top-margin-136');
      this.fixed = true;
    } else if (this.fixed && scroll < this.pixels) {
      this.element.nativeElement.classList.remove('top-margin-136');
      this.fixed = false;
    }
  }

}
