import { Injectable } from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import * as fromApp from '../../store';
import { SetLoading } from '../actions/layout.actions';
import { UserService } from './../../modules/shared/services/user/user.service';
import * as UserActions from './../actions/user.actions';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store<fromApp.AppState>
  ) { }

    loadUserData$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.SET_USER_ACTIVE_DATA),
        switchMap(() => {
            this.store.dispatch(SetLoading({loading: true}));
            return this.userService.getUserData()
                .pipe(
                    map(data => {
                        this.store.dispatch(SetLoading({loading: false}));
                        return new UserActions.LoadUserActiveDataSuccessAction(data);
                    }),
                    catchError((err, caught) => {
                        return EMPTY;
                    })
                );
        })
    ));
}
