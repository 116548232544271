import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as enums from '../../../shared/enum/member-activities-tab.enum';
import { IMembersDataContent } from '../../../shared/interfaces/member/member.data.content.interface';
import { IEntryParams } from '../../../shared/interfaces/params/entry-params.interface';


@Component({
  selector: 'app-exported',
  templateUrl: './exported.component.html',
  styleUrls: ['./exported.component.sass']
})
export class ExportedComponent {

  @Output() sortOptions: EventEmitter<string> = new EventEmitter();
  @Output() dataSourceChangeInfinityScroll = new EventEmitter<number>();
  @Output() goTo = new EventEmitter<IEntryParams>();
  @Output() copyEmitter = new EventEmitter();

  @Input() data: Array<IMembersDataContent>;
  @Input() currentSortOption: string;
  @Input() loading: boolean;
  @Input() noContentText: string;


  public titleColumnText = 'Project title';
  public displayedColumns =
          ['title', 'space', 'description', 'chargeable', 'creationDate', 'loggedTime', 'exportDate', 'duplicateActivityWithLessSpace'];

  public onDataSourceChangeInfinityScroll() {
    this.dataSourceChangeInfinityScroll.emit(enums.MemberActivitiesTabEnum.exported);
  }

  public onSortOptions(ev) {
    this.sortOptions.emit(ev);
  }

  public onGoToEntry(ev) {
    const obj: IEntryParams = {
      projectId: ev.firstId,
      entryId: ev.secondId
    };
    this.goTo.emit(obj);
  }

  public onCopy(ev) {
    const obj: IEntryParams = {
      projectId: ev.firstId,
      entryId: ev.secondId
    };
    this.copyEmitter.emit(obj);
  }
}
