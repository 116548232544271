import { timePeriod } from '../modules/shared/constants/time-period.constants';

export interface IDateRange {
    startDate: number;
    endDate: number;
    dateFilter?: string;
}

export const getTimestampRangeForToday = () => {
    const now = new Date();
    const currentDateUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    // set today 00:00
    const startDate = currentDateUTC.setHours(0, 0, 0, 0);
    // set today 23:59
    const endDate = currentDateUTC.setHours(23, 59, 59, 999);
    return {
        startDate,
        endDate
    };
};

export const getTimestampRangeForThisWeek = () => {
    const now = new Date();
    const correctDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const day = correctDate.getDay();
    const diff = correctDate.getDate() - day + (day === 0 ? -6 : 1);
    // get monday
    const result = new Date(correctDate.setDate(diff));

    const startDate = new Date(result).setHours(0, 0, 0, 0);
    // monday 00:00
    result.setDate(result.getDate() + 6);
    // sunday 23:59
    const endDate = new Date(result).setHours(23, 59, 59, 999);
    return {
        startDate,
        endDate
    };
};

export const getTimestampRangeForThisMonth = () => {
    const now = new Date();
    const correctDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    correctDate.setDate(1);
    // set 1st day of month 00:00
    const startDate = new Date(correctDate).setHours(0, 0, 0, 0);
    // set last day of month 23:59
    const endDate = new Date(correctDate.getFullYear(), correctDate.getMonth() + 1, 0).setHours(23, 59, 59, 999);

    return {
        startDate,
        endDate
    };
};


export const setCorrectDateFilter = (dateFilter: string) => {
    let range: IDateRange;
    switch (dateFilter) {
        case timePeriod.today:
            range = getTimestampRangeForToday();
            break;
        case timePeriod.week:
                range = getTimestampRangeForThisWeek();
            break;
        case timePeriod.month:
                range = getTimestampRangeForThisMonth();
            break;
        case timePeriod.all:
                // send nothing to backend
                range = {
                    startDate : 0,
                    endDate: 0
                };
            break;
        default:
            break;
    }
    return range;
};
