import {Component, Inject, OnInit} from '@angular/core';
import {
    Event,
    NavigationEnd,
    Router,
    RouterEvent
} from '@angular/router';

import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {
    EventMessage,
    EventType,
    InteractionStatus
} from "@azure/msal-browser";
import { Store } from '@ngrx/store';
import {Subject} from "rxjs";
import { Observable } from 'rxjs/Observable';
import {filter, takeUntil} from "rxjs/operators";

import { IFooter } from './modules/shared/components/footer/footer-factory/footer.interface';
import { returnFooterOption } from './modules/shared/components/footer/helper-functions/return-footer-options.function';
import { IUser } from './modules/shared/interfaces/user/user.interface';
import * as routeConstants from './modules/shared/models/router.constants';
import * as fromApp from './store';
import { PreviousRoute } from './store/actions/layout.actions';
import { SetUserActive } from './store/actions/user.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {}

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  private userData$: Observable<IUser>;
  public userInfoData: IUser = {exportAdmin: false};

  public history = [];
  public footerOptions: IFooter;
  public routeConstants = routeConstants;

  ngOnInit() {
  this.setAuthConfiguration();
  this.store.dispatch(new SetUserActive());

  this.router.events
    .subscribe((event: RouterEvent | Event) => {
      if (event instanceof NavigationEnd) {
        this.history =
        this.history.length ? [...this.history, event.urlAfterRedirects] :
                              [routeConstants.projects.activityProjects, event.urlAfterRedirects];
        if (event?.urlAfterRedirects !== event.url) {
          this.footerOptions = returnFooterOption(
            event.urlAfterRedirects,
            event.urlAfterRedirects,
            this.userInfoData.exportAdmin,
            this.userInfoData.user.costCenter);
          } else {
            this.footerOptions =
                  returnFooterOption(this.history[this.history.length - 2],
                    event.url, this.userInfoData.exportAdmin, this.userInfoData.user.costCenter);
                  }
          this.store.dispatch(PreviousRoute({
            url: this.history[this.history.length - 2]
          }));
      }
      });

      this.userData$ = this.store.select(fromApp.getUserData);
      this.userData$.subscribe((data: IUser) => {
        if (data.user) {
          this.userInfoData = data;
          this.footerOptions = returnFooterOption(
          this.history[this.history.length - 2],
          this.history[this.history.length - 1], this.userInfoData.exportAdmin, this.userInfoData.user.costCenter);
        }
      });
    }

    setAuthConfiguration() {
        this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
        this.setLoginDisplay();

        this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED)
            )
            .subscribe((result: EventMessage) => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount(){
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
