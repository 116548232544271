import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'booleanToText'
})
export class BooleanToTextPipe implements PipeTransform {

    transform(value: boolean, positiveValueText: string, negativeValueText: string): string {
        if (value === false) {
          return negativeValueText;
        }
        return positiveValueText;
    }
}
