import { createReducer, on } from '@ngrx/store';

import { IDateRange, setCorrectDateFilter } from '../../helper-functions/get-range.functions';
import { timePeriod } from '../../modules/shared/constants/time-period.constants';
import * as routeConstants from '../../modules/shared/models/router.constants';
import * as LayoutsActions from './../actions/layout.actions';

export interface ILayoutState {
    tab: number;
    activitiesTab: number;
    areUnsavedChanges: boolean;
    loading: boolean;
    tableLoading: boolean;
    disableButton: boolean;
    forceHide: boolean;
    previousRoute: string;
    dateFilter: string;
    startDate: number;
    endDate: number;
}

export const initialState: ILayoutState = {
    tab: 0,
    activitiesTab: 0,
    areUnsavedChanges: false,
    loading: false,
    tableLoading: false,
    disableButton: false,
    forceHide: false,
    previousRoute: routeConstants.activities.home,
    dateFilter: timePeriod.month,
    startDate: setCorrectDateFilter(timePeriod.month).startDate,
    endDate: setCorrectDateFilter(timePeriod.month).endDate
};

export const LayoutsReducer = createReducer<ILayoutState>(
  initialState,
  on(LayoutsActions.SetTabChangeAction, (state: ILayoutState, { tab }) => {
    return {
      ...state,
      tab
    };
  }),
on(LayoutsActions.SetActivitiesTabChangeAction, (state: ILayoutState, { activitiesTab }) => {
    return {
        ...state,
        activitiesTab
    };
}),
  on(LayoutsActions.SetUnsavedChangesAction, (state: ILayoutState, { areUnsavedChanges }) => {
    return {
      ...state,
      areUnsavedChanges
    };
  }),
  on(LayoutsActions.SetLoading, (state: ILayoutState, { loading }) => {
    return {
      ...state,
      loading
    };
  }),
  on(LayoutsActions.SetTableLoading, (state: ILayoutState, { loading }) => {
    return {
      ...state,
      tableLoading: loading
    };
  }),
  on(LayoutsActions.SetDisableButtonOnRequest, (state: ILayoutState, { disableButton }) => {
    return {
      ...state,
      disableButton
    };
  }),
  on(LayoutsActions.ForceHide, (state: ILayoutState, { forceHide }) => {
    return {
      ...state,
      forceHide
    };
  }),
  on(LayoutsActions.LayoutDateFilter, (state: ILayoutState, { dateFilter }) => {
    const timeRange: IDateRange = setCorrectDateFilter(dateFilter);
    return {
      ...state,
      dateFilter,
      startDate: timeRange.startDate,
      endDate: timeRange.endDate
    };
  }),
  on(LayoutsActions.PreviousRoute, (state: ILayoutState, { url }) => {
    return {
      ...state,
      previousRoute: url
    };
  })
);

export const getTab = (state: ILayoutState) => state.tab;
export const getActivitiesTab = (state: ILayoutState) => state.activitiesTab;
export const getUnsavedChanges = (state: ILayoutState) => state.areUnsavedChanges;
export const getLoading = (state: ILayoutState) => state.loading;
export const getTableLoading = (state: ILayoutState) => state.tableLoading;
export const getDisableButton = (state: ILayoutState) => state.disableButton;
export const getForceHide = (state: ILayoutState) => state.forceHide;
export const getPreviousRoute = (state: ILayoutState) => state.previousRoute;
export const getActivitiesDateFilter = (state: ILayoutState) => {
  return {
    dateFilter: state.dateFilter,
    startDate: state.startDate,
    endDate: state.endDate
  };
};

