import { createAction, props } from '@ngrx/store';

import { IMemberDataContentSets } from '../../modules/shared/interfaces/member/member-set-paging.interface';

export const LOAD_EXPORTED_MEMBER_DATA = '[EXPORTED_MEMBER] LOAD_EXPORTED_MEMBER_DATA';
export const LOAD_EXPORTED_MEMBER_DATA_SUCCESS = '[EXPORTED_MEMBER] LOAD_EXPORTED_MEMBER_DATA_SUCCESS';
export const SORT_MEMBER_EXPORTED_ACTIVITY = '[EXPORTED_MEMBER] SORT_MEMBER_EXPORTED_ACTIVITY';
export const SCROLL_MEMBER_EXPORTED_ACTIVITY = '[EXPORTED_MEMBER] SCROLL_MEMBER_EXPORTED_ACTIVITY';


export const LoadExportedMember = createAction(
  LOAD_EXPORTED_MEMBER_DATA,
  props<{
    projectId: number,
    page: number,
    size: number,
    sort: string,
    booked: boolean,
    startDate: number,
    endDate: number
  }>()
);

export const LoadExportedMemberDataSuccessAction = createAction(
  LOAD_EXPORTED_MEMBER_DATA_SUCCESS,
  props<{
    exportedMemberTableItems: IMemberDataContentSets
  }>()
);

export const ScrollMemberActivitiesExported = createAction(
  SCROLL_MEMBER_EXPORTED_ACTIVITY,
  props<{
    projectId: number,
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);


export const SortMemberActivitiesExported = createAction(
  SORT_MEMBER_EXPORTED_ACTIVITY,
  props<{
    projectId: number,
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);
