import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

    transform(value: number): number {
        if (!value) {
            return 0;
        }
        return (value - value % 60) / 60 ;
    }
}
