import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  public openSimpleSnackBar(message: string, duration: number, action?: string) {
    this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: 'left'
    });
  }
}
