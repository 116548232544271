import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { messages } from '../../modules/shared/constants/mesages.constants';
import * as routeConstants from '../../modules/shared/models/router.constants';
import * as fromApp from '../../store';
import { SetHeaderEmpty } from '../../store/actions/header-options.action';
import { SetUserActive } from '../../store/actions/user.actions';
import { IUser } from './../../modules/shared/interfaces/user/user.interface';

@Component({
  selector: 'app-tiles',
  templateUrl: './member-admin-view.component.html',
  styleUrls: ['./member-admin-view.component.sass']
})
export class MemberAdminViewComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router
  ) { }

  public routeConstants = routeConstants;
  public loading: Observable<boolean>;
  public user: IUser;
  public messages = messages;


  private onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.store.dispatch(new SetUserActive());

    this.store.dispatch(SetHeaderEmpty());
    this.loading = this.store.select(fromApp.getLayoutLoading);

    this.store.select(fromApp.getUserData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(data => {
      if (data.user.username && !data.projectAdmin) {
        this.router.navigate([routeConstants.projects.activityProjects]);
      } else if (data.projectAdmin && !data.projectMember) {
        this.router.navigate([routeConstants.projects.home]);
      }
      this.user = data;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
