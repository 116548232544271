<cx-footer>
     <!-- home -->
     <button
      (click)="onClickButton(routeModels.home.home)"
      mat-button >
      <i class="material-icons">home</i>
      <span class="display-none" >
          Home
      </span>
  </button>

 <!-- member projects -->
 <button
     (click)="onClickButton(routeModels.member.projects)"
     mat-button
     routerLink="/activity/projects"
     routerLinkActive="active">
     <i class="material-icons">assignment</i>
     <span class="display-none">
         {{messages.projects}}
     </span>
 </button>

 <button
     (click)="onClickButton(routeModels.activities.home)"
     mat-button
     routerLink="/activity/logs"
     routerLinkActive="active" >
     <i class="material-icons">note</i>
     <span class="display-none">
         {{ 'My ' + messages.activityLogs}}
     </span>
 </button>

 <!-- member profile -->
 <button
     mat-button
     (click)="onClickButton(routeModels.profile.home)"
     routerLink="/profile"
     routerLinkActive="active" >
     <i
     class="material-icons error"
     *ngIf="!isCostCenterSet"
     matTooltip="{{'Fill your ' + messages.costsCenter}}"
     [matTooltipPosition] = "'above'"
     ></i>
     <i class="material-icons">perm_identity</i>
     <span class="display-none">
        Profile
     </span>
 </button>
</cx-footer>
