import { createAction, props } from '@ngrx/store';

import { ICostsCenter } from '../../modules/shared/interfaces/ICostsCenter';

export const CREATE_COSTS_CENTER = '[COSTS_CENTER] CREATE_COSTS_CENTER';
export const UPDATE_COSTS_CENTER = '[COSTS_CENTER] UPDATE_COSTS_CENTER';
export const DELETE_COSTS_CENTER = '[COSTS_CENTER] DELETE_COSTS_CENTER';

export const CreateCostsCenter = createAction(
  CREATE_COSTS_CENTER,
  props<{
    costsCenter: ICostsCenter
  }>()
);

export const DeleteCostsCenter = createAction(
  DELETE_COSTS_CENTER,
  props<{
    id: number
  }>()
);

export const UpdateCostsCenter = createAction(
  UPDATE_COSTS_CENTER,
  props<{
    costsCenter: ICostsCenter,
    id: string
  }>()
);
