import { IHeader } from './header.interface';

export class HeaderOptionsModel implements IHeader {

    public navigationBackFxLayout: string;
    public navigationBackClass: string;
    public navigationBackShow: boolean;

    public timeTrackerHide: boolean;

    public headerTitle: string;
    public headerTitleClass: string;

    public searchShow: boolean;

    constructor(
        navigationBackFxLayout: string,
        navigationBackClass: string,
        navigationBackShow: boolean,
        timeTrackerHide: boolean,
        headerTitle: string,
        headerTitleClass: string,
        searchShow: boolean) {
        this.navigationBackFxLayout = navigationBackFxLayout;
        this.navigationBackClass = navigationBackClass;
        this.navigationBackShow = navigationBackShow;
        this.timeTrackerHide = timeTrackerHide;
        this.headerTitle = headerTitle;
        this.headerTitleClass = headerTitleClass;
        this.searchShow = searchShow;
    }
}
