import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration, MsalModule, MsalService
} from "@azure/msal-angular";
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication
} from "@azure/msal-browser";
import { CxMaterialConfigProviders } from "@bbraun/cortex";
import { CxHeartBeatModule } from "@bbraun/cortex/carousel";
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {environment} from "../environments/environment";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { MemberAdminViewComponent } from './components/member-admin-view/member-admin-view.component';
import { AdminExportModule } from './modules/admin-export/admin-export.module';
import { AdminOfProjectModule } from './modules/admin-of-project/admin-of-project.module';
import { CostsCentersMaintenanceModule } from './modules/costs-centers-maintenance/costs-centers-maintenance.module';
import { MemberModule } from './modules/member/member.module';
import { ProfileModule } from './modules/profile/profile.module';
import { UserResolver } from './modules/resolvers/user.resolver';
import { REDIRECT_PAGE_URL } from "./modules/shared/constants/local-storage.constants";
import { SharedModule } from './modules/shared/shared.module';
import { reducers } from './store';
import { ActivitiesExportedEffects } from './store/effects/activities-exported.effect';
import { ActivitiesNotExportedEffects } from './store/effects/activities-not-exported.effect';
import { AdminProjectsActiveEffects } from './store/effects/admin-projects-active.effect';
import { AdminProjectsFavoriteEffects } from './store/effects/admin-projects-favorite.effect';
import { AdminProjectsLockedEffects } from './store/effects/admin-projects-locked.effect';
import { CostsCenterffects } from './store/effects/costs-center.effect';
import { MemberExportedEffects } from './store/effects/member-exported.effect';
import { MemberNotExportedEffects } from './store/effects/member-not-exported.effect';
import { MemberProjectsActiveEffects } from './store/effects/member-projects-active.effect';
import { MemberProjectsFavoriteEffects } from './store/effects/member-projects-favorite.effect';
import { MemberProjectsLockedEffects } from './store/effects/member-projects-locked.effect';
import { UserEffects } from './store/effects/user.effect';

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msalConfig.auth.clientId,
            authority: environment.msalConfig.auth.authority,
            redirectUri: REDIRECT_PAGE_URL,
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.apiConfig.scopes]
        },
        loginFailedRoute: ''
    };
}

@NgModule({
  declarations: [
    AppComponent,
    MemberAdminViewComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CxHeartBeatModule,
        RouterModule,
        AppRoutingModule,
        SharedModule,
        AdminOfProjectModule,
        AdminExportModule,
        MemberModule,
        ProfileModule,
        CostsCentersMaintenanceModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionSerializability: true
            }
        }),
        EffectsModule.forRoot([
            MemberProjectsFavoriteEffects,
            MemberProjectsActiveEffects,
            MemberProjectsLockedEffects,
            AdminProjectsLockedEffects,
            MemberExportedEffects,
            MemberNotExportedEffects,
            AdminProjectsActiveEffects,
            AdminProjectsFavoriteEffects,
            ActivitiesExportedEffects,
            ActivitiesNotExportedEffects,
            UserEffects,
            CostsCenterffects
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        HttpClientModule,
        MsalModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UserResolver,
    CxMaterialConfigProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
