import { createAction, props } from '@ngrx/store';

import { IMemberDataContentSets } from './../../modules/shared/interfaces/member/member-set-paging.interface';

export const LOAD_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] LOAD_MEMBER_PROJECTS_FAVORITE_DATA';
export const SCROLL_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] SCROLL_MEMBER_PROJECTS_FAVORITE_DATA';
export const LOAD_MEMBER_PROJECTS_FAVORITE_DATA_SUCCESS = '[MEMBER-PROJECTS] LOAD_MEMBER_PROJECTS_FAVORITE_DATA_SUCCESS';
export const DELETE_FAVORITE_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] DELETE_FAVORITE_MEMBER_PROJECTS_FAVORITE_DATA';
export const CREATE_FAVORITE_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] CREATE_FAVORITE_MEMBER_PROJECTS_FAVORITE_DATA';
export const SEARCH_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] SEARCH_MEMBER_PROJECTS_FAVORITE_DATA';
export const SEARCH_SCROLL_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] SEARCH_SCROLL_MEMBER_PROJECTS_FAVORITE_DATA';
export const SORT_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] SORT_MEMBER_PROJECTS_FAVORITE_DATA';
export const SEARCH_SORT_MEMBER_PROJECTS_FAVORITE_DATA = '[MEMBER-PROJECTS] SEARCH_SORT_MEMBER_PROJECTS_FAVORITE_DATA';

export const LoadMemberProjectsFavorite = createAction(
  LOAD_MEMBER_PROJECTS_FAVORITE_DATA,
  props<{
     page: number,
     size: number,
     sort: string
  }>()
);

export const SortMemberProjectsFavorite = createAction(
  SORT_MEMBER_PROJECTS_FAVORITE_DATA,
  props<{
     page: number,
     size: number,
     sort: string
  }>()
);

export const LoadMemberProjectsFavoriteDataSuccessAction = createAction(
  LOAD_MEMBER_PROJECTS_FAVORITE_DATA_SUCCESS,
  props<{
    memberProjectFavoriteTableItems: IMemberDataContentSets
  }>()
);

export const ScrollMemberProjectsFavorite = createAction(
  SCROLL_MEMBER_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SearchSortMemberProjectsFavorite = createAction(
  SEARCH_SORT_MEMBER_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchMemberProjectsFavorite = createAction(
  SEARCH_MEMBER_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchScrollMemberProjectsFavorite = createAction(
  SEARCH_SCROLL_MEMBER_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);
