// Constants
import * as QUERY_CONSTANTS from '../../../shared/constants/query.constants';
import { IPagingOptions } from '../../interfaces/paging-options.interface';
import { ISearchingOptions } from '../../interfaces/searching-options.interface';
import { ISortingOptions } from '../../interfaces/sorting-options.interface';

export class PagingSortingSearchingOptions implements IPagingOptions, ISortingOptions, ISearchingOptions {

  static readonly DEFAULT_PAGE_SIZE = QUERY_CONSTANTS.DEFAULT_PAGE_SIZE;

  public page: number;
  public size: number;
  public sort: string;
  public searchParam: string;
  public favorite: boolean;
  public booked: boolean;
  public locked: boolean;
  public startDate: number;
  public endDate: number;

  constructor(
    page?: number, size?: number, sort?: string, favorite?: boolean, booked?: boolean,
            searchParam?: string, locked?: boolean, startDate?: number, endDate?: number) {

    if (page >= 0) {
      this.page = page;
    }

    if (size) {
      this.size = size;
    }

    if (sort) {
      this.sort = sort;
    }

    if (favorite) {
      this.favorite = favorite;
    } else {
      this.favorite = null;
    }

    if (booked !== undefined) {
      this.booked = booked;
    }

    if (searchParam !== undefined && searchParam !== null) {
      this.searchParam = searchParam;
    }

    if (locked) {
      this.locked = locked;
    }

    if (startDate) {
      this.startDate = startDate;
    }

    if (endDate) {
      this.endDate = endDate;
    }
  }
}
