import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Router} from '@angular/router';

import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {takeUntil} from 'rxjs/operators';

import {SetTabChangeAction} from '../../../store/actions/layout.actions';
import {
  ClearMemberProjectsActive,
  LoadMemberProjectsActive,
  ScrollMemberProjectsActive,
  SearchScrollMemberProjectsActive,
  SearchSortMemberProjectsActive,
  SortMemberProjectsActive
} from '../../../store/actions/member-projects-active.actions';
import {
  LoadMemberProjectsLocked,
  ScrollMemberProjectsLocked,
  SearchScrollMemberProjectsLocked,
  SearchSortMemberProjectsLocked,
  SortMemberProjectsLocked
} from '../../../store/actions/member-projects-locked.actions';
import {MemberPojectsActiveState} from '../../../store/reducers/member-projects-active.reducer';
import {MemberPojectsFavoriteState} from '../../../store/reducers/member-projects-favorite.reducer';
import {MemberPojectsLockedState} from '../../../store/reducers/member-projects-locked.reducer';
import {IFooter} from '../../shared/components/footer/footer-factory/footer.interface';
import {messages} from '../../shared/constants/mesages.constants';
import {InfinityScrollEnum} from '../../shared/enum/inifinity-scroll.enum';
import {MemberProjectsTabEnum} from '../../shared/enum/member-projects-tab.enum';
import {IMembersDataContent} from '../../shared/interfaces/member/member.data.content.interface';
import {activities} from '../../shared/models/router.constants';
import * as fromApp from './../../../store';
import {SetHeaderForMemberProjects} from './../../../store/actions/header-options.action';
import {
  LoadMemberProjectsFavorite, ScrollMemberProjectsFavorite,
  SearchMemberProjectsFavorite, SearchScrollMemberProjectsFavorite,
  SearchSortMemberProjectsFavorite, SortMemberProjectsFavorite
} from './../../../store/actions/member-projects-favorite.actions';
import * as tableParams from './../../shared/constants/parameters.for.calls.constants';
import * as routerConstants from './../../shared/models/router.constants';
import {FavoriteService} from './../../shared/services/favorite/favorite.service';

@Component({
  selector: 'app-member-projects',
  templateUrl: './member-projects.component.html',
  styleUrls: ['./member-projects.component.sass']
})
export class MemberProjectsComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private favoriteService: FavoriteService
  ) {
  }

  public MemberProjectsTabEnum = MemberProjectsTabEnum;

  public loading: Observable<boolean>;
  public currentTab: number;
  public currentSortOption = tableParams.parametersForMemberFavoriteProjects.sort;
  public currentSearch: string;
  public currentIsSearching: boolean;
  public currentPage = 0;
  public noContentText: string;
  public data: Array<IMembersDataContent>;
  public totalPages: number;
  public footerOptions: IFooter;

  private onDestroy$ = new Subject<void>();
  private onDestroyInitialLoad$ = new Subject<void>();

  ngOnInit() {
    this.store.dispatch(SetHeaderForMemberProjects());

    this.store.select(fromApp.getLayoutSelectedTab)
      .pipe(takeUntil(this.onDestroyInitialLoad$))
      .subscribe((res: number) => {
        this.currentTab = res;
        this.loadCorrectTab();
      });

    this.loading = this.store.select(fromApp.getLayoutTableLoading);

    this.onDestroyInitialLoad$.next();
    this.onDestroyInitialLoad$.complete();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.store.dispatch(ClearMemberProjectsActive());
    this.onDestroy$.complete();
  }

  public onGoTo(projectId: number) {
    this.router.navigate([routerConstants.projects.activityProjects, projectId]);

  }

  public onAdd(projectId: number) {
    this.router.navigate([activities.create, projectId]);
  }


  public onTabChange(ev: MatTabChangeEvent): void {
    this.currentTab = ev.index;
    this.currentSortOption = tableParams.parametersForMemberFavoriteProjects.sort;
    this.store.dispatch(SetTabChangeAction({tab: this.currentTab}));
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$ = new Subject<void>();
    this.currentPage = 0;
    this.loadCorrectTab();
  }

  public onSortOptions(sort) {
    this.currentPage = 0;
    this.currentSortOption = sort;

    if (this.currentIsSearching) {
      switch (this.currentTab) {
        case MemberProjectsTabEnum.active:
          this.store.dispatch(SearchSortMemberProjectsActive({
              page: tableParams.parametersForMemberActiveProjects.page,
              size: tableParams.parametersForMemberActiveProjects.size,
              sort: this.currentSortOption,
              search: this.currentSearch
            }
          ));
          break;
        case MemberProjectsTabEnum.favorite:
          this.store.dispatch(SearchSortMemberProjectsFavorite({
              page: tableParams.parametersForMemberFavoriteProjects.page,
              size: tableParams.parametersForMemberFavoriteProjects.size,
              sort: this.currentSortOption,
              search: this.currentSearch
            }
          ));
          break;
        case MemberProjectsTabEnum.locked:
          this.store.dispatch(SearchSortMemberProjectsLocked({
              page: tableParams.parametersForMemberLockedTable.page,
              size: tableParams.parametersForMemberLockedTable.size,
              sort: this.currentSortOption,
              search: this.currentSearch
            }
          ));
          break;
        default:
          break;
      }
    } else {
      switch (this.currentTab) {
        case MemberProjectsTabEnum.active:
          this.store.dispatch(SortMemberProjectsActive({
              page: tableParams.parametersForMemberActiveProjects.page,
              size: tableParams.parametersForMemberActiveProjects.size,
              sort
            }
          ));
          break;
        case MemberProjectsTabEnum.favorite:
          this.store.dispatch(SortMemberProjectsFavorite({
              page: tableParams.parametersForMemberFavoriteProjects.page,
              size: tableParams.parametersForMemberFavoriteProjects.size,
              sort
            }
          ));
          break;
        case MemberProjectsTabEnum.locked:
          this.store.dispatch(SortMemberProjectsLocked({
              page: tableParams.parametersForMemberLockedTable.page,
              size: tableParams.parametersForMemberLockedTable.size,
              sort
            }
          ));
          break;
        default:
          break;
      }
    }
  }

  public onDataSourceChangeInfinityScroll(status: number) {
    this.currentPage++;
    if (this.totalPages - 1 < this.currentPage) {
      return;
    }

    if (this.currentIsSearching) {
      switch (status) {
        case InfinityScrollEnum.favorite:
          this.store.dispatch(SearchScrollMemberProjectsFavorite({
              page: this.currentPage,
              size: tableParams.parametersForMemberFavoriteProjects.size,
              sort: this.currentSortOption,
              search: this.currentSearch
            }
          ));
          break;
        case InfinityScrollEnum.active:
          this.store.dispatch(SearchScrollMemberProjectsActive({
              page: this.currentPage,
              size: tableParams.parametersForMemberActiveProjects.size,
              sort: this.currentSortOption,
              search: this.currentSearch
            }
          ));
          break;
        case InfinityScrollEnum.locked:
          this.store.dispatch(SearchScrollMemberProjectsLocked({
              page: this.currentPage,
              size: tableParams.parametersForMemberLockedTable.size,
              sort: this.currentSortOption,
              search: this.currentSearch
            }
          ));
          break;
        default:
          break;
      }
    } else {
      switch (status) {
        case InfinityScrollEnum.favorite:
          this.store.dispatch(ScrollMemberProjectsFavorite({
              page: this.currentPage,
              size: tableParams.parametersForMemberFavoriteProjects.size,
              sort: this.currentSortOption
            }
          ));
          break;
        case InfinityScrollEnum.active:
          this.store.dispatch(ScrollMemberProjectsActive({
              page: this.currentPage,
              size: tableParams.parametersForMemberActiveProjects.size,
              sort: this.currentSortOption
            }
          ));
          break;
        case InfinityScrollEnum.locked:
          this.store.dispatch(ScrollMemberProjectsLocked({
              page: this.currentPage,
              size: tableParams.parametersForMemberLockedTable.size,
              sort: this.currentSortOption
            }
          ));
          break;
        default:
          break;
      }
    }
  }

  public onDeleteFavorite(projectId: number) {
    this.favoriteService.removeFavorite(projectId).subscribe({
      next: () => this.loadCorrectTab()
    });
  }

  public onCreateFavorite(projectId: number) {
    this.favoriteService.createFavorite(projectId).subscribe({
      next: () => this.loadCorrectTab()
    });
  }

  private loadMemberActiveTable(): void {
    this.store.select(fromApp.getMemberProjectsActiveTableData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res: MemberPojectsActiveState) => {

        this.noContentText = `There are no open ${messages.projects}`;
        this.data = res.value.content;
        this.totalPages = res.value.totalPages;
        this.currentSearch = res.searchParam;
        this.currentIsSearching = res.isSearching;
        this.currentSortOption = res.sort;
        this.currentPage = res.value.number;
      });

    this.store.dispatch(LoadMemberProjectsActive({
        page: tableParams.parametersForMemberActiveProjects.page,
        size: tableParams.parametersForMemberActiveProjects.size,
        sort: this.currentSortOption
      }
    ));

  }

  private loadMemberLockedTable(): void {
    this.store.select(fromApp.getMemberProjectsLockedTableData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res: MemberPojectsLockedState) => {
        this.noContentText = `There are no locked ${messages.projects}`;
        this.data = res.value.content;
        this.totalPages = res.value.totalPages;
        this.currentSearch = res.searchParam;
        this.currentIsSearching = res.isSearching;
        this.currentSortOption = res.sort;
        this.currentPage = res.value.number;
      });

    this.store.dispatch(LoadMemberProjectsLocked({
        page: tableParams.parametersForMemberLockedTable.page,
        size: tableParams.parametersForMemberLockedTable.size,
        sort: this.currentSortOption
      }
    ));
  }

  private loadMemberFavoriteTable(): void {
    this.store.select(fromApp.getMemberProjectsFavoriteTableData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res: MemberPojectsFavoriteState) => {
        this.noContentText = `There are no favorite ${messages.projects}`;
        this.data = res.value.content;
        this.totalPages = res.value.totalPages;
        this.currentSearch = res.searchParam;
        this.currentIsSearching = res.isSearching;
        this.currentSortOption = res.sort;
        this.currentPage = res.value.number;
      });

    this.store.dispatch(LoadMemberProjectsFavorite({
        page: tableParams.parametersForMemberFavoriteProjects.page,
        size: tableParams.parametersForMemberFavoriteProjects.size,
        sort: this.currentSortOption
      }
    ));
  }

  private loadCorrectTab() {
    switch (this.currentTab) {
      case MemberProjectsTabEnum.favorite:
        this.loadMemberFavoriteTable();
        break;
      case MemberProjectsTabEnum.active:
        this.loadMemberActiveTable();
        break;
      case MemberProjectsTabEnum.locked:
        this.loadMemberLockedTable();
        break;
      default:
        break;
    }
  }
}
