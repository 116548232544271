<div class="container">
  <div class="top-margin">
  <app-table
    [data]="costsCenters"
    [displayedColumns]="displayedColumns"
    [noContentText]="'No Costs Centers'"
    (goTo)="onGoToCostsCenter($event)"
    (delete)="onDeleteCostsCenter($event)"></app-table>
  </div>
    <cx-floating-button [icon]="'add'" (isMenuActiveChange)="redirectToNewCostsCenter()" [text]="'Add'"></cx-floating-button>
</div>

<app-footer-admin></app-footer-admin>
