import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IUser } from '../../shared/interfaces/user/user.interface';
import { UserService } from '../../shared/services/user/user.service';
import * as routerConstants from './../../shared/models/router.constants';

@Injectable()
export class AdminProjectsGuardService {

  constructor(
    private router: Router,
    private userService: UserService) { }

  public async canActivate() {
     return this.userService.getUserData().toPromise()
    .then((res: IUser) => {
      if (res.projectAdmin || res.exportAdmin) {
        return true;
      }
      this.router.navigate([routerConstants.notAuthorized.home]);
      return false;
    }).catch((err) => {
      this.router.navigate([routerConstants.home.home]);
      return false;
      }
    );
  }
}
