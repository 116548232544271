import { createReducer, on } from '@ngrx/store';

import { parametersForAdminFavoriteTable } from '../../modules/shared/constants/parameters.for.calls.constants';
import * as AdminProjectsFavoriteActions from '../actions/admin-projects-favorite.actions';
import { IAdminProjectDataContentSets } from './../../modules/shared/interfaces/admin/admin-project-set-paging.interface';

export interface AdminPojectsFavoriteState {
  value: IAdminProjectDataContentSets;
  sort: string;
  isScrolling: boolean;
  isSearching: boolean;
  searchParam: string;
}

const initialState: AdminPojectsFavoriteState = {
    value: {
      content: [],
      first: true,
      last: false,
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 1,
      sort: {
        ascending: true,
        descending: false,
        direction: 'ASC',
        ignoreCase: false,
        nullHandling: 'NATIVE',
        property: 'lastExported'
      }
    },
    sort: parametersForAdminFavoriteTable.sort,
    isScrolling: false,
    searchParam: '',
    isSearching: false
};

export const adminProjectsFavoriteDataReducer = createReducer<AdminPojectsFavoriteState>(
  initialState,
  on(AdminProjectsFavoriteActions.LoadAdminProjectsFavorite, (state: AdminPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false,
      isSearching: false,
      searchParam: ''
    };
  }),
  on(AdminProjectsFavoriteActions.SortAdminProjectsFavorite, (state: AdminPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(AdminProjectsFavoriteActions.ScrollAdminProjectsFavorite, (state: AdminPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: true,
      isSorting: false
    };
  }),
  on(AdminProjectsFavoriteActions.SearchAdminProjectsFavorite, (state: AdminPojectsFavoriteState, { search }) => {
    return {
      ...state,
      isScrolling: false,
      isSearching: true,
      searchParam: search
    };
  }),
  on(AdminProjectsFavoriteActions.SearchSortAdminProjectsFavorite, (state: AdminPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(AdminProjectsFavoriteActions.SearchScrollAdminProjectsFavorite, (state: AdminPojectsFavoriteState, { search }) => {
    return {
      ...state,
      isScrolling: true,
      isSearching: true,
      searchParam: search
    };
  }),
  on(AdminProjectsFavoriteActions.LoadAdminProjectsFavoriteDataSuccessAction, (
    state: AdminPojectsFavoriteState, { adminProjectFavoriteTableItems }) => {
      const value = { ...adminProjectFavoriteTableItems };

      if (state.isScrolling) {
        value.content =
          state.value.content.concat(adminProjectFavoriteTableItems.content);
      }

      return {
        ...state,
        value
      };
  })
);

export const getAdminTableProjectsFavorite = (state: AdminPojectsFavoriteState) => state;
export const getAdminTableSortSearchParamFavorite = (state: AdminPojectsFavoriteState) => {
  return {
  sort: state.sort,
  searchParam: state.searchParam
  };
};
