import { Injectable } from "@angular/core";

import { HeaderOptionsModel } from './header.model';

@Injectable()
export class HeaderFactory {
  public createHeadersWithOptions(
    navigationBackFxLayout: string,
    navigationBackClass: string,
    navigationBackShow: boolean,
    timeTrackerHide: boolean,
    headerTitle: string,
    headerTitleClass: string,
    searchShow: boolean
    ): HeaderOptionsModel {

    return new HeaderOptionsModel(
        navigationBackFxLayout,
        navigationBackClass,
        navigationBackShow,
        timeTrackerHide,
        headerTitle,
        headerTitleClass,
        searchShow
    );
  }
}
