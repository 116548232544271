import { createAction, props } from '@ngrx/store';

import { IAdminProjectDataContentSets } from '../../modules/shared/interfaces/admin/admin-project-set-paging.interface';

export const LOAD_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] LOAD_ADMIN_PROJECTS_FAVORITE_DATA';
export const SCROLL_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] SCROLL_ADMIN_PROJECTS_FAVORITE_DATA';
export const LOAD_ADMIN_PROJECTS_FAVORITE_DATA_SUCCESS = '[ADMIN-PROJECTS] LOAD_ADMIN_PROJECTS_FAVORITE_DATA_SUCCESS';
export const DELETE_FAVORITE_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] DELETE_FAVORITE_ADMIN_PROJECTS_FAVORITE_DATA';
export const CREATE_FAVORITE_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] CREATE_FAVORITE_ADMIN_PROJECTS_FAVORITE_DATA';
export const SEARCH_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] SEARCH_ADMIN_PROJECTS_FAVORITE_DATA';
export const SEARCH_SCROLL_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] SEARCH_SCROLL_ADMIN_PROJECTS_FAVORITE_DATA';
export const SORT_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] SORT_ADMIN_PROJECTS_FAVORITE_DATA';
export const SEARCH_SORT_ADMIN_PROJECTS_FAVORITE_DATA = '[ADMIN-PROJECTS] SEARCH_SORT_ADMIN_PROJECTS_FAVORITE_DATA';


export const LoadAdminProjectsFavorite = createAction(
  LOAD_ADMIN_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SortAdminProjectsFavorite = createAction(
  SORT_ADMIN_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const LoadAdminProjectsFavoriteDataSuccessAction = createAction(
  LOAD_ADMIN_PROJECTS_FAVORITE_DATA_SUCCESS,
  props<{
    adminProjectFavoriteTableItems: IAdminProjectDataContentSets
  }>()
);

export const ScrollAdminProjectsFavorite = createAction(
  SCROLL_ADMIN_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SearchSortAdminProjectsFavorite = createAction(
  SEARCH_SORT_ADMIN_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchAdminProjectsFavorite = createAction(
  SEARCH_ADMIN_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchScrollAdminProjectsFavorite = createAction(
  SEARCH_SCROLL_ADMIN_PROJECTS_FAVORITE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);
