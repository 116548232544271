import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromApp from '../../store';
import { SetTableLoading } from '../actions/layout.actions';
import { AdminProjectsService } from './../../modules/admin-of-project/services/admin-projects.service';
import * as AdminProjectsFavoriteActions from './../actions/admin-projects-favorite.actions';
import { ForceHide } from './../actions/layout.actions';

@Injectable()
export class AdminProjectsFavoriteEffects {

  constructor(
    private actions$: Actions,
    private adminProjectsService: AdminProjectsService,
    private store: Store<fromApp.AppState>
  ) { }

    loadAdminProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsFavoriteActions.LoadAdminProjectsFavorite
      ),
      switchMap((action) => {
          this.store.dispatch(ForceHide({forceHide: true}));
          this.store.dispatch(SetTableLoading({loading: true}));
          return this.adminProjectsService
          .getAdminTableData(action.page, action.size, action.sort, true)
          .pipe(
            map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
            this.store.dispatch(SetTableLoading({loading: false}));
              return AdminProjectsFavoriteActions.LoadAdminProjectsFavoriteDataSuccessAction({
                adminProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    sortAdminProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsFavoriteActions.SortAdminProjectsFavorite
      ),
      switchMap((action) => {
          return this.adminProjectsService
          .getAdminTableData(action.page, action.size, action.sort, true)
          .pipe(
            map(data => {
              return AdminProjectsFavoriteActions.LoadAdminProjectsFavoriteDataSuccessAction({
                adminProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    scrollAdminProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsFavoriteActions.ScrollAdminProjectsFavorite
      ),
      switchMap((action) => {
          this.store.dispatch(SetTableLoading({loading: true}));
          return this.adminProjectsService
          .getAdminTableData(action.page, action.size, action.sort, true)
          .pipe(
            map(data => {
            this.store.dispatch(SetTableLoading({loading: false}));
            return AdminProjectsFavoriteActions.LoadAdminProjectsFavoriteDataSuccessAction({
              adminProjectFavoriteTableItems: data
            });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );


    searchAdminProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsFavoriteActions.SearchAdminProjectsFavorite
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
          this.store.dispatch(ForceHide({forceHide: true}));
        return this.adminProjectsService
          .searchProjects(action.page, action.size, action.sort, true, null, action.search)
          .pipe(
            map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
             this.store.dispatch(SetTableLoading({loading: false}));
              return AdminProjectsFavoriteActions.LoadAdminProjectsFavoriteDataSuccessAction({
                adminProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

   searchScrollAdminProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsFavoriteActions.SearchScrollAdminProjectsFavorite
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.adminProjectsService
          .searchProjects(action.page, action.size, action.sort, true, null, action.search)
          .pipe(
            map(data => {
             this.store.dispatch(SetTableLoading({loading: false}));
              return AdminProjectsFavoriteActions.LoadAdminProjectsFavoriteDataSuccessAction({
                adminProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    sortScrollAdminProjectFavoriteTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsFavoriteActions.SearchScrollAdminProjectsFavorite
      ),
      switchMap((action) => {
        return this.adminProjectsService
          .searchProjects(action.page, action.size, action.sort, true, null, action.search)
          .pipe(
            map(data => {
              return AdminProjectsFavoriteActions.LoadAdminProjectsFavoriteDataSuccessAction({
                adminProjectFavoriteTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );
}
