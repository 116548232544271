import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ICostsCenter } from '../../shared/interfaces/ICostsCenter';
import { BackendDataService } from '../../shared/services/backend-data-service/backend-data-service.service';


@Injectable()
export class CostsCenterService {
  constructor(
    private backendDataService: BackendDataService
  ) { }

  public create(body: ICostsCenter): Observable<any> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.create(body, httpParams, `/rest/admin-export-projects/costsCenters`);
  }

  public put(body: ICostsCenter, id: string): Observable<any> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.update(body, httpParams, `/rest/admin-export-projects/costsCenters/${id}`);
  }

  public delete(id: number): Observable<any> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.removeByUrlOnly(httpParams, `/rest/admin-export-projects/costsCenters/${id}`);
  }

}
