import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MemberService } from '../../modules/member/services/member.service';
import * as fromApp from '../../store';
import { SetTableLoading } from '../actions/layout.actions';
import { ForceHide } from './../actions/layout.actions';
import * as MemberProjectsLockedActions from './../actions/member-projects-locked.actions';

@Injectable()
export class MemberProjectsLockedEffects {

  constructor(
    private actions$: Actions,
    private memberProjectsService: MemberService,
    private store: Store<fromApp.AppState>
  ) { }

    loadMemberProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsLockedActions.LoadMemberProjectsLocked
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort, null, true)
          .pipe(
            map(data => {
          this.store.dispatch(ForceHide({forceHide: false}));
          this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsLockedActions.LoadMemberProjectsLockedDataSuccessAction({
                memberProjectLockedTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    scrollMemberProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsLockedActions.ScrollMemberProjectsLocked
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort, null, true)
          .pipe(
            map(data => {
          this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsLockedActions.LoadMemberProjectsLockedDataSuccessAction({
                memberProjectLockedTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    sortMemberProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsLockedActions.SortMemberProjectsLocked
      ),
      switchMap((action) => {
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort, null, true)
          .pipe(
            map(data => {
              return MemberProjectsLockedActions.LoadMemberProjectsLockedDataSuccessAction({
                memberProjectLockedTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchMemberProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsLockedActions.SearchMemberProjectsLocked
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading:true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, null, true, action.search)
          .pipe(
            map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
             this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsLockedActions.LoadMemberProjectsLockedDataSuccessAction({
                memberProjectLockedTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchSortMemberProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsLockedActions.SearchSortMemberProjectsLocked
      ),
      switchMap((action) => {
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, null, true, action.search)
          .pipe(
            map(data => {
              return MemberProjectsLockedActions.LoadMemberProjectsLockedDataSuccessAction({
                memberProjectLockedTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchScrollMemberProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsLockedActions.SearchScrollMemberProjectsLocked
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, null, true, action.search)
          .pipe(
            map(data => {
          this.store.dispatch(SetTableLoading({loading: false}));
          return MemberProjectsLockedActions.LoadMemberProjectsLockedDataSuccessAction({
            memberProjectLockedTableItems: data
          });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );
}
