import { createReducer, on } from '@ngrx/store';

import { HeaderFactory } from '../../modules/shared/components/header/header-factory/header.factory';
import { HeaderOptionsModel } from '../../modules/shared/components/header/header-factory/header.model';
import * as HeaderOptions from '../actions/header-options.action';
import { messages } from './../../modules/shared/constants/mesages.constants';

const initialState: HeaderOptionsModel = {
    navigationBackFxLayout: null,
    navigationBackClass: null,
    navigationBackShow: null,
    timeTrackerHide: null,
    headerTitle: null,
    headerTitleClass: null,
    searchShow: null
};
const headerFactory = new HeaderFactory();

export const headerOptionsReducer = createReducer<HeaderOptionsModel>(
  initialState,
  on(HeaderOptions.SetHeaderForAdminProjects, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'start center', 'top-margin-7', false, true, messages.projects, 'top-margin-7', true
    );
  }),
  on(HeaderOptions.SetHeaderForAdminNewProjectAndLogs, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'start center', '', true, true, 'New ' + messages.project, '', false
    );
  }),
  on(HeaderOptions.SetHeaderForAdminUpload, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'start center', '', false, true, messages.upload, '', false
    );
  }),
  on(HeaderOptions.SetHeaderForExportAdmin, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'start center', 'top-margin-7', false, true, messages.export, 'top-margin-7', false
    );
  }),
  on(HeaderOptions.SetHeaderForActivityHome, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'start center', 'top-margin-7', false, false, 'My ' + messages.activityLogs, 'top-margin-6-8', true
    );
  }),
  on(HeaderOptions.SetHeaderForActivityCreate, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'space-between center', '', true, false, 'New ' + messages.activity, '', false
    );
  }),
  on(HeaderOptions.SetHeaderWithOnlyTitle, (state: HeaderOptionsModel, { title }) => {
    return headerFactory.createHeadersWithOptions(
      'start center', 'top-margin-7', false, true, title, 'top-margin-7', false
    );
  }),
  on(HeaderOptions.SetHeaderForEntry, (state: HeaderOptionsModel, { title }) => {
    return headerFactory.createHeadersWithOptions(
      'start center', '', true, false, title, '', false
    );
  }),
  on(HeaderOptions.SetHeaderWithBack, (state: HeaderOptionsModel, { title }) => {
    return headerFactory.createHeadersWithOptions(
      'start center', '', true, true, title, '', false
    );
  }),
  on(HeaderOptions.SetHeaderForMemberProjects, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'start center', 'top-margin-7', false, false, messages.projects, 'top-margin-7', true
    );
  }),
  on(HeaderOptions.SetHeaderEmpty, (state: HeaderOptionsModel) => {
    return headerFactory.createHeadersWithOptions(
      'start center', 'top-margin-7', false, true, '', '', false
    );
  }),
  on(HeaderOptions.SetHeader, (state: HeaderOptionsModel, { title }) => {
      const value = JSON.parse(JSON.stringify(title));
      return {
        ...state,
        headerTitle : value
      };
  })
);

export const getHeaderOptions = (state: HeaderOptionsModel) => state;
