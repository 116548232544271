<app-table
    [data]="data"
    [displayedColumns]="displayedColumns"
    [currentSortOption]="currentSortOption"
    [loading]="loading"
    [noContentText]="noContentText"
    [titleColumnText]="titleColumnText"
    (dataSourceChangeInfinityScroll)="onDataSourceChangeInfinityScroll()"
    (sortOptions)="onSortOptions($event)"
    (goTo)="onGoToEntry($event)"
    (delete)= "onDeleteEntry($event)"
    (copyEmitter)="onCopy($event)"
></app-table>
