import { createAction, props } from '@ngrx/store';

import { IMemberDataContentSets } from './../../modules/shared/interfaces/member/member-set-paging.interface';

export const LOAD_NOT_EXPORTED_MEMBER_DATA = '[NOT_EXPORTED_MEMBER] LOAD_NOT_EXPORTED_MEMBER_DATA';
export const LOAD_NOT_EXPORTED_MEMBER_DATA_SUCCESS = '[NOT_EXPORTED_MEMBER] LOAD_NOT_EXPORTED_MEMBER_DATA_SUCCESS';
export const DELETE_ENTRY_MEMBER = '[NOT_EXPORTED_MEMBER] DELETE_ENTRY_MEMBER';
export const SORT_MEMBER_NOT_EXPORTED_ACTIVITY = '[NOT_EXPORTED_MEMBER] SORT_MEMBER_NOT_EXPORTED_ACTIVITY';
export const SCROLL_MEMBER_NOT_EXPORTED_ACTIVITY = '[NOT_EXPORTED_MEMBER] SCROLL_MEMBER_NOT_EXPORTED_ACTIVITY';
export const TAB_MEMBER_ACTIVITY = '[MEMBER] TAB_MEMBER_ACTIVITY';

export const LoadNotExportedMember = createAction(
  LOAD_NOT_EXPORTED_MEMBER_DATA,
  props<{
    projectId: number,
    page: number,
    size: number,
    sort: string,
    booked: boolean,
    startDate: number,
    endDate: number
  }>()
);

export const LoadNotExportedMemberDataSuccessAction = createAction(
  LOAD_NOT_EXPORTED_MEMBER_DATA_SUCCESS,
  props<{
    notExportedMemberTableItems: IMemberDataContentSets
  }>()
);

export const DeleteEntryMemberAction = createAction(
  DELETE_ENTRY_MEMBER,
  props<{
    projectId: number,
    entryId: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const ScrollMemberActivitiesNotExported = createAction(
  SCROLL_MEMBER_NOT_EXPORTED_ACTIVITY,
  props<{
    projectId: number,
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const SortMemberActivitiesNotExported = createAction(
  SORT_MEMBER_NOT_EXPORTED_ACTIVITY,
  props<{
    projectId: number,
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const MemberActivitiesTab = createAction(
  TAB_MEMBER_ACTIVITY,
  props<{
    tab: number
  }>()
);
