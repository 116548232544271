import { createAction, props } from "@ngrx/store";

export const SET_TIME_TRACKER_TIME = '[TIME-TRACKER] SET_TIME_TRACKER_TIME';

export const SetTimeTrackerTime = createAction(
  SET_TIME_TRACKER_TIME,
  props<{
    time: string
  }>()
);
