import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpParamsFactory } from '../../factory/http-params/http-params.factory';
import { IUserFormBody } from '../../interfaces/user/userFormBody.interface';
import { BackendDataService } from '../backend-data-service/backend-data-service.service';
import { IUser } from './../../interfaces/user/user.interface';

@Injectable()
export class UserService {

  constructor(
    private backendDataService: BackendDataService,
    private httpParamsFactory: HttpParamsFactory
  ) { }

  public getUserData(): Observable<IUser> {
    const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams();
    return this.backendDataService.get(httpParams, 'profile');
  }

  public updateUserData(body: IUserFormBody): Observable<IUserFormBody> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.update(body, httpParams, `profile/_update`);
  }

}
