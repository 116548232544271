import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { HttpParamsFactory } from '../../shared/factory/http-params/http-params.factory';
import { PagingFactory } from '../../shared/factory/paging/paging.factory';
import { PagingSortingSearchingOptions } from '../../shared/factory/paging/paging-sorting-searching-options.model';
import { IAdminFormBody } from '../../shared/interfaces/admin/admin-body-table.interface';
import { IAdminProjectDataContentSets } from '../../shared/interfaces/admin/admin-project-set-paging.interface';
import { BackendDataService } from '../../shared/services/backend-data-service/backend-data-service.service';
import { IAdminProjectsDataContent } from './../../shared/interfaces/admin/admin.projects.data.content.interface';

@Injectable()
export class AdminProjectsService {

  constructor(
    private backendDataService: BackendDataService,
    private httpParamsFactory: HttpParamsFactory,
    private pagingFactory: PagingFactory
  ) { }

  public getAdminTableData(
    page?: number,
    size?: number,
    sort?: string,
    favorite?: boolean,
    locked?: boolean): Observable<IAdminProjectDataContentSets> {

    const options: PagingSortingSearchingOptions = this.pagingFactory.
                    createPagingSortingSearchingOptions(page, size, sort, favorite, null, null, locked);

    const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
    return this.backendDataService.get(httpParams, 'rest/admin-projects');
  }

  public getAdminActivitiesForProjectTableData( projectId: number,
    page?: number,
    size?: number,
    sort?: string,
    favorite?: null,
    booked?: boolean): Observable<IAdminProjectDataContentSets> {

  const options: PagingSortingSearchingOptions = this.pagingFactory.
            createPagingSortingSearchingOptions(page, size, sort, favorite, booked);

  const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
  return this.backendDataService.get(httpParams, `rest/admin-projects/${projectId}/entries`);
  }

  public searchProjects(
    page?: number, size?: number, sort?: string, favorite?: boolean, locked?: boolean, searchParam?: string
  ): Observable<IAdminProjectDataContentSets> {

  const options: PagingSortingSearchingOptions = this.pagingFactory.
    createPagingSortingSearchingOptions(page, size, sort, favorite, null, searchParam, locked);

  const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
    return this.backendDataService.get(httpParams, `rest/admin-projects`);
  }

  public createAdminProject(body: IAdminFormBody): Observable <IAdminFormBody> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.create(body, httpParams, 'rest/admin-projects');
  }

  public updateProject(body: IAdminFormBody): Observable<IAdminProjectsDataContent> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.update(body, httpParams, `rest/admin-projects/${body.projectId}`);
  }
}
