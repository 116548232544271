import { messages } from '../modules/shared/constants/mesages.constants';
import { timePeriod } from '../modules/shared/constants/time-period.constants';

export const visualizeNoContentNotExported = (selectedOpiton: string) => {
    return selectedOpiton === timePeriod.all ?
    `There are no Not Exported ${messages.activityLogs}` :
    `There are no Not Exported ${messages.activityLogs} for ${selectedOpiton.toLocaleLowerCase()}`;
};

export const visualizeNoContentExported = (selectedOpiton: string) => {
    return selectedOpiton === timePeriod.all ?
    `There are no Exported ${messages.activityLogs}` :
    `There are no Exported ${messages.activityLogs} for ${selectedOpiton.toLocaleLowerCase()}`;
};
