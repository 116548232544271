import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {MsalGuard} from "@azure/msal-angular";
import {BrowserUtils} from "@azure/msal-browser";

import { MemberAdminViewComponent } from './components/member-admin-view/member-admin-view.component';
import { AdminExportGuardService } from './modules/admin-export/guards/admin-export.guard';
import { AdminProjectsGuardService } from './modules/admin-of-project/guards/admin-project.guard';
import { UserResolver } from './modules/resolvers/user.resolver';

const routes: Routes = [
  // lazy loading
  { path: 'not-found', loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: 'not-authorized', loadChildren: () => import('./modules/not-authorized/not-authorized.module').then(m => m.NotAuthorizedModule)},
  { path: 'server-error', loadChildren: () => import('./modules/server-error/server-error.module').then(m => m.ServerErrorModule)},

  { path: 'activity', loadChildren: () => import('./modules/member/member.module').then(m => m.MemberModule),
    canActivate:[MsalGuard]
  },

  { path: 'activity/projects', loadChildren: () => import('./modules/new-project-and-logs/new-project-and-logs.module').then(m => m.NewProjectAndLogsModule),
          resolve: {user: UserResolver},
          canActivate:[MsalGuard]
  },

  { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
      canActivate:[MsalGuard]
  },

  { path: 'admin-projects/export-admin', loadChildren: () => import('./modules/admin-export/admin-export.module').then(m => m.AdminExportModule),
    canActivate: [MsalGuard, AdminExportGuardService]},

  { path: 'admin-projects/costs-centers',
    loadChildren: () => import('./modules/costs-centers-maintenance/costs-centers-maintenance.module').then(m => m.CostsCentersMaintenanceModule),
    canActivate: [MsalGuard, AdminExportGuardService]},

  { path: 'admin-projects', loadChildren: () => import('./modules/admin-of-project/admin-of-project.module').then(m => m.AdminOfProjectModule),
   canActivate: [MsalGuard, AdminProjectsGuardService]},

  { path: '', component: MemberAdminViewComponent, pathMatch: 'full', canActivate:[MsalGuard]},
  { path: '**', redirectTo: '' }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // Don't perform initial navigation in iframes or popups
        initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
