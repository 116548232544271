export const activities = {
    blank: '/activity',
    home: '/activity/logs',
    create: '/activity/logs/create',
    copy: '/activity/logs/create/copy'

};

export const projects = {
    create: '/activity/projects/create',
    activityProjects: '/activity/projects',
    blank: '/admin-projects',
    home: '/admin-projects/projects',
    export: '/admin-projects/export-admin/export',
    uploadLogs: '/admin-projects/export-admin/upload-logs',
    profile: '/admin-projects/profile',
    costsCenters: '/admin-projects/costs-centers',
    edit : 'activity/projects/admin-projects',
    copy : 'activity/projects/admin-projects/copy'
};

export const member = {
    projects: '/activity/projects'
};

export const profile = {
    home: '/profile'
};

export const notFound = {
    home: '/not-found'
};

export const serverError = {
    home: '/server-error'
};

export const notAuthorized = {
    home: '/not-authorized'
};

export const home = {
    home: '/'
};

