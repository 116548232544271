import { createReducer, on } from '@ngrx/store';

import { parametersForNotExported } from '../../modules/shared/constants/parameters.for.calls.constants';
import * as NotExportedActions from '../actions/activities-not-exported.actions';
import { IMemberDataContentSets } from './../../modules/shared/interfaces/member/member-set-paging.interface';


export interface ActivitiesNotExportedState {
  value: IMemberDataContentSets;
  sort: string;
  isScrolling: boolean;
  isSearching: boolean;
  searchParam: string;
  tab: number;
}

const initialState: ActivitiesNotExportedState = {
    value: {
      content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    sort: {
      ascending: true,
      descending: false,
      direction: 'ASC',
      ignoreCase: false,
      nullHandling: 'NATIVE',
      property: 'lastNotExported'
    }
    },
    sort: parametersForNotExported.sort,
    isScrolling: false,
    searchParam: '',
    isSearching: false,
    tab: 0
};

export const activitiesNotExportedMemberDataReducer = createReducer<ActivitiesNotExportedState>(
  initialState,
  on(NotExportedActions.LoadActivitiesNotExported, (state: ActivitiesNotExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false,
      isSearching: false,
      searchParam: ''
    };
  }),
  on(NotExportedActions.SortActivitiesNotExported, (state: ActivitiesNotExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(NotExportedActions.ScrollActivitiesNotExported, (state: ActivitiesNotExportedState, { sort }) => {
    return {
        ...state,
        sort,
        isScrolling: true,
        isSorting: false
    };
  }),
  on(NotExportedActions.SearchActivitiesNotExported, (state: ActivitiesNotExportedState, { search }) => {
    return {
      ...state,
      isScrolling: false,
      isSearching: true,
      searchParam: search
    };
  }),
  on(NotExportedActions.SearchSortActivitiesNotExported, (state: ActivitiesNotExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(NotExportedActions.SearchScrollActivitiesNotExported, (state: ActivitiesNotExportedState, { search }) => {
    return {
      ...state,
      isScrolling: true,
      isSearching: true,
      searchParam: search
    };
  }),
  on(NotExportedActions.LoadActivitiesNotExportedDataSuccessAction, (
    state: ActivitiesNotExportedState, {notExportedTableItems}) => {
    const value = { ...notExportedTableItems };

    if (state.isScrolling) {
    value.content = state.value.content.concat(notExportedTableItems.content);
    }
    return {
      ...state,
      value
    };
  }),
  on(NotExportedActions.ActivitiesTab, (state: ActivitiesNotExportedState) => {
    return {
      ...state,
      tab: state.tab
    };
  }),
  on(NotExportedActions.ClearNotExportedActivities, () => initialState)
);

export const getActivitiesNotExported = (state: ActivitiesNotExportedState) => state;
export const getActivitiesSortSearchParamNotExported = (state: ActivitiesNotExportedState) => {
  return {
  sort: state.sort,
  searchParam: state.searchParam
  };
};

