import { createReducer, on } from '@ngrx/store';

import { parametersForMemberActiveProjects } from '../../modules/shared/constants/parameters.for.calls.constants';
import { IMemberDataContentSets } from '../../modules/shared/interfaces/member/member-set-paging.interface';
import * as MemberProjectsActiveActions from '../actions/member-projects-active.actions';

export interface MemberPojectsActiveState {
  value: IMemberDataContentSets;
  sort: string;
  isScrolling: boolean;
  isSearching: boolean;
  searchParam: string;
}

const initialState: MemberPojectsActiveState = {
  value: {
    content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    sort: {
      ascending: true,
      descending: false,
      direction: 'ASC',
      ignoreCase: false,
      nullHandling: 'NATIVE',
      property: 'lastExported'
    }
  },
  sort: parametersForMemberActiveProjects.sort,
  isScrolling: false,
  searchParam: '',
  isSearching: false
};

export const memberProjectsActiveDataReducer = createReducer<MemberPojectsActiveState>(
  initialState,
  on(MemberProjectsActiveActions.LoadMemberProjectsActive, (state: MemberPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false,
      isSearching: false,
      searchParam: ''
    };
  }),
  on(MemberProjectsActiveActions.SortMemberProjectsActive, (state: MemberPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(MemberProjectsActiveActions.ScrollMemberProjectsActive, (state: MemberPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: true,
      isSorting: false
    };
  }),
  on(MemberProjectsActiveActions.SearchMemberProjectsActive, (state: MemberPojectsActiveState, { search }) => {
    return {
      ...state,
      isScrolling: false,
      isSearching: true,
      searchParam: search
    };
  }),
  on(MemberProjectsActiveActions.SearchSortMemberProjectsActive, (state: MemberPojectsActiveState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(MemberProjectsActiveActions.SearchScrollMemberProjectsActive, (state: MemberPojectsActiveState, { search }) => {
    return {
      ...state,
      isScrolling: true,
      isSearching: true,
      searchParam: search
    };
  }),
  on(MemberProjectsActiveActions.LoadMemberProjectsActiveDataSuccessAction, (
    state: MemberPojectsActiveState, {memberProjectActiveTableItems}) => {
      if (state.isScrolling) {
        memberProjectActiveTableItems = JSON.parse(JSON.stringify(memberProjectActiveTableItems));
        memberProjectActiveTableItems.content = state.value.content.concat(memberProjectActiveTableItems.content);
      }

      return {
        ...state,
        value: memberProjectActiveTableItems
      };
  }),
  on(MemberProjectsActiveActions.ClearMemberProjectsActive, (state: MemberPojectsActiveState) => {
    return {
      ...state,
      sort: 'lastExported,desc'
    };
  }));

export const getMemberTableProjectsActive = (state: MemberPojectsActiveState) => state;
export const getMemberTableSortSearchParamActive = (state: MemberPojectsActiveState) => {
  return {
    sort: state.sort,
    searchParam: state.searchParam
  };
};
