import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { parametersForAdminFavoriteTable } from '../../constants/parameters.for.calls.constants';
import {
    IAdminProjectsDataContent
} from '../../interfaces/admin/admin.projects.data.content.interface';
import { IAdminProjectsProject } from '../../interfaces/admin/admin-projects-get-project.interface';
import * as fromApp from './../../../../store';
import * as routerConstants from './../../models/router.constants';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass']

})
export class TableComponent implements OnInit {

  constructor(
    private store: Store<fromApp.AppState>
  ) {}

  @Output() sortOptions: EventEmitter<string> = new EventEmitter<string>();
  @Output() dataSourceChangeInfinityScroll = new EventEmitter<number>();
  @Output() goTo = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() create = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();
  @Output() copyEmitter = new EventEmitter<any>();

  // Sort & order configuration of the table.
  @Input() displayedColumns: Array<string>;
  @Input() currentSortOption = parametersForAdminFavoriteTable.sort;
  @Input() loading: boolean;
  @Input() noContentText: string;
  @Input() fixedPixels = 162;

  public dataSource: MatTableDataSource<any>;
  public forceHide: Observable<boolean>;

  // custom
  @Input() titleColumnText = 'Title';
  @Input() currentProject: IAdminProjectsProject;
  public active: string;
  public direction: string;


  get data() {
    return this.dataSource.data;
  }

  // Updates the data used in the component.
  @Input()
      set data(value: Array<IAdminProjectsDataContent>) {
        this.active = this.currentSortOption.split(',')[0];
        this.direction = this.currentSortOption.split(',')[1];
        this.dataSource = new MatTableDataSource(value);
      }

  public ngOnInit() {
    this.forceHide = this.store.select(fromApp.getLayoutforceHide);
  }

  public onScroll(): void {
    // you have to change the value of currentPage = 0 in the parent component
    this.dataSourceChangeInfinityScroll.emit();
  }

  public onSort(ev: Sort) {
    this.currentSortOption = `${ev.active},${ev.direction}`;
    // you have to change the value of currentPage = 0 in the parent component
    this.sortOptions.emit(this.currentSortOption);
  }

  public onGoToClick(firstId: number, secondId: number, locked: boolean, costsCenterId?: number) {
    // check context

    if (costsCenterId) {
      this.goTo.emit(costsCenterId);
      return;
    }

    if ((locked || (this.currentProject && this.currentProject.project.locked)) &&
      (window.location.href.includes(routerConstants.activities.home) ||
      window.location.href.includes(routerConstants.projects.activityProjects + '/'))) {
        return;
    }

    if (secondId !== undefined) {
      this.goTo.emit({firstId, secondId});
    } else {
      this.goTo.emit(firstId);
    }
  }

  public onDelete(firstId: number, secondId?: number) {
    if (secondId !== undefined) {
      this.delete.emit({firstId, secondId});
    } else {
      this.delete.emit(firstId);
    }
    // you have to change the value of currentPage = 0 in the parent component

  }

  public onCreate(firstId: number) {
      this.create.emit(firstId);
    // you have to change the value of currentPage = 0 in the parent component

  }

  public onCopy(firstId: number, secondId?: number) {
    if (secondId !== undefined) {
      this.copyEmitter.emit({firstId, secondId});
    } else {
      this.copyEmitter.emit(firstId);
    }
  }

  public onAdd(firstId: number) {
    this.add.emit(firstId);
  }
}
