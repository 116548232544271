import { createAction, props } from '@ngrx/store';

import { IMemberDataContentSets } from '../../modules/shared/interfaces/member/member-set-paging.interface';
import {CLEAR_MEMBER_PROJECTS_ACTIVE_DATA} from "./member-projects-active.actions";

export const LOAD_NOT_EXPORTED_ACTIVITY_DATA = '[NOT_EXPORTED_ACTIVITY] LOAD_NOT_EXPORTED_ACTIVITY_DATA';
export const LOAD_NOT_EXPORTED_ACTIVITY_DATA_SUCCESS = '[NOT_EXPORTED_ACTIVITY] LOAD_NOT_EXPORTED_ACTIVITY_DATA_SUCCESS';
export const DELETE_ENTRY = '[NOT_EXPORTED_ACTIVITY] DELETE_ENTRY';
export const SORT_NOT_EXPORTED_ACTIVITY = '[NOT_EXPORTED_ACTIVITY] SORT_NOT_EXPORTED_ACTIVITY';
export const SCROLL_NOT_EXPORTED_ACTIVITY = '[NOT_EXPORTED_ACTIVITY] SCROLL_NOT_EXPORTED_ACTIVITY';
export const SEARCH_NOT_EXPORTED_ACTIVITY = '[NOT_EXPORTED_ACTIVITY] SEARCH_NOT_EXPORTED_ACTIVITY';
export const SEARCH_SORT_NOT_EXPORTED_ACTIVITY = '[NOT_EXPORTED_ACTIVITY] SEARCH_SORT_NOT_EXPORTED_ACTIVITY';
export const SEARCH_SCROLL_NOT_EXPORTED_ACTIVITY = '[NOT_EXPORTED_ACTIVITY] SEARCH_SCROLL_NOT_EXPORTED_ACTIVITY';
export const TAB_ACTIVITY = '[TAB_ACTIVITY] TAB_ACTIVITY';
export const CLEAT_NOT_EXPORTED_ACTIVITIES_DATA = '[NOT_EXPORTED_ACTIVITY] CLEAT_NOT_EXPORTED_ACTIVITIES_DATA';

export const LoadActivitiesNotExported = createAction(
  LOAD_NOT_EXPORTED_ACTIVITY_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const LoadActivitiesNotExportedDataSuccessAction = createAction(
  LOAD_NOT_EXPORTED_ACTIVITY_DATA_SUCCESS,
  props<{
    notExportedTableItems: IMemberDataContentSets
  }>()
);

export const ScrollActivitiesNotExported = createAction(
  SCROLL_NOT_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const SortActivitiesNotExported = createAction(
  SORT_NOT_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const SearchActivitiesNotExported = createAction(
  SEARCH_NOT_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number,
    search: string
  }>()
);

export const SearchSortActivitiesNotExported = createAction(
  SEARCH_SORT_NOT_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number,
    search: string
  }>()
);


export const SearchScrollActivitiesNotExported = createAction(
  SEARCH_SCROLL_NOT_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number,
    search: string
  }>()
);

export const ActivitiesTab = createAction(
  TAB_ACTIVITY,
  props<{
    tab: number
  }>()
);

export const DeleteEntryAction = createAction(
  DELETE_ENTRY,
  props<{
    projectId: number,
    entryId: number,
    sort: string,
    startDate: number,
    endDate: number,
    isSearching: boolean,
    search: string
  }>()
);

export const ClearNotExportedActivities = createAction(
    CLEAT_NOT_EXPORTED_ACTIVITIES_DATA
);
