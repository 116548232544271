import { ICostsCenter } from '../../modules/shared/interfaces/ICostsCenter';
import { IUser } from '../../modules/shared/interfaces/user/user.interface';
import * as UserActions from '../actions/user.actions';

export interface UserState {
  value: IUser;
  loading: boolean;
}

const initialState: UserState = {
  value: {
    user: {
      userId: null,
      username: null,
      costCenter: null,
      activityType: null,
      chargeable: null
    },
    projectAdmin: null,
    exportAdmin: null,
    projectMember: null,
    costsCenters: []
  },
  loading: false
};

export function userDataReducer(state = initialState, action: UserActions.Action) {
  switch (action.type) {
      case (UserActions.LOAD_USER_ACTIVE_DATA_SUCCESS):
      // sort costsCenters array
          // eslint-disable-next-line no-case-declarations
      const userData = JSON.parse(JSON.stringify(action.userData));
      userData.costsCenters.sort((a: ICostsCenter, b: ICostsCenter) => {
        const first = a.costsCenterLabel.toUpperCase();
        const second = b.costsCenterLabel.toUpperCase();
        let comparison = 0;
        if (first > second) {
          comparison = 1;
        } else if (first < second) {
          comparison = -1;
        }
        return comparison;
      });
      return {
        value: userData,
        loading: false
      };
      case (UserActions.SET_USER_ACTIVE_DATA):
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}


export const getUserData = (state: UserState) => state.value;
export const getUserDataLoading = (state: UserState) => state.loading;
