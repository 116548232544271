<div class="container-form">
    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">

        <div class="top-margin-form full-width d-flex flex-row flex-wrap align-content-center justify-content-between ">
            <mat-form-field class="date-picker width-160">
                <mat-label>Date</mat-label>
                <input
                  matInput
                  formControlName="creationDate"
                  placeholder="DATE"
                  class="grey"
                  autocomplete="off"
                  [matDatepicker]="myDatepicker"
                  readonly
                  required>
                <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
          {{defaultDate}}
            <div class="width-160 time-picker d-flex justify-content-end">
                <mat-form-field>
                    <ngx-timepicker-field formControlName="loggedTime" [format]="24" required
                        [defaultTime]="defaultTime | minuteToHoursAndMinutes"></ngx-timepicker-field>
                        <!-- This input is added due to library restriction -->
                    <mat-label>Hours</mat-label>
                    <input matInput placeholder="HOURS" formControlName="loggedTime" required autocomplete="off"
                        style="display: none">
                    <mat-error *ngIf="mainForm?.controls['loggedTime']?.invalid">
                       <span>Hours must be more than <strong>00:00</strong></span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="top-margin-40px full-width">
            <mat-form-field class="full-width">
                <mat-label>Project title</mat-label>
                <input class="grey" matInput placeholder="PROJECT TITLE" formControlName="title" required autocomplete="off">
            </mat-form-field>
        </div>

        <div class="top-margin-40px">
            <mat-form-field class="full-width">
                <mat-label>Activity</mat-label>
                <input class="grey" matInput formControlName="description" placeholder="ACTIVITY" required autocomplete="off">
                <mat-error *ngIf="mainForm?.controls['description']?.invalid">
                    <span>Activity is <strong>required</strong></span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="top-margin-30px d-flex justify-content-between align-content-center">
            <mat-checkbox
              formControlName="chargeable"
              color="primary">
                <span class="grey default-cursor">Charged</span>
            </mat-checkbox>
            <div *ngIf="
                    projectEdit?.project?.chargeable ||
                    projectNew?.project?.chargeable ||
                    projectCopy?.project?.chargeable" class="relative">
                <mat-form-field>
                    <mat-label>WBS ELEMENT</mat-label>
                    <input matInput placeholder="WBS ELEMENT" [value]="
                        projectEdit?.project?.costsAllocationText ||
                        projectNew?.project?.costsAllocationText ||
                        projectCopy?.project?.costsAllocationText
                        " disabled>
                </mat-form-field>
                <mat-error class="wbs-error" *ngIf="
                    mainFormObject?.chargeable &&
                    ((!projectEdit?.project?.costsAllocationText && projectEdit?.project?.chargeable) ||
                    (!projectNew?.project?.costsAllocationText && projectNew?.project?.chargeable) ||
                    (!projectCopy?.project?.costsAllocationText && projectCopy?.project?.chargeable))">
                    Activities will not be exported without defined WBS Element
                </mat-error>
            </div>
        </div>
        <app-button [icon]="'done'" [submit]="true" type="submit"></app-button>
    </form>
    <app-footer-member></app-footer-member>
</div>
