import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { IAdminExported } from '../../shared/interfaces/admin/admin-exported.interface';
import { AdminExportService } from '../services/admin-export.service';


@Injectable()
export class LastExportedResolver {
  constructor(private adminExportService: AdminExportService) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
      Observable<IAdminExported> {
      return this.adminExportService.getLastExported();
  }
}
