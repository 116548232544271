import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { environment } from 'src/main/typescript/environments/environment';

import { SetHeaderForAdminUpload } from '../../../store/actions/header-options.action';
import * as fromApp from './../../../store';
import {MsalService} from "@azure/msal-angular";


@Component({
  selector: 'app-upload-logs',
  templateUrl: './upload-logs.component.html',
  styles: ['p { padding-left: 15px; }']
})
export class UploadLogsComponent {

  public uploader : FileUploader;
  public file: FileItem;

  constructor(
    private store: Store<fromApp.AppState>,
    private readonly msalService: MsalService
  ) {
    this.store.dispatch(SetHeaderForAdminUpload());
      const accessTokenRequest = {
          scopes: environment.apiConfig.scopes,
          account: this.msalService.instance.getAllAccounts()[0],
      };


      this.msalService.acquireTokenSilent(accessTokenRequest).subscribe( (accessTokenReponse) => {
          if(accessTokenReponse !== null) {
              // Acquire token silent success
              let accessToken = 'Bearer ' + accessTokenReponse.accessToken;
              // Call your API with token
              this.uploader = new FileUploader({url: environment.backendURL + '/rest/admin-import-entries', authToken: accessToken})
              this.uploader.onBeforeUploadItem = (item) => {
                  item.withCredentials = false;
              }
          }
      })
  }

  onClick() {
    this.file = this.uploader.queue[this.uploader.queue.length - 1];
    this.file.upload();
  }
}
