import { Component, EventEmitter, Input, Output } from '@angular/core';

import { InfinityScrollEnum } from '../../../../shared/enum/inifinity-scroll.enum';
import { IMembersDataContent } from '../../../../shared/interfaces/member/member.data.content.interface';

@Component({
  selector: 'app-member-locked',
  templateUrl: './member-locked.component.html',
  styleUrls: ['./member-locked.component.sass']
})
export class MemberLockedComponent {
  @Output() sortOptions: EventEmitter<string> = new EventEmitter();
  @Output() dataSourceChangeInfinityScroll = new EventEmitter<number>();
  @Output() goTo = new EventEmitter<number>();
  @Output() createFavorite = new EventEmitter<number>();
  @Output() deleteFavorite = new EventEmitter<number>();
  @Output() add = new EventEmitter<number>();

  @Input() data: Array<IMembersDataContent>;
  @Input() currentTab: number;
  @Input() currentSortOption: string;
  @Input() loading: boolean;
  @Input() noContentText: string;


  public displayedColumns = ['locked', 'key', 'title', 'costsAllocationText', 'lastEntry', 'add', 'favorite', 'goTo'];

  public onSortOptions(ev) {
    this.sortOptions.emit(ev);
  }

  public onDataSourceChangeInfinityScroll() {
    this.dataSourceChangeInfinityScroll.emit(InfinityScrollEnum.locked);
  }

  public onGoTo(ev: number) {
    this.goTo.emit(ev);
  }

  public onDeleteFavorite(projectId: number) {
    this.deleteFavorite.emit(projectId);
  }

  public onCreateFavorite(projectId: number) {
    this.createFavorite.emit(projectId);
  }

  public onAdd(projectId: number) {
    this.add.emit(projectId);
  }
}
