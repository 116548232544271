import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'minuteToHoursAndMinutes'
})
export class MinuteSecondsPipe implements PipeTransform {

    transform(value: number | string): string {
        if (typeof value === 'string') {
            return value.substring(value.indexOf(':') + 2);
        }
        let minutes: number | string = value % 60;
        let hours: number | string = (value - minutes) / 60;

        if (hours < 10) {
          hours = '0' + hours;
        }
        if (minutes < 10) {
          minutes = '0' + minutes;
        }

        return hours + ':' + minutes;
    }
}
