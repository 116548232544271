export const environment = {
  environment: 'test',
  enableProdMode: false,
  backendURL: 'https://tmt.dev.bbraun.cloud/backend',
  trackingUi: {
    enable: false,
    reportingUrl: '',
    apiKey: ''
  },
  msalConfig: {
    auth: {
      clientId: '65ccfae4-acd4-4004-b416-ade216d5ea51',
      authority: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51'
    }
  },
  apiConfig: {
    scopes: ['openid', 'profile', 'email', 'offline_access', 'api://65ccfae4-acd4-4004-b416-ade216d5ea51/Backend.Access'],
    uri: 'https://tmt.dev.bbraun.cloud/backend/*'
  }
};
