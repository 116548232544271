import { IEntryFormBody } from '../interfaces/member/entry-form-object.interface';

export class CreateNewEntryFormModel implements IEntryFormBody {
  creationDate: string | Date;
  loggedTime: string ;
  chargeable: boolean;
  description: string;
  title?: string;
  projectId?: number;
  entryId?: number;

    constructor(formObject: IEntryFormBody, projectId: number, entryId?: number, title?: string) {
      this.creationDate = formObject.creationDate;
      this.loggedTime = this.timeToMinutes(formObject.loggedTime);
      this.description = formObject.description.trim();
      this.projectId = projectId;
      this.chargeable = formObject.chargeable;

      if (entryId) {
        this.entryId = entryId;
      }

      if (title) {
        this.title = title;
      }
    }

  private timeToMinutes(time: string | number) {
   if (typeof time === 'number') {
     return time.toString();
   }

// time-picker fix
   if (time.includes(' ')) {
      time = time.substring(0, time.length - 3);
    }
    const timeArr = time.split(':').map(Number);
    const result = timeArr[0] * 60 + timeArr[1];
    return result.toString();
  }
}
