<app-table
    [data]="data"
    [displayedColumns]="displayedColumns"
    [currentSortOption]="currentSortOption"
    [loading]="loading"
    [noContentText]="noContentText"
    (dataSourceChangeInfinityScroll)="onDataSourceChangeInfinityScroll()"
    (sortOptions)="onSortOptions($event)"
    (goTo)="onGoTo($event)"
    (create)="onCreateFavorite($event)"
    (delete)="onDeleteFavorite($event)"
    (copyEmitter)="onCopy($event)"
    ></app-table>
