import { createAction, props } from '@ngrx/store';

import { IAdminProjectDataContentSets } from '../../modules/shared/interfaces/admin/admin-project-set-paging.interface';

export const LOAD_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] LOAD_ADMIN_PROJECTS_LOCKED_DATA';
export const SCROLL_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] SCROLL_ADMIN_PROJECTS_LOCKED_DATA';
export const LOAD_ADMIN_PROJECTS_LOCKED_DATA_SUCCESS = '[ADMIN-PROJECTS] LOAD_ADMIN_PROJECTS_LOCKED_DATA_SUCCESS';
export const DELETE_LOCKED_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] DELETE_LOCKED_ADMIN_PROJECTS_LOCKED_DATA';
export const CREATE_LOCKED_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] CREATE_LOCKED_ADMIN_PROJECTS_LOCKED_DATA';
export const SEARCH_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] SEARCH_ADMIN_PROJECTS_LOCKED_DATA';
export const SEARCH_SCROLL_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] SEARCH_SCROLL_ADMIN_PROJECTS_LOCKED_DATA';
export const SORT_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] SORT_ADMIN_PROJECTS_LOCKED_DATA';
export const SEARCH_SORT_ADMIN_PROJECTS_LOCKED_DATA = '[ADMIN-PROJECTS] SEARCH_SORT_ADMIN_PROJECTS_LOCKED_DATA';


export const LoadAdminProjectsLocked = createAction(
  LOAD_ADMIN_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SortAdminProjectsLocked = createAction(
  SORT_ADMIN_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const LoadAdminProjectsLockedDataSuccessAction = createAction(
  LOAD_ADMIN_PROJECTS_LOCKED_DATA_SUCCESS,
  props<{
    adminProjectLockedTableItems: IAdminProjectDataContentSets
  }>()
);

export const ScrollAdminProjectsLocked = createAction(
  SCROLL_ADMIN_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SearchSortAdminProjectsLocked = createAction(
  SEARCH_SORT_ADMIN_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchAdminProjectsLocked = createAction(
  SEARCH_ADMIN_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchScrollAdminProjectsLocked = createAction(
  SEARCH_SCROLL_ADMIN_PROJECTS_LOCKED_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);
