import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CostsCenterService } from '../../modules/costs-centers-maintenance/services/costs-center.service';
import { messages } from '../../modules/shared/constants/mesages.constants';
import { projects } from '../../modules/shared/models/router.constants';
import { SnackBarService } from '../../modules/shared/services/snack-bar/snack-bar.service';
import * as CostsCenterAction from '../actions/costs-center.actions';
import * as UserActions from './../actions/user.actions';

@Injectable()
export class CostsCenterffects {

  constructor(
    private actions$: Actions,
    private costsCenterService: CostsCenterService,
    private snackBarService: SnackBarService,
    private router: Router
    ) { }

  createCostsCenter$ = createEffect(() => this.actions$.pipe(
    ofType(CostsCenterAction.CreateCostsCenter),
    switchMap((action) => {
        return this.costsCenterService.create(action.costsCenter)
          .pipe(
            map(_ => {
            this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} created`, 2000);
            this.router.navigate([projects.costsCenters]);
              return new UserActions.SetUserActive();
            }),
            catchError((err) => {
            err.status === 409 ?
            this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} ID duplication`, 2000) :
            this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} creation error`, 2000);
               return EMPTY;
             })
          );
    }))
  );

  updateCostsCenter$ = createEffect(() => this.actions$.pipe(
    ofType(CostsCenterAction.UpdateCostsCenter),
    switchMap((action) => {
        return this.costsCenterService.put(action.costsCenter, action.id)
          .pipe(
            map(_ => {
            this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} updated`, 2000);
            this.router.navigate([projects.costsCenters]);
              return new UserActions.SetUserActive();
            }),
            catchError((err) => {
              err.status === 409 ?
              this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} ID duplication`, 2000) :
              this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} update error`, 2000);
               return EMPTY;
             })
          );
    }))
  );


    deleteCostsCenter$ = createEffect(() => this.actions$.pipe(
      ofType(CostsCenterAction.DeleteCostsCenter),
      switchMap((action) => {
          return this.costsCenterService.delete(action.id)
            .pipe(
              map(_ => {
            this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} deleted`, 2000);
            return new UserActions.SetUserActive();
              }),
              catchError(() => {
              this.snackBarService.openSimpleSnackBar(`${messages.costsCenter} delete error`, 2000);
              return EMPTY;
               })
            );
      }))
    );
}
