import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {MsalGuard} from "@azure/msal-angular";

import { ProjectsComponent } from './projects/projects.component';

const routes: Routes = [
  { path: '', redirectTo: 'projects', pathMatch: 'full' },
  { path: 'projects', component: ProjectsComponent, canActivate: [MsalGuard] },
  { path: 'profile', canActivate: [MsalGuard], loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule)}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminOfProjectRoutingModule { }
