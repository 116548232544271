import { createReducer, on } from '@ngrx/store';

import { parametersForExported } from '../../modules/shared/constants/parameters.for.calls.constants';
import * as MemberExportedActions from '../actions/member-exported.actions';
import { IMemberDataContentSets } from './../../modules/shared/interfaces/member/member-set-paging.interface';

export interface MemberExportedState {
  value: IMemberDataContentSets;
  sort: string;
  isSorting: boolean;
  isScrolling: boolean;
}

const initialState: MemberExportedState = {
    value: {
      content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    sort: {
      ascending: true,
      descending: false,
      direction: 'ASC',
      ignoreCase: false,
      nullHandling: 'NATIVE',
      property: 'lastNotExported'
    }
    },
    sort: parametersForExported.sort,
    isScrolling: false,
    isSorting: false
};

export const exportedMemberDataReducer = createReducer<MemberExportedState>(
  initialState,
  on(MemberExportedActions.LoadExportedMember, (state: MemberExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isSorting: false,
      isScrolling: false
    };
  }),
  on(MemberExportedActions.SortMemberActivitiesExported, (state: MemberExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isSorting: true,
      isScrolling: false
    };
  }),
  on(MemberExportedActions.ScrollMemberActivitiesExported, (state: MemberExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: true,
      isSorting: false
    };
  }),
  on(MemberExportedActions.LoadExportedMemberDataSuccessAction, (
    state: MemberExportedState, {exportedMemberTableItems}) => {
    const value = { ...exportedMemberTableItems };

    if (state.isScrolling) {
      value.content = state.value.content.concat(exportedMemberTableItems.content);
    }

    return {
      ...state,
      value
    };
  })
);

export const getMemberExported = (state: MemberExportedState) => state;
