import { createReducer, on } from '@ngrx/store';

import { parametersForNotExported } from '../../modules/shared/constants/parameters.for.calls.constants';
import { IMemberDataContentSets } from '../../modules/shared/interfaces/member/member-set-paging.interface';
import * as MemberNotExportedActions from '../actions/member-not-exported.actions';

export interface MemberNotExportedState {
  value: IMemberDataContentSets;
  sort: string;
  isSorting: boolean;
  isScrolling: boolean;
  tab: number;
}

const initialState: MemberNotExportedState = {
    value: {
      content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    sort: {
      ascending: true,
      descending: false,
      direction: 'ASC',
      ignoreCase: false,
      nullHandling: 'NATIVE',
      property: 'lastNotExported'
    }
    },
    sort: parametersForNotExported.sort,
    isScrolling: false,
    isSorting: false,
    tab: 0
};

export const notExportedMemberDataReducer = createReducer<MemberNotExportedState>(
  initialState,
  on(MemberNotExportedActions.LoadNotExportedMember, (state: MemberNotExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isSorting: false,
      isScrolling: false
    };
  }),
  on(MemberNotExportedActions.SortMemberActivitiesNotExported, (state: MemberNotExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isSorting: true,
      isScrolling: false
    };
  }),
  on(MemberNotExportedActions.ScrollMemberActivitiesNotExported, (state: MemberNotExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: true,
      isSorting: false
    };
  }),
  on(MemberNotExportedActions.LoadNotExportedMemberDataSuccessAction, (
    state: MemberNotExportedState, { notExportedMemberTableItems }) => {
    const value = {...notExportedMemberTableItems};

    if (state.isScrolling) {
      value.content =
        state.value.content.concat(notExportedMemberTableItems.content);
    }

    return {
      ...state,
      value
    };
  }),
  on(MemberNotExportedActions.MemberActivitiesTab, (state: MemberNotExportedState, { tab }) => {
    return {
      ...state,
      tab
    };
  })
);


export const getMemberNotExported = (state: MemberNotExportedState) => state;
export const getMemberTab = (state: MemberNotExportedState) => state.tab;
