<div class="top-margin-form">
  <div class="container">
      <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">
              <div class="full-width d-flex justify-content-evenly align-content-start gap">
                <mat-form-field class="full-width">
                  <mat-label>Costs Center ID</mat-label>
                    <input matInput placeholder="Costs Center ID" formControlName="costsCenterId" required autocomplete="off">
                    <mat-error *ngIf="this.mainForm.controls['costsCenterId'].errors?.required ||
                  this.mainForm.controls['costsCenterId'].errors?.minlength ||
                  this.mainForm.controls['costsCenterId'].errors?.maxlength">
                      <span>Title is <strong>required</strong> and should be 3 characters and less than 100</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width margin-top-0">
                    <mat-label>Costs Center Label</mat-label>
                    <input matInput placeholder="Costs Center Label" formControlName="costsCenterLabel" required autocomplete="off">
                    <mat-error *ngIf="this.mainForm.controls['costsCenterLabel'].errors?.required ||
                  this.mainForm.controls['costsCenterLabel'].errors?.minlength ||
                  this.mainForm.controls['costsCenterLabel'].errors?.maxlength">
                      <span>Title is <strong>required</strong> and should be 3 characters and less than 100</span>
                  </mat-error>
                </mat-form-field>
          </div>
          <div class="full-width d-flex justify-content-evenly align-content-start gap">
              <mat-checkbox formControlName="chargeable" class="full-width" color="primary">
                Chargeable Cost Center
              </mat-checkbox>
          </div>
          <app-button [icon]="'done'" [submit]="true" type="submit"></app-button>
      </form>
  </div>
</div>

<app-footer-admin></app-footer-admin>
