import { createReducer, on } from '@ngrx/store';

import { parametersForExported } from '../../modules/shared/constants/parameters.for.calls.constants';
import * as ExportedActions from '../actions/activities-exported.actions';
import { IMemberDataContentSets } from './../../modules/shared/interfaces/member/member-set-paging.interface';


export interface ActivitiesExportedState {
  value: IMemberDataContentSets;
  sort: string;
  isScrolling: boolean;
  isSearching: boolean;
  searchParam: string;
}

const initialState: ActivitiesExportedState = {
    value: {
      content: [],
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    sort: {
      ascending: true,
      descending: false,
      direction: 'ASC',
      ignoreCase: false,
      nullHandling: 'NATIVE',
      property: 'lastExported'
    }
    },
    sort: parametersForExported.sort,
    isScrolling: false,
    searchParam: '',
    isSearching: false
};

export const activitiesExportedDataReducer = createReducer<ActivitiesExportedState>(
  initialState,
  on(ExportedActions.LoadActivitiesExported, (state: ActivitiesExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false,
      isSearching: false,
      searchParam: ''
    };
  }),
  on(ExportedActions.ScrollActivitiesExported, (state: ActivitiesExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: true,
      isSorting: false
    };
  }),
  on(ExportedActions.SortActivitiesExported, (state: ActivitiesExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isSorting: false
    };
  }),
  on(ExportedActions.SortActivitiesExported, (state: ActivitiesExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isSorting: false
    };
  }),
  on(ExportedActions.SearchActivitiesExported, (state: ActivitiesExportedState, { search }) => {
    return {
      ...state,
        isScrolling: false,
        isSearching: true,
        searchParam: search
    };
  }),
  on(ExportedActions.SearchSortActivitiesExported, (state: ActivitiesExportedState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(ExportedActions.SearchScrollActivitiesExported, (state: ActivitiesExportedState, { search }) => {
    return {
      ...state,
      isScrolling: true,
      isSearching: true,
      searchParam: search
    };
  }),
  on(ExportedActions.LoadActivitiesExportedDataSuccessAction, (
    state: ActivitiesExportedState, { exportedTableItems }) => {
    const value = {...exportedTableItems};

    if (state.isScrolling) {
      value.content = state.value.content.concat(exportedTableItems.content);
    }

    return {
      ...state,
      value
    };
  })
);

export const getActivitiesExported = (state: ActivitiesExportedState) => state;
export const getActivitiesSortSearchParamExported = (state: ActivitiesExportedState) => {
  return {
  sort: state.sort,
  searchParam: state.searchParam
  };
};

