import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from './../../shared/shared.module';
import { MemberProjectsComponent } from './member-projects.component';
import { MemberProjectsRoutingModule } from './member-projects.routing.module';
import { AllActiveComponent } from './tabs-menu/all-active/all-active.component';
import { FavoritesComponent } from './tabs-menu/favorites/favorites.component';
import { MemberLockedComponent } from './tabs-menu/member-locked/member-locked.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MemberProjectsRoutingModule
  ],
  declarations: [
    MemberProjectsComponent,
    FavoritesComponent,
    AllActiveComponent,
    MemberLockedComponent
  ]
})
export class MemberProjectsModule { }
