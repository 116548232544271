import { createAction, props } from "@ngrx/store";

export const TAB_CHANGE = '[LAYOUT] TAB_CHANGE';
export const ACTIVITIES_TAB_CHANGE = '[LAYOUT] ACTIVITIES_TAB_CHANGE';
export const UNSAVED_CHANGES = '[LAYOUT] UNSAVED_CHANGES';
export const SET_LOADING = '[LAYOUT] SET_LOADING';
export const SET_TABLE_LOADING = '[LAYOUT] SET_TABLE_LOADING';
export const SET_DISABLE_BUTTON = '[LAYOUT] SET_DISABLE_BUTTON';
export const FORCE_HIDE = '[LAYOUT] FORCE_HIDE';
export const DATE_FILTER_ACTIVITIES = '[LAYOUT] DATE_FILTER_ACTIVITIES';
export const PREVIOUS_ROUTE = '[LAYOUT] PREVIOUS_ROUTE';

// Change the Tab Action

export const SetTabChangeAction = createAction(
  TAB_CHANGE,
  props<{
    tab: number
  }>()
);

// Change the activities tab

export const SetActivitiesTabChangeAction = createAction(
    ACTIVITIES_TAB_CHANGE,
    props<{
        activitiesTab: number
    }>()
);

// Check if user has touch form and not saved the changed data
export const SetUnsavedChangesAction = createAction(
  UNSAVED_CHANGES,
  props<{
    areUnsavedChanges: boolean
  }>()
);

export const SetLoading = createAction(
  SET_LOADING,
  props<{
    loading: boolean
  }>()
);

export const SetTableLoading = createAction(
  SET_TABLE_LOADING,
  props<{
    loading: boolean
  }>()
);

export const SetDisableButtonOnRequest = createAction(
  SET_DISABLE_BUTTON,
  props<{
    disableButton: boolean
  }>()
);

export const ForceHide = createAction(
  FORCE_HIDE,
  props<{
    forceHide: boolean
  }>()
);

export const LayoutDateFilter = createAction(
  DATE_FILTER_ACTIVITIES,
  props<{
    dateFilter: string
  }>()
);

export const PreviousRoute = createAction(
  PREVIOUS_ROUTE,
  props<{
    url: string
  }>()
);
