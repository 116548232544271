import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MemberService } from '../../modules/member/services/member.service';
import * as fromApp from '../../store';
import { SetTableLoading } from '../actions/layout.actions';
import { ForceHide } from './../actions/layout.actions';
import * as MemberProjectsActiveActions from './../actions/member-projects-active.actions';

@Injectable()
export class MemberProjectsActiveEffects {

  constructor(
    private actions$: Actions,
    private memberProjectsService: MemberService,
    private store: Store<fromApp.AppState>
  ) { }

    loadMemberProjectActiveTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsActiveActions.LoadMemberProjectsActive
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort)
          .pipe(
            map(data => {
          this.store.dispatch(ForceHide({forceHide: false}));
          this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsActiveActions.LoadMemberProjectsActiveDataSuccessAction({
                memberProjectActiveTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    scrollMemberProjectActiveTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsActiveActions.ScrollMemberProjectsActive
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort)
          .pipe(
            map(data => {
          this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsActiveActions.LoadMemberProjectsActiveDataSuccessAction({
                memberProjectActiveTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    sortMemberProjectActiveTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsActiveActions.SortMemberProjectsActive
      ),
      switchMap((action) => {
        return this.memberProjectsService
          .getMemberProjectsTableData(action.page, action.size, action.sort)
          .pipe(
            map(data => {
              return MemberProjectsActiveActions.LoadMemberProjectsActiveDataSuccessAction({
                memberProjectActiveTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchMemberProjectActiveTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsActiveActions.SearchMemberProjectsActive
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, null, null, action.search)
          .pipe(
            map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
             this.store.dispatch(SetTableLoading({loading: false}));
              return MemberProjectsActiveActions.LoadMemberProjectsActiveDataSuccessAction({
                memberProjectActiveTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchSortMemberProjectActiveTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsActiveActions.SearchSortMemberProjectsActive
      ),
      switchMap((action) => {
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, null, null, action.search)
          .pipe(
            map(data => {
              return MemberProjectsActiveActions.LoadMemberProjectsActiveDataSuccessAction({
                memberProjectActiveTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchScrollMemberProjectActiveTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        MemberProjectsActiveActions.SearchScrollMemberProjectsActive
      ),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberProjectsService
          .searchProjects(action.page, action.size, action.sort, null, null, action.search)
          .pipe(
            map(data => {
          this.store.dispatch(SetTableLoading({loading: false}));
          return MemberProjectsActiveActions.LoadMemberProjectsActiveDataSuccessAction({
            memberProjectActiveTableItems: data
          });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );
}
