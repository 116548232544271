import { Injectable } from "@angular/core";

import { PagingSortingSearchingOptions } from './paging-sorting-searching-options.model';

@Injectable()
export class PagingFactory {
  /**
   * Creates a Pageable object. All parameters are optional
   * some parameters will be initialised with default values,
   * other will be and interpreted by the REST endpoints with reasonable defaults parameters at the server side.
   *
   */
  public createPagingSortingSearchingOptions(page?: number,
                                             size?: number,
                                             sort?: string,
                                             favorite?: boolean,
                                             booked?: boolean,
                                             searchParam?: string,
                                             locked?: boolean,
                                             startDate?: number,
                                             endDate?: number
                                            ): PagingSortingSearchingOptions {
    return new PagingSortingSearchingOptions(
                                            page,
                                            size,
                                            sort,
                                            favorite,
                                            booked,
                                            searchParam,
                                            locked,
                                            startDate,
                                            endDate);
  }

}
