import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { HttpParamsFactory } from '../../shared/factory/http-params/http-params.factory';
import { PagingFactory } from '../../shared/factory/paging/paging.factory';
import { PagingSortingSearchingOptions } from '../../shared/factory/paging/paging-sorting-searching-options.model';
import { IEntryFormBody } from '../../shared/interfaces/member/entry-form-object.interface';
import { IMembersDataContent } from '../../shared/interfaces/member/member.data.content.interface';
import { IMemberProject } from '../../shared/interfaces/member/member-projects.interface';
import { IMemberDataContentSets } from '../../shared/interfaces/member/member-set-paging.interface';
import { ITimeDestribution } from '../../shared/interfaces/member/time-destribution.interface';
import { BackendDataService } from '../../shared/services/backend-data-service/backend-data-service.service';

@Injectable()
export class MemberService {

  constructor(
    private backendDataService: BackendDataService,
    private httpParamsFactory: HttpParamsFactory,
    private pagingFactory: PagingFactory
  ) { }

  // GET
  public getMemberActivitiesTableData(page?: number,
                                      size?: number,
                                      sort?: string,
                                      favorite?: null,
                                      booked?: boolean,
                                      startDate?: number,
                                      endDate?: number,
                                      searchParam?: string):
    Observable<IMemberDataContentSets> {

    const options: PagingSortingSearchingOptions = this.pagingFactory.
                    createPagingSortingSearchingOptions(
                      page,
                      size,
                      sort,
                      favorite,
                      booked,
                      searchParam,
                      null,
                      startDate,
                      endDate);

    const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
    return this.backendDataService.get(httpParams, 'rest/entries');
  }

  public getMemberProjectsTableData(
    page?: number,
    size?: number,
    sort?: string,
    favorite?: boolean,
    locked?: boolean): Observable<IMemberDataContentSets> {

      const options: PagingSortingSearchingOptions = this.pagingFactory.
                createPagingSortingSearchingOptions(page, size, sort, favorite, null, null, locked);

      const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
      return this.backendDataService.get(httpParams, 'rest/projects');
  }

  public searchProjects(
    page?: number, size?: number, sort?: string, favorite?: boolean, locked?: boolean, searchParam?: string
  ): Observable<IMemberDataContentSets> {

  const options: PagingSortingSearchingOptions = this.pagingFactory.
    createPagingSortingSearchingOptions(page, size, sort, favorite, null, searchParam, locked);

  const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
    return this.backendDataService.get(httpParams, `rest/projects`);
  }

  public getActivity(projectId: number, entryId: number): Observable<IMembersDataContent> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.get(httpParams, `rest/projects/${projectId}/entries/${entryId}`);
  }

  public getProject(projectId: number): Observable<IMemberProject> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.get(httpParams, `rest/projects/${projectId}`);
  }

  public getMemberActivitiesForProjectTableData( projectId: number,
    page?: number, size?: number, sort?: string, favorite?: null, booked?: boolean,
    startDate?: number, endDate?: number): Observable<IMemberDataContentSets> {

  const options: PagingSortingSearchingOptions =
    this.pagingFactory.createPagingSortingSearchingOptions(
      page, size, sort, favorite, booked, null, null, startDate, endDate);

  const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
  return this.backendDataService.get(httpParams, `rest/projects/${projectId}/entries`);
  }

  public getTimeDestributionForProject(
    projectId: number,
    startDate: number,
    endDate: number): Observable<ITimeDestribution> {
      const options: PagingSortingSearchingOptions = this.pagingFactory.
      createPagingSortingSearchingOptions(null, null, null, null, null, null, null, startDate, endDate);

      const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
      return this.backendDataService.get(httpParams, `rest/projects/${projectId}/entries/_timeDistribution`);
  }

  public getTimeDestribution(startDate: number, endDate: number): Observable<ITimeDestribution> {
    const options: PagingSortingSearchingOptions = this.pagingFactory.
    createPagingSortingSearchingOptions(null, null, null, null, null, null, null, startDate, endDate);

    const httpParams = this.httpParamsFactory.returnPagingSortingSearchingParams(options);
    return this.backendDataService.get(httpParams, `/rest/entries/_timeDistribution`);
  }

  // PUT
  public updateActivity(body: IEntryFormBody, projectId: number, entryId: number): Observable<IMembersDataContent> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.update(body, httpParams, `rest/projects/${projectId}/entries/${entryId}`);
  }

  // POST
  public createNewEntry(body: IEntryFormBody, projectId: number): Observable <IEntryFormBody> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.create(body, httpParams, `rest/projects/${projectId}/entries`);
  }

  // DELETE
  public removeEntry(projectId: number, entryId: number): Observable <IEntryFormBody> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.removeByUrlOnly(httpParams, `rest/projects/${projectId}/entries/${entryId}`);
  }

}
