<cx-header>
      <cx-header-back-navigator *ngIf="headerOptions?.navigationBackShow" (click)="onBack()"></cx-header-back-navigator>
      <cx-header-title>
        {{headerOptions?.headerTitle}}
      </cx-header-title>
      <cx-search-bar
        *ngIf="headerOptions?.searchShow"
        (search)="onKeyPress($event)"
        [value]="selectedValue"
        [blurOnValueChange]="false"
      ></cx-search-bar>
    <cx-header-quickbar>
    </cx-header-quickbar>
</cx-header>
