import {Location} from '@angular/common';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

import * as fromApp from '../../../../store';
import {LoadActivitiesExported, SearchActivitiesExported} from '../../../../store/actions/activities-exported.actions';
import {SearchAdminProjectsFavorite} from '../../../../store/actions/admin-projects-favorite.actions';
import {
  LoadMemberProjectsActive,
  SearchMemberProjectsActive
} from '../../../../store/actions/member-projects-active.actions';
import {
  LoadMemberProjectsFavorite,
  SearchMemberProjectsFavorite
} from '../../../../store/actions/member-projects-favorite.actions';
import {
  LoadMemberProjectsLocked,
  SearchMemberProjectsLocked
} from '../../../../store/actions/member-projects-locked.actions';
import * as tableParams from '../../constants/parameters.for.calls.constants';
import {AdminProjectsTabEnum} from '../../enum/admin-projects-tab.enum';
import {MemberActivitiesTabEnum} from '../../enum/member-activities-tab.enum';
import {MemberProjectsTabEnum} from '../../enum/member-projects-tab.enum';
import {ISortSearchParam} from '../../interfaces/ISortSearchParam';
import {environment} from './../../../../../environments/environment';
import {IDateRange} from './../../../../helper-functions/get-range.functions';
import {
  LoadActivitiesNotExported,
  SearchActivitiesNotExported
} from './../../../../store/actions/activities-not-exported.actions';
import {
  LoadAdminProjectsActive,
  SearchAdminProjectsActive
} from './../../../../store/actions/admin-projects-active.actions';
import {LoadAdminProjectsFavorite} from './../../../../store/actions/admin-projects-favorite.actions';
import {
  LoadAdminProjectsLocked,
  SearchAdminProjectsLocked
} from './../../../../store/actions/admin-projects-locked.actions';
import * as routerConstants from './../../models/router.constants';
import {AppNavigateService} from './../../services/navigation/app-navigate.service';
import {IHeader} from './header-factory/header.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private location: Location,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private navigationService: AppNavigateService
  ) {
  }

  @Input() currentURL: string;

  public inputFocused = false;
  public inputTermChanged = new Subject<string>();
  public selectedValue: Subject<string> = new Subject<string>();
  public currentInput = '';
  public headerOptions: IHeader;
  public environment = environment;
  private currentTabProjects: number;
  private currentTabActivities: number;
  private currentSort: string;
  private lastSearch = '';
  private startDate: number;
  private endDate: number;
  private onDestroy$ = new Subject<void>();
  private isFormDirty: boolean;

  ngOnInit() {
    this.store.select(fromApp.getLayoutUnSavedChanges)
      .subscribe(res => {
        this.isFormDirty = res;
      });

    this.store.select(fromApp.getHeaderOptions)
      .subscribe(res => {
        this.headerOptions = res;
      });

    if (this.currentURL?.includes(routerConstants.projects.blank)) {
      this.store.select(fromApp.getAdminProjectsActiveSortSearchData)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });

      this.store.select(fromApp.getAdminProjectsLockedSortSearchData)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });

      this.store.select(fromApp.getAdminProjectsFavoriteSortSearchData)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });
    } else {
      // date filter/ startdate/ enddate subscription
      this.store.select(fromApp.getLayoutActivitiesDateFilter)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: IDateRange) => {
          this.startDate = res.startDate;
          this.endDate = res.endDate;
        });

      // member subscriptions
      this.store.select(fromApp.getMemberProjectsActiveSortSearchData)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });

      this.store.select(fromApp.getMemberProjectsFavoriteSortSearchData)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });

      this.store.select(fromApp.getMemberProjectsLockedSortSearchData)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });

      // activities subscriptions
      this.store.select(fromApp.getActivitiesSortSearchNotExported)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });

      this.store.select(fromApp.getActivitiesSortSearchExported)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: ISortSearchParam) => {
          this.currentSort = res.sort;
          this.currentInput = res.searchParam;
          this.selectedValue.next(this.currentInput);
        });

    }

    this.store.select(fromApp.getLayoutSelectedTab)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res: number) => {
        this.currentInput = '';
        this.selectedValue.next(this.currentInput);
        this.currentTabProjects = res;
      });

    this.store.select(fromApp.getLayoutActivitiesSelectedTab)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res: number) => {
        this.currentInput = '';
        this.selectedValue.next(this.currentInput);
        this.currentTabActivities = res;
      });

    this.subscribeToOnTermChange();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onKeyPress(ev: string) {
    this.currentInput = ev;
    this.selectedValue.next(this.currentInput);
    if (this.lastSearch !== this.currentInput) {
      this.lastSearch = this.currentInput;
      this.search(this.currentInput);
    }
  }

  private subscribeToOnTermChange() {
    this.inputTermChanged.pipe(
      distinctUntilChanged())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((inputTerm: string) => {
        this.currentInput = inputTerm;
        this.selectedValue.next(this.currentInput);
        if (inputTerm.length === 0) {
          this.lastSearch = this.currentInput;
          this.initialCall();
        }
      });
  }

  private search(value: string) {
    // admin-projects
    if (this.currentTabProjects ===
      AdminProjectsTabEnum.favorite && this.currentURL === routerConstants.projects.home) {
      this.store.dispatch(SearchAdminProjectsFavorite({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          search: value
        }
      ));
    } else if (this.currentTabProjects ===
      AdminProjectsTabEnum.active && this.currentURL === routerConstants.projects.home) {
      this.store.dispatch(SearchAdminProjectsActive({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          search: value
        }
      ));
    } else if (this.currentTabProjects ===
      AdminProjectsTabEnum.locked && this.currentURL === routerConstants.projects.home) {
      this.store.dispatch(SearchAdminProjectsLocked({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          search: value
        }
      ));
    } else if (this.currentTabProjects ===
      MemberProjectsTabEnum.active && this.currentURL === routerConstants.projects.activityProjects) {
      // member-projects
      this.store.dispatch(SearchMemberProjectsActive({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          search: value
        }
      ));
    } else if (this.currentTabProjects === MemberProjectsTabEnum.favorite
      && this.currentURL === routerConstants.projects.activityProjects) {
      // member-projects
      this.store.dispatch(SearchMemberProjectsFavorite({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          search: value
        }
      ));
    } else if (this.currentTabProjects === MemberProjectsTabEnum.locked
      && this.currentURL === routerConstants.projects.activityProjects) {
      // member-projects
      this.store.dispatch(SearchMemberProjectsLocked({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          search: value
        }
      ));
    } else if (this.currentTabActivities ===
      MemberActivitiesTabEnum.notExported && this.currentURL === routerConstants.activities.home) {
      // activities not exported
      this.store.dispatch(SearchActivitiesNotExported(
        {
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          startDate: this.startDate,
          endDate: this.endDate,
          search: value
        }
      ));
    } else if (this.currentTabActivities ===
      MemberActivitiesTabEnum.exported && this.currentURL === routerConstants.activities.home) {
      // activities exported
      this.store.dispatch(SearchActivitiesExported(
        {
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          startDate: this.startDate,
          endDate: this.endDate,
          search: value
        }
      ));
    }
  }


  private initialCall() {
    if (this.currentTabProjects ===
      AdminProjectsTabEnum.active && this.currentURL === routerConstants.projects.home) {
      this.store.dispatch(LoadAdminProjectsActive({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort
        }
      ));
    } else if (this.currentTabProjects ===
      AdminProjectsTabEnum.locked && this.currentURL === routerConstants.projects.home) {
      this.store.dispatch(LoadAdminProjectsLocked({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort
        }
      ));
    } else if (this.currentTabProjects ===
      AdminProjectsTabEnum.favorite && this.currentURL === routerConstants.projects.home) {
      this.store.dispatch(LoadAdminProjectsFavorite({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort
        }
      ));
    } else if (this.currentTabProjects ===
      MemberProjectsTabEnum.active && this.currentURL === routerConstants.projects.activityProjects) {
      // member- projects
      this.store.dispatch(LoadMemberProjectsActive({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort
        }
      ));
    } else if (this.currentTabProjects === MemberProjectsTabEnum.favorite
      && this.currentURL === routerConstants.projects.activityProjects) {
      // member- projects
      this.store.dispatch(LoadMemberProjectsFavorite({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort
        }
      ));
    } else if (this.currentTabProjects ===
      MemberProjectsTabEnum.locked && this.currentURL === routerConstants.projects.activityProjects) {
      // member- projects
      this.store.dispatch(LoadMemberProjectsLocked({
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort
        }
      ));
    } else if (this.currentTabActivities ===
      MemberActivitiesTabEnum.notExported && this.currentURL === routerConstants.activities.home) {
      // activities not exported
      this.store.dispatch(LoadActivitiesNotExported(
        {
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          startDate: this.startDate,
          endDate: this.endDate
        }
      ));
    } else if (this.currentTabActivities ===
      MemberActivitiesTabEnum.exported && this.currentURL === routerConstants.activities.home) {
      // activities not exported
      this.store.dispatch(LoadActivitiesExported(
        {
          page: tableParams.parametersForAdminFavoriteTable.page,
          size: tableParams.parametersForAdminFavoriteTable.size,
          sort: this.currentSort,
          startDate: this.startDate,
          endDate: this.endDate
        }
      ));
    }
  }

  public onBack(): void {
    if (this.isFormDirty) {
      this.navigationService.goBackWithConfirmation();
    } else {
      window.location.href.includes(routerConstants.projects.activityProjects + '/') &&
      !window.location.href.includes(routerConstants.projects.blank)
        ? this.router.navigate([routerConstants.projects.activityProjects])
        : this.location.back();
    }
  }
}
