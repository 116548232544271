import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpParamsFactory } from '../shared/factory/http-params/http-params.factory';
import { PagingFactory } from './../shared/factory/paging/paging.factory';
import { BackendDataService } from './../shared/services/backend-data-service/backend-data-service.service';
import { AdminOfProjectRoutingModule } from './admin-of-project-routing.module';
import { AdminProjectsGuardService } from './guards/admin-project.guard';
import { ProjectsModule } from './projects/projects.module';
import { AdminProjectsService } from './services/admin-projects.service';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ProjectsModule,
    SharedModule,
    AdminOfProjectRoutingModule
  ],
  providers: [
    AdminProjectsService,
    HttpParamsFactory,
    PagingFactory,
    BackendDataService,
    AdminProjectsGuardService
  ],
  exports: [
  ]
})
export class AdminOfProjectModule { }
