import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { forkJoin, Observable } from 'rxjs';

import { IAdminProjectsProject } from '../../shared/interfaces/admin/admin-projects-get-project.interface';
import { IMembersDataContent } from '../../shared/interfaces/member/member.data.content.interface';
import { MemberService } from './../services/member.service';


@Injectable()
export class MemberEntryAndProjectResolver {
    constructor(
      private memberService: MemberService
    ) {}

    public resolve(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot):
        Observable<[IMembersDataContent, IAdminProjectsProject]> {
          return forkJoin(
            this.memberService.getActivity(route.params.projectId, route.params.entryId),
            this.memberService.getProject(route.params.projectId));
    }
}
