import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { BackendDataService } from '../backend-data-service/backend-data-service.service';


@Injectable()
export class FavoriteService {

  constructor(
    private backendDataService: BackendDataService
  ) { }

  public createFavorite(projectId: number): Observable <any> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.createByUrlOnly(httpParams, `rest/projects/${projectId}/_favorite`);
  }

  public removeFavorite(projectId: number): Observable <any> {
    const httpParams: HttpParams = new HttpParams();
    return this.backendDataService.removeByUrlOnly(httpParams, `rest/projects/${projectId}/_favorite`);
  }
}
