<div class="container-form">
    <div class="top-margin-form full-width">
        <h3>Here you can upload activity logs directly into TMT application.</h3>
        <h3>It is mandatory to use our Excel template. Wrong or corrupted data will not be imported in the application.</h3>

    </div>

    <div style="display: flex">
        <button style="margin-right: 15px;">
            <a href="/assets/files/tmt-import-file-template.xlsx" target="_blank">Download template</a>
        </button>
        <input type="file" ng2FileSelect [uploader]="uploader" accept=".xls,.xlsx"/>
        <cx-heart-beat [loading]="uploader.isUploading" style="width: 10%"></cx-heart-beat>
    </div>


    <div style="margin-top: 10px;">
        <span class="error">{{ file?.isError ? 'There was a problem with uploading, please check your file.' : ''}}</span>
        <span class="green">{{ file?.isSuccess ? 'Activity logs were uploaded successfully.' : ''}}</span>
    </div>

    <div style="margin-top: 30px;">
        All fields in the Excel template file are required:
        <p>4-2-2 user </p>
        <p>Date in the format - "dd.mm.yyyy" </p>
        <p>Task - short description of the activity </p>
        <p>No of hours spent - accepted by the following format - "hh,mm" </p>
        <p>Project ID </p>
        <p>WBS </p>
    </div>
    <app-button (click)="uploader.queue.length && onClick()" [icon]="'cloud_upload'" [submit]="true"></app-button>
</div>

<app-footer-admin></app-footer-admin>
