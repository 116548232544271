import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { CxHeartBeatModule } from "@bbraun/cortex/carousel";
import { FileUploadModule } from 'ng2-file-upload';

import { SharedModule } from '../shared/shared.module';
import { AdminExportRoutingModule } from './admin-export.routing.module';
import { ExportComponent } from './export/export.component';
import { AdminExportGuardService } from './guards/admin-export.guard';
import { LastExportedResolver } from './resolvers/lastExported.resolver';
import { AdminExportService } from './services/admin-export.service';
import { UploadLogsComponent } from './upload-logs/upload-logs.component';

@NgModule({
  imports: [
    CommonModule,
    AdminExportRoutingModule,
    FileUploadModule,
    SharedModule,
    CxHeartBeatModule,
    MatButtonModule
  ],
  declarations: [ExportComponent, UploadLogsComponent],
  providers: [AdminExportGuardService, AdminExportService, LastExportedResolver]
})
export class AdminExportModule { }
