import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import * as routerConstants from './../models/router.constants';
import { SnackBarService } from '../services/snack-bar/snack-bar.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private inj: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const snackBar = this.inj.get(SnackBarService);
        const router = this.inj.get(Router);

        return next.handle(req).pipe(tap(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // continue
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 403:
                            router?.navigate([routerConstants.notAuthorized.home]);
                        break;
                        case 404:
                            router?.navigate([routerConstants.notFound.home]);
                        break;
                        case 500:
                            snackBar.openSimpleSnackBar('Something went wrong', 3000);
                        break;
                        case 502:
                            router?.navigate([routerConstants.serverError.home]);
                        break;
                    }
                }
            }
        ));
    }
}
