import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';

import {
  LoadAdminProjectsActive,
  ScrollAdminProjectsActive,
  SearchAdminProjectsActive,
  SearchScrollAdminProjectsActive,
  SearchSortAdminProjectsActive,
  SortAdminProjectsActive
} from '../../../store/actions/admin-projects-active.actions';
import { LoadAdminProjectsFavorite,
  ScrollAdminProjectsFavorite,
  SearchAdminProjectsFavorite,
  SearchScrollAdminProjectsFavorite,
  SearchSortAdminProjectsFavorite,
  SortAdminProjectsFavorite} from '../../../store/actions/admin-projects-favorite.actions';
import { LoadAdminProjectsLocked, ScrollAdminProjectsLocked,
  SearchAdminProjectsLocked } from '../../../store/actions/admin-projects-locked.actions';
import { SetHeaderForAdminProjects } from '../../../store/actions/header-options.action';
import { SetTabChangeAction} from '../../../store/actions/layout.actions';
import { AdminPojectsActiveState } from '../../../store/reducers/admin-projects-active.reducer';
import { AdminPojectsFavoriteState } from '../../../store/reducers/admin-projects-favorite.reducer';
import { AdminPojectsLockedState } from '../../../store/reducers/admin-projects-locked.reducer';
import { InfinityScrollEnum } from '../../shared/enum/inifinity-scroll.enum';
import { IAdminProjectsDataContent } from '../../shared/interfaces/admin/admin.projects.data.content.interface';
import * as fromRoot from './../../../store';
import { SearchScrollAdminProjectsLocked,
  SearchSortAdminProjectsLocked, SortAdminProjectsLocked } from './../../../store/actions/admin-projects-locked.actions';
import { messages } from './../../shared/constants/mesages.constants';
import * as tableParams from './../../shared/constants/parameters.for.calls.constants';
import * as enums from './../../shared/enum/admin-projects-tab.enum';
import { AdminProjectsTabEnum } from './../../shared/enum/admin-projects-tab.enum';
import * as routerConstants from './../../shared/models/router.constants';
import { FavoriteService } from './../../shared/services/favorite/favorite.service';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.sass']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private favoriteService: FavoriteService
  ) {}

  public enums = enums;

  public currentPage = tableParams.parametersForAdminFavoriteTable.page;
  public totalPages: number;
  public data: Array<IAdminProjectsDataContent>;
  public loading: Observable<boolean>;
  public noContentText = 'No items';
  public currentTab = 0;
  public currentSortOption = tableParams.parametersForAdminFavoriteTable.sort;
  public currentSearch = '';
  public currentIsSearching = false;

  private onDestroy$ = new Subject<void>();
  private onDestroyInitialLoad$ = new Subject<void>();

  ngOnInit() {
    this.store.dispatch(SetHeaderForAdminProjects());
    // Initial Load
    this.store.select(fromRoot.getLayoutSelectedTab)
    .pipe(takeUntil(this.onDestroyInitialLoad$))
    .subscribe((res: number) => {
      this.currentTab = res;
      this.loadCorrectTab();
    });

    this.loading = this.store.select(fromRoot.getLayoutTableLoading);

    this.onDestroyInitialLoad$.next();
    this.onDestroyInitialLoad$.complete();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public addRedirect() {
    this.router.navigate([routerConstants.projects.create]);
  }

  public onTabChange(ev: MatTabChangeEvent): void {
    this.currentTab = ev.index;
    this.store.dispatch(SetTabChangeAction({ tab: this.currentTab }));
    this.currentPage = 0;
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$ = new Subject<void>();
    this.loadCorrectTab();
  }

  public onSortOptions(sort) {
    this.currentPage = 0;
    this.currentSortOption = sort;

    if (this.currentIsSearching) {
      switch (this.currentTab) {
        case enums.AdminProjectsTabEnum.active:
          this.store.dispatch(SearchSortAdminProjectsActive({
            page: tableParams.parametersForAdminActiveTable.page,
            size: tableParams.parametersForAdminActiveTable.size,
            sort: this.currentSortOption,
            search: this.currentSearch
          }
          ));
          break;
        case enums.AdminProjectsTabEnum.favorite:
          this.store.dispatch(SearchSortAdminProjectsFavorite({
            page: tableParams.parametersForAdminActiveTable.page,
            size: tableParams.parametersForAdminActiveTable.size,
            sort: this.currentSortOption,
            search: this.currentSearch
          }
          ));
          break;
        case enums.AdminProjectsTabEnum.locked:
          this.store.dispatch(SearchSortAdminProjectsLocked({
            page: tableParams.parametersForAdminActiveTable.page,
            size: tableParams.parametersForAdminActiveTable.size,
            sort: this.currentSortOption,
            search: this.currentSearch
          }
          ));
          break;
        default:
          break;
      }
    } else {
      switch (this.currentTab) {
        case enums.AdminProjectsTabEnum.active:
          this.store.dispatch(SortAdminProjectsActive({
            page: tableParams.parametersForAdminActiveTable.page,
            size: tableParams.parametersForAdminActiveTable.size,
            sort
          }
          ));
          break;
        case enums.AdminProjectsTabEnum.favorite:
          this.store.dispatch(SortAdminProjectsFavorite({
            page: tableParams.parametersForAdminFavoriteTable.page,
            size: tableParams.parametersForAdminFavoriteTable.size,
            sort
          }
          ));
          break;
        case enums.AdminProjectsTabEnum.locked:
          this.store.dispatch(SortAdminProjectsLocked({
            page: tableParams.parametersForAdminFavoriteTable.page,
            size: tableParams.parametersForAdminFavoriteTable.size,
            sort
          }
          ));
          break;
        default:
          break;
      }
    }
  }

  public onDataSourceChangeInfinityScroll(status: number) {
    this.currentPage++;
    if (this.totalPages - 1 < this.currentPage) {
      return;
    }

    if (this.currentIsSearching) {
      switch (status) {
        case InfinityScrollEnum.favorite:
          this.store.dispatch(SearchScrollAdminProjectsFavorite({
            page: this.currentPage,
            size: tableParams.parametersForAdminFavoriteTable.size,
            sort: this.currentSortOption,
            search: this.currentSearch
          }
          ));
          break;
        case InfinityScrollEnum.active:
          this.store.dispatch(SearchScrollAdminProjectsActive({
            page: this.currentPage,
            size: tableParams.parametersForAdminFavoriteTable.size,
            sort: this.currentSortOption,
            search: this.currentSearch
          }
          ));
          break;
        case InfinityScrollEnum.locked:
          this.store.dispatch(SearchScrollAdminProjectsLocked({
            page: this.currentPage,
            size: tableParams.parametersForAdminFavoriteTable.size,
            sort: this.currentSortOption,
            search: this.currentSearch
          }
          ));
          break;
        default:
          break;
      }
    } else {
      switch (status) {
        case InfinityScrollEnum.favorite:
          this.store.dispatch(ScrollAdminProjectsFavorite({
            page: this.currentPage,
            size: tableParams.parametersForAdminActiveTable.size,
            sort: this.currentSortOption
          }
          ));
          break;
        case InfinityScrollEnum.active:
            this.store.dispatch(ScrollAdminProjectsActive({
              page: this.currentPage,
              size: tableParams.parametersForAdminFavoriteTable.size,
              sort: this.currentSortOption
            }
          ));
          break;
        case InfinityScrollEnum.locked:
          this.store.dispatch(ScrollAdminProjectsLocked({
            page: this.currentPage,
            size: tableParams.parametersForAdminActiveTable.size,
            sort: this.currentSortOption
          }
        ));
          break;
        default:
          break;
      }
    }
  }

  public onGoTo(projectId: number) {
    this.router.navigate([routerConstants.projects.edit, projectId]);
  }

  public onCopyProject(projectId: number) {
    this.router.navigate([routerConstants.projects.copy, projectId]);
  }

  public onDeleteFavorite(projectId: number) {
    this.favoriteService.removeFavorite(projectId).subscribe({
      next: () => this.loadCorrectTab()
    });
  }

  public onCreateFavorite(projectId: number) {
    this.favoriteService.createFavorite(projectId).subscribe({
      next: () => this.loadCorrectTab()
    });
  }

  private loadAdminActiveTable(): void {
    this.store.select(fromRoot.getAdminProjectsActiveTableData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((res: AdminPojectsActiveState) => {

      this.noContentText = `There are no open ${messages.projects}`;
      this.data = res.value.content;
      this.totalPages = res.value.totalPages;
      this.currentSearch = res.searchParam;
      this.currentIsSearching = res.isSearching;
      this.currentSortOption = res.sort;
      this.currentPage = res.value.number;
    });

    this.store.dispatch(LoadAdminProjectsActive({
      page: tableParams.parametersForAdminActiveTable.page,
      size: tableParams.parametersForAdminActiveTable.size,
      sort: this.currentSortOption
    }));
  }

  private loadAdminLockedTable(): void {
    this.store.select(fromRoot.getAdminProjectsLockedTableData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((res: AdminPojectsLockedState) => {
      this.noContentText = `There are no locked ${messages.projects}`;
      this.data = res.value.content;
      this.totalPages = res.value.totalPages;
      this.currentSearch = res.searchParam;
      this.currentIsSearching = res.isSearching;
      this.currentSortOption = res.sort;
      this.currentPage = res.value.number;
    });

    this.store.dispatch(LoadAdminProjectsLocked({
      page: tableParams.parametersForAdminActiveTable.page,
      size: tableParams.parametersForAdminActiveTable.size,
      sort: this.currentSortOption
    }));
  }

  private loadAdminFavoriteTable(): void {
    this.store.select(fromRoot.getAdminProjectsFavoriteTableData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((res: AdminPojectsFavoriteState) => {
      this.noContentText = `There are no favorite ${messages.projects}`;
      this.data = res.value.content;
      this.totalPages = res.value.totalPages;
      this.currentSearch = res.searchParam;
      this.currentIsSearching = res.isSearching;
      this.currentSortOption = res.sort;
      this.currentPage = res.value.number;
    });

    this.store.dispatch(LoadAdminProjectsFavorite({
      page: tableParams.parametersForAdminFavoriteTable.page,
      size: tableParams.parametersForAdminFavoriteTable.size,
      sort: this.currentSortOption
    }));
  }

  private loadCorrectTab() {
    switch (this.currentTab) {
      case enums.AdminProjectsTabEnum.favorite:
      this.loadAdminFavoriteTable();
        break;
      case enums.AdminProjectsTabEnum.active:
        this.loadAdminActiveTable();
        break;
      case enums.AdminProjectsTabEnum.locked:
        this.loadAdminLockedTable();
        break;
      default:
        break;
    }
  }
}
