import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IAdminProjectsDataContent } from './../../../../shared/interfaces/admin/admin.projects.data.content.interface';


@Component({
  selector: 'app-admin-active-projects',
  templateUrl: './admin-active-projects.component.html',
  styleUrls: ['./admin-active-projects.component.sass']
})
export class AdminActiveProjectsComponent {
  @Output() sortOptions: EventEmitter<string> = new EventEmitter();
  @Output() dataSourceChangeInfinityScroll = new EventEmitter<number>();
  @Output() goTo = new EventEmitter<number>();
  @Output() createFavorite = new EventEmitter<number>();
  @Output() deleteFavorite = new EventEmitter<number>();
  @Output() copyEmitter = new EventEmitter<number>();

  @Input() data: Array<IAdminProjectsDataContent>;
  @Input() currentSortOption: string;
  @Input() loading: boolean;
  @Input() noContentText: string;

  public displayedColumns =
  ['locked', 'key', 'title', 'costsAllocationText', 'lastExported', 'duplicateProject', 'favorite', 'goTo'];

  public onDataSourceChangeInfinityScroll() {
    this.dataSourceChangeInfinityScroll.emit(1);
  }

  public onSortOptions(ev) {
    this.sortOptions.emit(ev);
  }

  public onGoTo(ev: number) {
    this.goTo.emit(ev);
  }

  public onDeleteFavorite(projectId: number) {
    this.deleteFavorite.emit(projectId);
  }

  public onCreateFavorite(projectId: number) {
    this.createFavorite.emit(projectId);
  }

  public onCopy(ev) {
    this.copyEmitter.emit(ev);
  }
}
