import { createReducer, on } from '@ngrx/store';

import { INITIAL_STATE_TIMETRACKER } from '../../modules/shared/constants/local-storage.constants';
import * as TimeTrackerActions from '../actions/time-tracker.action';


export interface ITimeTrackerState {
  time: string;
}

const initialState: ITimeTrackerState = {
  time: INITIAL_STATE_TIMETRACKER
};

export const timeTrackerReducer = createReducer<ITimeTrackerState>(
initialState,
on(TimeTrackerActions.SetTimeTrackerTime, (state: ITimeTrackerState, { time }) => {
  return {
    ...state,
    time
  };
})
);

export const getTime = (state: ITimeTrackerState) => state.time;
