export const messages = {
    activity: 'Activity',
    activityLogs: 'Activities',
    entries: 'entries',
    project: 'Project',
    projects: 'Projects',
    costsCenter: 'Costs Center',
    confirmation: 'Yes',
    cancel: 'Cancel',
    contactAdmin: 'Contact your administrator',
    checkConnection: 'Check you connection',
    export: 'Export',
    upload: 'Upload logs',
    profile: 'Profile',
    noWBSElement: '',
    wbs: 'WBS Element',
    duplicatedWBS: 'WBS Element duplication',
    duplicatedEmptyWBS: 'Duplicated projects with empty WBS Element'
};
