import { Component, EventEmitter, Input, Output } from '@angular/core';

import { fadeIn } from '../../animations/fade-in.animation';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass'],
  animations: [fadeIn()]

})
export class ButtonComponent {

  @Output() addRedirect = new EventEmitter();
  @Input() icon = 'add';
  @Input() submit = false;

  public onClick() {
    this.addRedirect.emit();
  }

}
