import { createReducer, on } from '@ngrx/store';

import { parametersForMemberFavoriteProjects } from '../../modules/shared/constants/parameters.for.calls.constants';
import { IMemberDataContentSets } from '../../modules/shared/interfaces/member/member-set-paging.interface';
import * as MemberProjectsFavoriteActions from '../actions/member-projects-favorite.actions';

export interface MemberPojectsFavoriteState {
  value: IMemberDataContentSets;
  sort: string;
  isScrolling: boolean;
  isSearching: boolean;
  searchParam: string;
}

const initialState: MemberPojectsFavoriteState = {
    value: {
      content: [],
      first: true,
      last: false,
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 1,
      sort: {
        ascending: true,
        descending: false,
        direction: 'ASC',
        ignoreCase: false,
        nullHandling: 'NATIVE',
        property: 'lastExported'
      }
    },
    sort: parametersForMemberFavoriteProjects.sort,
    isScrolling: false,
    searchParam: '',
    isSearching: false
};

export const memberProjectsFavoriteDataReducer = createReducer<MemberPojectsFavoriteState>(
  initialState,
  on(MemberProjectsFavoriteActions.LoadMemberProjectsFavorite, (state: MemberPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false,
      isSearching: false,
      searchParam: ''
    };
  }),
  on(MemberProjectsFavoriteActions.SortMemberProjectsFavorite, (state: MemberPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(MemberProjectsFavoriteActions.ScrollMemberProjectsFavorite, (state: MemberPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: true,
      isSorting: false
    };
  }),
  on(MemberProjectsFavoriteActions.SearchMemberProjectsFavorite, (state: MemberPojectsFavoriteState, { search }) => {
    return {
      ...state,
      isScrolling: false,
      isSearching: true,
      searchParam: search
    };
  }),
  on(MemberProjectsFavoriteActions.SearchSortMemberProjectsFavorite, (state: MemberPojectsFavoriteState, { sort }) => {
    return {
      ...state,
      sort,
      isScrolling: false
    };
  }),
  on(MemberProjectsFavoriteActions.SearchScrollMemberProjectsFavorite, (
    state: MemberPojectsFavoriteState, { search }) => {
    return {
      ...state,
      isScrolling: true,
      isSearching: true,
      searchParam: search
    };
  }),
  on(MemberProjectsFavoriteActions.LoadMemberProjectsFavoriteDataSuccessAction, (
    state: MemberPojectsFavoriteState, { memberProjectFavoriteTableItems }) => {
      const value = { ...memberProjectFavoriteTableItems };

      if (state.isScrolling) {
        value.content =
          state.value.content.concat(memberProjectFavoriteTableItems.content);
      }
      return {
        ...state,
        value
      };
  })
);

export const getMemberTableProjectsFavorite = (state: MemberPojectsFavoriteState) => state;
export const getMemberTableSortSearchParamFavorite = (state: MemberPojectsFavoriteState) => {
  return {
  sort: state.sort,
  searchParam: state.searchParam
  };
};
