import { createAction, props } from "@ngrx/store";

export const SET_HEADER_ADMIN_PROJECTS = '[HEADER] SET_HEADER_ADMIN_PROJECTS';
export const SET_HEADER_EMPTY = '[HEADER] SET_HEADER_EMPTY';
export const SET_HEADER_UPLOAD_ADMIN = '[HEADER] SET_HEADER_UPLOAD_ADMIN';
export const SET_HEADER_EXPORT_ADMIN = '[HEADER] SET_HEADER_EXPORT_ADMIN';
export const SET_HEADER_ACTIVITY_HOME = '[HEADER] SET_HEADER_ACTIVITY_HOME';
export const SET_HEADER_ACTIVITY_CREATE = '[HEADER] SET_HEADER_ACTIVITY_CREATE';
export const SET_HEADER_WITH_ONLY_TITLE = '[HEADER] SET_HEADER_WITH_ONLY_TITLE';
export const SET_HEADER_ENTRY = '[HEADER] SET_HEADER_ENTRY';
export const SET_HEADER_TITLE_WITH_BACK = '[HEADER] SET_HEADER_TITLE_WITH_BACK';
export const SET_HEADER_MEMBER_PROJECTS = '[HEADER] SET_HEADER_MEMBER_PROJECTS';
export const SET_HEADER_ADMIN_MEMBER_VIEW = '[HEADER] SET_HEADER_ADMIN_MEMBER_VIEW';
export const SET_HEADER_TEXT = '[HEADER] SET_HEADER_TEXT';


export const SetHeaderForAdminProjects = createAction(
    SET_HEADER_ADMIN_PROJECTS
);

export const SetHeaderForAdminNewProjectAndLogs = createAction(
    SET_HEADER_EMPTY
);

export const SetHeaderForAdminUpload = createAction(
    SET_HEADER_UPLOAD_ADMIN
);

export const SetHeaderForExportAdmin = createAction(
    SET_HEADER_EXPORT_ADMIN
);

export const SetHeaderForActivityHome = createAction(
    SET_HEADER_ACTIVITY_HOME
);

export const SetHeaderForActivityCreate = createAction(
    SET_HEADER_ACTIVITY_CREATE
);

export const SetHeaderWithOnlyTitle = createAction(
    SET_HEADER_WITH_ONLY_TITLE,
    props<{
        title: string
    }>()
);

export const SetHeaderForMemberProjects = createAction(
    SET_HEADER_MEMBER_PROJECTS
);

export const SetHeaderForEntry = createAction(
    SET_HEADER_ENTRY,
    props<{
        title: string
    }>()
);

export const SetHeaderWithBack = createAction(
    SET_HEADER_TITLE_WITH_BACK,
    props<{
        title: string
    }>()
);

export const SetHeaderEmpty = createAction(
    SET_HEADER_ADMIN_MEMBER_VIEW
);

export const SetHeader = createAction(
    SET_HEADER_TEXT,
    props<{
        title: string
    }>()
);

