<div>
    <div *ngIf="data.length && (forceHide | async) === false">
        <mat-table
        matSort
        infiniteScroll
        matSortDisableClear
        [dataSource]="dataSource"
        matSortActive="{{active}}"
        matSortDirection="{{direction}}"
        (matSortChange)="onSort($event)"
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="20"
        [infiniteScrollThrottle]="500"
        [infiniteScrollUpDistance]="fixedPixels"
        >

            <!-- key Column -->
            <ng-container matColumnDef="key">
                <mat-header-cell class="missingFxAlignment13" *matHeaderCellDef mat-sort-header>
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'key,asc' || currentSortOption === 'key,desc'}"
                        >ID</span>
                        <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'key,asc',
                        'desc': currentSortOption === 'key,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment13" *matCellDef="let data"
                [ngClass]="{ 'error': data.costsAllocationText === null && !data.locked && data.chargeable }"
                > {{data.key}} </mat-cell>
            </ng-container>

            <!-- costsAllocationText Column -->
            <ng-container matColumnDef="costsAllocationText">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="missingFxAlignment26">
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'costsAllocationText,asc' || currentSortOption === 'costsAllocationText,desc'}"
                      >WBS element</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'costsAllocationText,asc',
                                    'desc': currentSortOption === 'costsAllocationText,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell *matCellDef="let data"  class="d-flex justify-content-between align-items-center missingFxAlignment26">
                    {{data.costsAllocationText}}
                </mat-cell>
            </ng-container>

            <!-- title Column -->
            <ng-container matColumnDef="title">
                <mat-header-cell class="missingFxAlignment25" *matHeaderCellDef mat-sort-header>
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'title,asc' || currentSortOption === 'title,desc'}"
                        >{{titleColumnText}}</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'title,asc',
                                    'desc': currentSortOption === 'title,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment25" *matCellDef="let data"
                [ngClass]="{ 'error': data.costsAllocationText === null && !data.locked && data.chargeable }"
                > {{data.title}} </mat-cell>
            </ng-container>

            <!-- lastExported Column -->
            <ng-container matColumnDef="lastExported">
                <mat-header-cell class="missingFxAlignment13" *matHeaderCellDef mat-sort-header>
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'lastExported,asc' || currentSortOption === 'lastExported,desc'}"
                        >Last export</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'lastExported,asc',
                                    'desc': currentSortOption === 'lastExported,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment13" *matCellDef="let data"> <span>
                        {{data.lastExported | date:'dd/MM/yyyy'}}
                    </span> </mat-cell>
            </ng-container>

            <!-- lastEntry Column -->
            <ng-container matColumnDef="lastEntry">
                    <mat-header-cell class="missingFxAlignment15" *matHeaderCellDef mat-sort-header>
                            <span [ngClass]="{ 'active-table-text': currentSortOption === 'lastEntry,asc' || currentSortOption === 'lastEntry,desc'}"
                                >Last activity</span>
                            <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'lastEntry,asc',
                                            'desc': currentSortOption === 'lastEntry,desc'}">&#xE315;</i>
                        </mat-header-cell>
                <mat-cell class="missingFxAlignment15" *matCellDef="let data">
                    <span>
                        {{data.lastEntry | date:'dd/MM/yyyy'}}
                    </span></mat-cell>
            </ng-container>

            <!-- favorite Column -->
            <ng-container matColumnDef="favorite">
                <mat-header-cell class="missingFxAlignment5" *matHeaderCellDef></mat-header-cell>
                <mat-cell class="missingFxAlignment5" *matCellDef="let data">
                    <mat-icon *ngIf="data.favorite"
                    (click)="onDelete(data.projectId);$event.stopPropagation()"
                    class="opaciity-hover-0-5 mat table-icons"
                    matTooltip="Remove project from favourites">
                        favorite
                    </mat-icon>
                    <mat-icon *ngIf="!data.favorite"
                    (click)="onCreate(data.projectId); $event.stopPropagation()"
                    class="material-icons table-icons"
                    matTooltip="Add project to favourites">
                        favorite_border
                    </mat-icon>
                </mat-cell>
            </ng-container>

              <!-- locked Column -->
              <ng-container matColumnDef="locked">
                <mat-header-cell class="missingFxAlignment6" *matHeaderCellDef></mat-header-cell>
                <mat-cell class="missingFxAlignment6" *matCellDef="let data">
                    <mat-icon
                    *ngIf="data.locked"
                    class="material-icons table-icons"
                    matTooltip="Project is locked">
                        lock
                    </mat-icon>
                    <mat-icon
                    *ngIf="!data.locked"
                    class="material-icons table-icons green"
                    matTooltip="Project is unlocked">
                        lock_open
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="missingFxAlignment45">
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'description,asc' || currentSortOption === 'description,desc'}"
                        >Activity</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'description,asc',
                                    'desc': currentSortOption === 'description,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment45" *matCellDef="let data"> {{data.description}} </mat-cell>
            </ng-container>

            <!-- chargeable Column -->
            <ng-container matColumnDef="chargeable">
                <mat-header-cell class="missingFxAlignment10" *matHeaderCellDef mat-sort-header>
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'chargeable,asc' || currentSortOption === 'chargeable,desc'}"
                        >Charged</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'chargeable,asc',
                                    'desc': currentSortOption === 'chargeable,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment10" *matCellDef="let data">
                    <mat-checkbox color="primary" disabled="true" [checked]="data.chargeable"></mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- creationDate Column -->
            <ng-container matColumnDef="creationDate">
                <mat-header-cell class="missingFxAlignment10" *matHeaderCellDef mat-sort-header>
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'creationDate,asc' || currentSortOption === 'creationDate,desc'}"
                        >Date</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'creationDate,asc',
                                    'desc': currentSortOption === 'creationDate,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment10" *matCellDef="let data"> {{data.creationDate | date:'dd/MM/yyyy'}} </mat-cell>
            </ng-container>

            <!-- loggedTime Column -->
            <ng-container matColumnDef="loggedTime">
                <mat-header-cell class="missingFxAlignment10" *matHeaderCellDef mat-sort-header>
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'loggedTime,asc' || currentSortOption === 'loggedTime,desc'}"
                        >Hours</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'loggedTime,asc',
                                    'desc': currentSortOption === 'loggedTime,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment10" *matCellDef="let data"> {{data.loggedTime | minuteToHoursAndMinutes}} </mat-cell>
            </ng-container>

            <!-- exportDate Column -->
            <ng-container matColumnDef="exportDate">
                <mat-header-cell class="missingFxAlignment15" *matHeaderCellDef mat-sort-header>
                    <span [ngClass]="{ 'active-table-text': currentSortOption === 'exportDate,asc' || currentSortOption === 'exportDate,desc'}"
                        >Export date</span>
                    <i class="material-icons sort-icons" [ngClass]="{'asc': currentSortOption === 'exportDate,asc',
                                    'desc': currentSortOption === 'exportDate,desc'}">&#xE315;</i>
                </mat-header-cell>
                <mat-cell class="missingFxAlignment15" *matCellDef="let data"> {{data.exportDate | date:'dd/MM/yyyy'}} </mat-cell>
            </ng-container>

            <!-- duplicate activity Column -->
            <ng-container matColumnDef="duplicateActivity">
                <mat-header-cell class="missingFxAlignment5" *matHeaderCellDef> </mat-header-cell>
                <mat-cell class="missingFxAlignment5" *matCellDef="let data">
                    <i
                    [ngClass]="{ 'half-opacity': data.locked || currentProject?.project?.locked }"
                    [matTooltip]="data.locked === false || currentProject?.project?.locked === false ? 'Copy activity' :
                    data.locked || currentProject?.project?.locked ? 'Project is locked' : ''"
                    (click)="(data.locked === false || currentProject?.project?.locked === false) && onCopy(data.projectId, data.entryId)" (click)="$event.stopPropagation()" class="material-icons table-icons click-icon">file_copy</i>
                </mat-cell>
            </ng-container>

        <!-- duplicate activity Column -->
            <ng-container matColumnDef="duplicateActivityWithLessSpace">
                <mat-header-cell class="missingFxAlignment5" *matHeaderCellDef> </mat-header-cell>
                <mat-cell class="missingFxAlignment5" *matCellDef="let data">
                    <i
                    [ngClass]="{ 'half-opacity': data.locked || currentProject?.project?.locked }"
                    [matTooltip]="data.locked === false || currentProject?.project?.locked === false ? 'Copy activity' :
                    data.locked || currentProject?.project?.locked ? 'Project is locked' : ''"
                    (click)="(data.locked === false || currentProject?.project?.locked === false) && onCopy(data.projectId, data.entryId)" (click)="$event.stopPropagation()" class="material-icons table-icons click-icon">file_copy</i>
                </mat-cell>
            </ng-container>

             <!--  duplicateProject Column -->
             <ng-container matColumnDef="duplicateProject">
                <mat-header-cell class="missingFxAlignment7" *matHeaderCellDef> </mat-header-cell>
                <mat-cell class="missingFxAlignment7" *matCellDef="let data">
                    <span
                    [ngClass]="{'half-opacity': !data.chargeable || !data.costsAllocationText}"
                    [matTooltip]="
                    data.costsAllocationText ? 'Add WBS element' :
                    !data.chargeable ? 'Project is not chargeable' :
                    !data.costsAllocationText ? 'Project does not have a defined WBS Element' : ''"
                    class="change-src" (click)="data.costsAllocationText && onCopy(data.projectId)" (click)="$event.stopPropagation()">&nbsp;</span>
                </mat-cell>
            </ng-container>

            <!-- add Column -->
            <ng-container matColumnDef="add">
                <mat-header-cell class="missingFxAlignment5" *matHeaderCellDef> </mat-header-cell>
                <mat-cell class="missingFxAlignment5" *matCellDef="let data">
                    <mat-icon
                    [matTooltip]="!data.locked ? 'Create activity' : 'Project is locked'"
                    [ngClass]="{ 'half-opacity': data.locked || currentProject?.project?.locked }"
                    (click)="!data.locked && onAdd(data.projectId);$event.stopPropagation()"
                    class="material-icons table-icons click-icon">
                        add
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <!-- delete activity Column -->
            <ng-container matColumnDef="deleteActivity">
                <mat-header-cell class="missingFxAlignment5" *matHeaderCellDef> </mat-header-cell>
                <mat-cell class="missingFxAlignment5" *matCellDef="let data">
                    <i
                    [matTooltip]="'Delete activity'"
                    (click)="onDelete(data.projectId, data.entryId)" (click)="$event.stopPropagation()" class="material-icons table-icons click-icon delete-font">delete_outline</i>
                </mat-cell>
            </ng-container>

            <!-- costs center View Value Column -->
            <ng-container matColumnDef="costsCenterId">
                    <mat-header-cell class="missingFxAlignment50" *matHeaderCellDef>Costs Center ID</mat-header-cell>
                    <mat-cell *matCellDef="let data" class="d-flex justify-content-between align-items-center missingFxAlignment50">
                        {{data.costsCenterId}}
                    </mat-cell>
            </ng-container>

            <!-- costs center Value Column -->
            <ng-container matColumnDef="costsCenterLabel">
                    <mat-header-cell class="missingFxAlignment40" *matHeaderCellDef>
                        Costs Center Label
                        <i class="material-icons sort-icons desc">&#xE315;</i>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="d-flex justify-content-between align-items-center missingFxAlignment40">
                        {{data.costsCenterLabel}}
                    </mat-cell>
            </ng-container>

            <!-- costs center Chargeable Column -->
            <ng-container matColumnDef="costCenterChargeable">
              <mat-header-cell class="missingFxAlignment40" *matHeaderCellDef>
                Costs Center Chargeable
                <i class="material-icons sort-icons desc">&#xE315;</i>
              </mat-header-cell>
              <mat-cell *matCellDef="let data" class="d-flex justify-content-between align-items-center missingFxAlignment40">
                {{data.chargeable | booleanToText: 'Chargeable': 'Non-chargeable'}}
              </mat-cell>
            </ng-container>

            <!-- delete costs center Column -->
            <ng-container matColumnDef="deleteCostsCenter">
                    <mat-header-cell class="d-flex missingFxAlignment5" *matHeaderCellDef> </mat-header-cell>
                    <mat-cell class="d-flex missingFxAlignment5" *matCellDef="let data">
                        <i
                        [matTooltip]="'Delete Costs Center'"
                        (click)="onDelete(data.costsCenterId)" (click)="$event.stopPropagation()" class="material-icons table-icons click-icon delete-font">delete_outline</i>
                    </mat-cell>
            </ng-container>

            <!-- goTo Column -->
            <ng-container matColumnDef="goTo">
                <mat-header-cell *matHeaderCellDef class="d-flex missingFxAlignment2"> </mat-header-cell>
                <mat-cell *matCellDef="let data" class="d-flex missingFxAlignment2">
                    <i class="material-icons table-icons click-icon">keyboard_arrow_right</i>
                </mat-cell>
            </ng-container>


            <!-- space Column -->
            <ng-container matColumnDef="space">
                <mat-header-cell class="missingFxAlignment2"  *matHeaderCellDef> </mat-header-cell>
                <mat-cell class="missingFxAlignment2"  *matCellDef="let data"></mat-cell>
            </ng-container>

              <!-- goToActivity Column -->
              <ng-container matColumnDef="goToActivity">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <i
                    [matTooltip]="data.locked || currentProject?.project?.locked ? 'Project is locked' : ''"
                    [ngClass]="{ 'half-opacity': data.locked || currentProject?.project?.locked }"
                    class="material-icons table-icons click-icon">keyboard_arrow_right</i>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" appScrollToFixed [pixels]="fixedPixels" ></mat-header-row>
            <mat-row
            [matTooltip]="row.locked === false && row.chargeable && row.costsAllocationText === null ?
                         'WBS element is not defined. Activities will not be exported' : '' "
            class="mat-row-custom" (click)=" onGoToClick(row.projectId, row.entryId, row.locked, row.costsCenterId)" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <cx-heart-beat [loading]="loading  && (forceHide | async)"></cx-heart-beat>
    <div *ngIf="!data.length && !loading" fxLayout="row" fxLayoutAlign="center center">
        <span class="default-pointer margin-top-70" >{{noContentText}}</span>
    </div>
</div>
