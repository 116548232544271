import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {RouterModule} from '@angular/router';

import {CxHeartBeatModule} from "@bbraun/cortex/carousel";
import {CxFloatingButtonModule} from "@bbraun/cortex/floating-button";
import {CxFooterModule} from "@bbraun/cortex/footer";
import {CxHeaderModule} from "@bbraun/cortex/header";
import {CxSearchBarModule} from "@bbraun/cortex/search-bar";
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {MY_DATE_FORMATS} from '../../constants/date-format.constants';
import {ButtonComponent} from './components/button/button.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {FooterAdminComponent} from './components/footer/footer-admin/footer-admin.component';
import {FooterMemberComponent} from './components/footer/footer-member/footer-member.component';
import {HeaderComponent} from './components/header/header.component';
import {HeaderFactory} from './components/header/header-factory/header.factory';
import {NotAuthorizedComponent} from './components/not-authorization/not-authorization.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {TableComponent} from './components/table/table.component';
import {ScrollToFixedDirective} from './directives/scroll-to-fixed.directive';
import {TopMarginDirective} from './directives/top-margin.directive.directive';
import {HeaderInterceptor} from './interceptors/https.interceptor';
import {MaterialModule} from './modules/material/material.module';
import {MinutesToHoursPipe} from './pipes/minutesToHours.pipe';
import {MinuteSecondsPipe} from './pipes/minuteToHoursAndMinutes.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';
import {FavoriteService} from './services/favorite/favorite.service';
import {AppNavigateService} from './services/navigation/app-navigate.service';
import {SnackBarService} from './services/snack-bar/snack-bar.service';
import {UserService} from './services/user/user.service';
import {BooleanToTextPipe} from "./pipes/booleanToText.pipe";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        RouterModule,
        CxHeartBeatModule,
        CxFooterModule,
        CxHeaderModule,
        CxSearchBarModule,
        MatIconModule,
        CxFloatingButtonModule
    ],

    declarations: [
        HeaderComponent,
        FooterMemberComponent,
        FooterAdminComponent,
        ConfirmDialogComponent,
        TableComponent,
        ButtonComponent,
        MinuteSecondsPipe,
        MinutesToHoursPipe,
        TruncatePipe,
        BooleanToTextPipe,
        NotFoundComponent,
        NotAuthorizedComponent,
        ScrollToFixedDirective,
        TopMarginDirective
    ],

    providers: [
        SnackBarService,
        AppNavigateService,
        HeaderFactory,
        UserService,
        FavoriteService,
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        }
    ],

    exports: [
        HeaderComponent,
        FooterMemberComponent,
        FooterAdminComponent,
        TableComponent,
        ButtonComponent,
        NotAuthorizedComponent,
        NotFoundComponent,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        RouterModule,
        MinuteSecondsPipe,
        MinutesToHoursPipe,
        BooleanToTextPipe,
        TruncatePipe,
        ScrollToFixedDirective,
        TopMarginDirective
    ]
})
export class SharedModule {
    constructor(private dateAdapter: DateAdapter<Date>) {
        this.dateAdapter.setLocale('en-gb'); // DD/MM/YYYY
    }
}
