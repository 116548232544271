<cx-footer>
    <!-- home -->
    <button
         (click)="onClickButton(routeModels.home.home)"
         mat-button>
         <i class="material-icons">home</i>
         <span class="display-none" >
             Home
         </span>
     </button>

    <!-- admin projects -->
    <button
        (click)="onClickButton(routeModels.projects.home)"
        mat-button
        routerLink="/admin-projects/projects"
        routerLinkActive="active" >
        <i class="material-icons">assignment</i>
        <span class="display-none">
            {{messages.projects}}
        </span>
    </button>

    <button
        (click)="onClickButton(routeModels.projects.export)"
        routerLink="/admin-projects/export-admin/export"
        routerLinkActive="active"
        mat-button>
        <i class="material-icons">import_export</i>
        <span>
            Export
        </span>
    </button>

    <button
        (click)="onClickButton(routeModels.projects.uploadLogs)"
        routerLink="/admin-projects/export-admin/upload-logs"
        routerLinkActive="active"
        mat-button>
        <i class="material-icons">attachment</i>
        <span>
            Upload Logs
        </span>
    </button>

    <!-- admin costs Centers -->
    <button
        mat-button
        routerLink="/admin-projects/costs-centers"
        routerLinkActive="active"
        (click)="onClickButton(routeModels.projects.costsCenters)"
        [ngClass]="{ 'active': currentURL === routeModels.projects.costsCenters}">
        <i class="material-icons">description</i>
        <span>
            Costs Centers
        </span>
    </button>

    <!-- admin profile -->
    <button
        mat-button
        routerLink="/admin-projects/profile"
        routerLinkActive="active"
        (click)="onClickButton(routeModels.projects.profile)" >
        <i class="material-icons">perm_identity</i>
        <span>
            Profile
        </span>
    </button>
</cx-footer>
