import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { messages } from '../../modules/shared/constants/mesages.constants';
import * as tableParams from '../../modules/shared/constants/parameters.for.calls.constants';
import * as fromApp from '../../store';
import { ForceHide, SetTableLoading } from '../actions/layout.actions';
import { MemberService } from './../../modules/member/services/member.service';
import { SnackBarService } from './../../modules/shared/services/snack-bar/snack-bar.service';
import * as memberNotExportedActions from './../actions/member-not-exported.actions';
import { LoadNotExportedMember } from './../actions/member-not-exported.actions';

@Injectable()
export class MemberNotExportedEffects {

  constructor(
    private actions$: Actions,
    private memberService: MemberService,
    private store: Store<fromApp.AppState>,
    private snackBarService: SnackBarService
  ) { }

  loadMemberNotExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(
      memberNotExportedActions.LoadNotExportedMember,
      memberNotExportedActions.ScrollMemberActivitiesNotExported,
      memberNotExportedActions.SortMemberActivitiesNotExported
    ),
    switchMap((action) => {
      return this.memberService
        .getMemberActivitiesForProjectTableData(
          action.projectId,
          action.page,
          action.size,
          action.sort,
          null,
          false,
          action.startDate,
          action.endDate)
        .pipe(
          map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
            this.store.dispatch(SetTableLoading({loading: false}));
            return memberNotExportedActions.LoadNotExportedMemberDataSuccessAction({
              notExportedMemberTableItems: data
            });
          }),
          catchError((err, caught) => {
            return EMPTY;
          })
        );
    }))
  );


  deleteActivitiy$ = createEffect(() => this.actions$.pipe(
    ofType(memberNotExportedActions.DeleteEntryMemberAction),
    switchMap((action) => {
      return this.memberService
        .removeEntry(action.projectId, action.entryId)
        .pipe(
          map(_ => {
            this.store.dispatch(ForceHide({forceHide: false}));
            this.store.dispatch(SetTableLoading({loading: false}));
            this.snackBarService.openSimpleSnackBar(`${messages.activity} deleted`, 2000);
            return LoadNotExportedMember({
              projectId: action.projectId,
              page: tableParams.parametersForNotExported.page,
              size: tableParams.parametersForNotExported.size,
              sort: action.sort,
              booked: tableParams.parametersForNotExported.booked,
              startDate: action.startDate,
              endDate: action.endDate
          });
          }),
          catchError((err, caught) => {
            return EMPTY;
          })
        );
    })
   ));
}
