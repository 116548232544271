import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MemberProjectsComponent } from './member-projects.component';

const routes: Routes = [
  { path: '', component: MemberProjectsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberProjectsRoutingModule { }
