<div class="container">
        <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">
                <div class="top-margin-form d-flex justify-conten-around align-content-start">

                <div class="full-width d-flex flex-column justify-content-between align-content-start">
                    <mat-form-field class="width-90">
                        <mat-label>Username</mat-label>
                        <input class="grey" matInput formControlName="user" placeholder="USERNAME">
                    </mat-form-field>
                </div>

                <div class="full-width d-flex flex-column">
                    <mat-form-field class="width-90">
                            <mat-label>My Costs Center</mat-label>
                            <input matInput class="grey" placeholder="{{ 'MY ' + (messages.costsCenter | uppercase)}}"
                            formControlName="costCenter" [matAutocomplete]="auto" (focus)="onFocusInput()"  (input)="inputTermChanged.next($event.target.value)"
                            autocomplete="off">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option
                                *ngFor="let option of options"
                                [value]="option.costsCenterLabel">
                                    <div class="d-flex justify-content-between align-content-center">
                                            <span class="grey">{{option.costsCenterLabel}}</span>
                                            <span class="grey">{{option.costsCenterId}}</span>
                                    </div>
                                </mat-option>
                                <mat-option *ngIf="options.length === 0" disabled>
                                        No results
                                </mat-option>
                            </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="!mainForm.controls['costCenter'].value && currentURL === profileURL">
                    <span>Your activities will not be exported without <strong>{{messages.costsCenter}}</strong></span>
                    </mat-error>

                    <div class="mat-checkbox-margin-top" >
                        <mat-checkbox formControlName="chargeable" color="primary">
                            Chargeable activities by default
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <app-button [icon]="'done'" [submit]="true" type="submit"></app-button>

        </form>
        <app-footer-member
        *ngIf="!isAdmin"></app-footer-member>
        <app-footer-admin
        *ngIf="isAdmin"></app-footer-admin>
    </div>
