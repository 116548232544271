import { createAction, props } from '@ngrx/store';

import { IMemberDataContentSets } from './../../modules/shared/interfaces/member/member-set-paging.interface';

export const LOAD_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] LOAD_MEMBER_PROJECTS_ACTIVE_DATA';
export const SCROLL_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] SCROLL_MEMBER_PROJECTS_ACTIVE_DATA';
export const LOAD_MEMBER_PROJECTS_ACTIVE_DATA_SUCCESS = '[MEMBER-PROJECTS] LOAD_MEMBER_PROJECTS_ACTIVE_DATA_SUCCESS';
export const DELETE_FAVORITE_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] DELETE_FAVORITE_MEMBER_PROJECTS_ACTIVE_DATA';
export const CREATE_FAVORITE_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] CREATE_FAVORITE_MEMBER_PROJECTS_ACTIVE_DATA';
export const SEARCH_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] SEARCH_MEMBER_PROJECTS_ACTIVE_DATA';
export const SEARCH_SCROLL_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] SEARCH_SCROLL_MEMBER_PROJECTS_ACTIVE_DATA';
export const SORT_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] SORT_MEMBER_PROJECTS_ACTIVE_DATA';
export const SEARCH_SORT_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] SEARCH_SORT_MEMBER_PROJECTS_ACTIVE_DATA';
export const CLEAR_MEMBER_PROJECTS_ACTIVE_DATA = '[MEMBER-PROJECTS] CLEAR_MEMBER_PROJECTS_ACTIVE_DATA';


export const LoadMemberProjectsActive = createAction(
  LOAD_MEMBER_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SortMemberProjectsActive = createAction(
  SORT_MEMBER_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const LoadMemberProjectsActiveDataSuccessAction = createAction(
  LOAD_MEMBER_PROJECTS_ACTIVE_DATA_SUCCESS,
  props<{
    memberProjectActiveTableItems: IMemberDataContentSets
  }>()
);

export const ScrollMemberProjectsActive = createAction(
  SCROLL_MEMBER_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string
  }>()
);

export const SearchSortMemberProjectsActive = createAction(
  SEARCH_SORT_MEMBER_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchMemberProjectsActive = createAction(
  SEARCH_MEMBER_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const SearchScrollMemberProjectsActive = createAction(
  SEARCH_SCROLL_MEMBER_PROJECTS_ACTIVE_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    search: string
  }>()
);

export const ClearMemberProjectsActive = createAction(
  CLEAR_MEMBER_PROJECTS_ACTIVE_DATA
);
