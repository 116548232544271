import { ActionReducerMap, createSelector } from '@ngrx/store';

import { HeaderOptionsModel } from '../modules/shared/components/header/header-factory/header.model';
import * as fromActivitiesExported from './reducers/activities-exported.reducer';
import * as fromActivitiesNotExported from './reducers/activities-not-exported.reducer';
import * as fromAdminActiveProjects from './reducers/admin-projects-active.reducer';
import * as fromAdminFavoriteProjects from './reducers/admin-projects-favorite.reducer';
import * as fromAdminLockedProjects from './reducers/admin-projects-locked.reducer';
import * as fromHeaderOptions from './reducers/header-options.reducer';
import { ILayoutState } from './reducers/layout.reducer';
import * as fromLayout from './reducers/layout.reducer';
import * as fromMemberExported from './reducers/member-exported.reducer';
import * as fromMemberNotExported from './reducers/member-not-exported.reducer';
import * as fromMemberActiveProjects from './reducers/member-projects-active.reducer';
import * as fromMemberFavoriteProjects from './reducers/member-projects-favorite.reducer';
import * as fromMemberLockedProjects from './reducers/member-projects-locked.reducer';
import * as fromTimeTracker from './reducers/time-tracker.reducer';
import * as fromUserData from './reducers/user.reducer';


export interface AppState {
  timeTracker: fromTimeTracker.ITimeTrackerState;
  projectsActiveAdminTableData: fromAdminActiveProjects.AdminPojectsActiveState;
  projectsFavoriteAdminTableData: fromAdminFavoriteProjects.AdminPojectsFavoriteState;
  projectsLockedAdminTableData: fromAdminLockedProjects.AdminPojectsLockedState;
  layout: ILayoutState;
  activitiesExported: fromActivitiesExported.ActivitiesExportedState;
  activitiesNotExported: fromActivitiesNotExported.ActivitiesNotExportedState;
  userData: fromUserData.UserState;
  headerOptions: HeaderOptionsModel;
  projectsActiveMemberTableData: fromMemberActiveProjects.MemberPojectsActiveState;
  projectsLockedMemberTableData: fromMemberLockedProjects.MemberPojectsLockedState;
  projectsFavoriteMemberTableData: fromMemberFavoriteProjects.MemberPojectsFavoriteState;
  memberExported: fromMemberExported.MemberExportedState;
  memberNotExported: fromMemberNotExported.MemberNotExportedState;
}

export const reducers: ActionReducerMap<AppState> = {
  timeTracker: fromTimeTracker.timeTrackerReducer,
  projectsActiveAdminTableData: fromAdminActiveProjects.adminProjectsActiveDataReducer,
  projectsFavoriteAdminTableData: fromAdminFavoriteProjects.adminProjectsFavoriteDataReducer,
  projectsLockedAdminTableData: fromAdminLockedProjects.adminProjectsLockedDataReducer,
  layout: fromLayout.LayoutsReducer,
  activitiesExported: fromActivitiesExported.activitiesExportedDataReducer,
  activitiesNotExported: fromActivitiesNotExported.activitiesNotExportedMemberDataReducer,
  userData: fromUserData.userDataReducer,
  headerOptions: fromHeaderOptions.headerOptionsReducer,
  projectsActiveMemberTableData: fromMemberActiveProjects.memberProjectsActiveDataReducer,
  projectsLockedMemberTableData: fromMemberLockedProjects.memberProjectsLockedDataReducer,
  projectsFavoriteMemberTableData: fromMemberFavoriteProjects.memberProjectsFavoriteDataReducer,
  memberExported: fromMemberExported.exportedMemberDataReducer,
  memberNotExported: fromMemberNotExported.notExportedMemberDataReducer
};

const getState = state => state;

// State parent
export const getParentTime = createSelector(getState, (state: AppState) => state.timeTracker);
export const getParentAdminProjectsActiveTableData =
  createSelector(getState, (state: AppState) => state.projectsActiveAdminTableData);
export const getParentAdminProjectsLockedTableData =
  createSelector(getState, (state: AppState) => state.projectsLockedAdminTableData);
export const getParentAdminProjectsFavoriteTableData =
  createSelector(getState, (state: AppState) => state.projectsFavoriteAdminTableData);
export const getParentMemberProjectsActiveTableData =
  createSelector(getState, (state: AppState) => state.projectsActiveMemberTableData);
export const getParentMemberProjectsLockedTableData =
  createSelector(getState, (state: AppState) => state.projectsLockedMemberTableData);
export const getParentMemberProjectsFavoriteTableData =
  createSelector(getState, (state: AppState) => state.projectsFavoriteMemberTableData);

export const getParentLayout =
  createSelector(getState, (state: AppState) => state.layout);
export const getParentActivitiesExported =
  createSelector(getState, (state: AppState) => state.activitiesExported);
export const getParentActivitiesNotExported =
  createSelector(getState, (state: AppState) => state.activitiesNotExported);
export const getParentUserData =
  createSelector(getState, (state: AppState) => state.userData);
export const getParentHeaderOptions =
  createSelector(getState, (state: AppState) => state.headerOptions);
export const getParentMemberExported =
  createSelector(getState, (state: AppState) => state.memberExported);
export const getParentMemeberNotExported =
  createSelector(getState, (state: AppState) => state.memberNotExported);


// Get state


// time-tracker
export const getTimeData = createSelector(getParentTime, fromTimeTracker.getTime);

// admin-projects projects
export const getAdminProjectsActiveTableData =
  createSelector(getParentAdminProjectsActiveTableData, fromAdminActiveProjects.getAdminTableProjectsActive);
export const getAdminProjectsLockedTableData =
  createSelector(getParentAdminProjectsLockedTableData, fromAdminLockedProjects.getAdminTableProjectsLocked);
export const getAdminProjectsFavoriteTableData =
  createSelector(getParentAdminProjectsFavoriteTableData, fromAdminFavoriteProjects.getAdminTableProjectsFavorite);

// admin-projects sort
export const getAdminProjectsActiveSortSearchData =
  createSelector(getParentAdminProjectsActiveTableData, fromAdminActiveProjects.getAdminTableSortSearchParamActive);
export const getAdminProjectsLockedSortSearchData =
  createSelector(getParentAdminProjectsLockedTableData, fromAdminLockedProjects.getAdminTableSortSearchParamLocked);
export const getAdminProjectsFavoriteSortSearchData =
  createSelector(getParentAdminProjectsFavoriteTableData,
    fromAdminFavoriteProjects.getAdminTableSortSearchParamFavorite);


// member-projects
export const getMemberProjectsActiveTableData =
  createSelector(getParentMemberProjectsActiveTableData, fromMemberActiveProjects.getMemberTableProjectsActive);
  export const getMemberProjectsLockedTableData =
  createSelector(getParentMemberProjectsLockedTableData, fromMemberLockedProjects.getMemberTableProjectsLocked);
export const getMemberProjectsFavoriteTableData =
  createSelector(getParentMemberProjectsFavoriteTableData, fromMemberFavoriteProjects.getMemberTableProjectsFavorite);

// member-projects sort
export const getMemberProjectsActiveSortSearchData =
  createSelector(getParentMemberProjectsActiveTableData, fromMemberActiveProjects.getMemberTableSortSearchParamActive);
export const getMemberProjectsFavoriteSortSearchData =
  createSelector(getParentMemberProjectsFavoriteTableData,
    fromMemberFavoriteProjects.getMemberTableSortSearchParamFavorite);
export const getMemberProjectsLockedSortSearchData =
  createSelector(getParentMemberProjectsLockedTableData, fromMemberLockedProjects.getMemberTableSortSearchParamLocked);


// layout
export const getLayoutUnSavedChanges = createSelector(getParentLayout, fromLayout.getUnsavedChanges);
export const getLayoutSelectedTab = createSelector(getParentLayout, fromLayout.getTab);
export const getLayoutActivitiesSelectedTab = createSelector(getParentLayout, fromLayout.getActivitiesTab);
export const getLayoutLoading = createSelector(getParentLayout, fromLayout.getLoading);
export const getLayoutTableLoading = createSelector(getParentLayout, fromLayout.getTableLoading);
export const getLayoutDisableButton = createSelector(getParentLayout, fromLayout.getDisableButton);
export const getLayoutforceHide = createSelector(getParentLayout, fromLayout.getForceHide);
export const getLayoutActivitiesDateFilter = createSelector(getParentLayout, fromLayout.getActivitiesDateFilter);
export const getLayoutPreviousRoute = createSelector(getParentLayout, fromLayout.getPreviousRoute);


// activities
export const getActivitiesExported =
  createSelector(getParentActivitiesExported, fromActivitiesExported.getActivitiesExported);
export const getActivitiesNotExported =
  createSelector(getParentActivitiesNotExported, fromActivitiesNotExported.getActivitiesNotExported);
export const getActivitiesSortSearchNotExported =
     createSelector(getParentActivitiesNotExported, fromActivitiesNotExported.getActivitiesSortSearchParamNotExported);
export const getActivitiesSortSearchExported =
     createSelector(getParentActivitiesExported, fromActivitiesExported.getActivitiesSortSearchParamExported);

// member activities
export const getMemberExportedTableData =
  createSelector(getParentMemberExported, fromMemberExported.getMemberExported);
export const getMemberNotExportedTableData =
  createSelector(getParentMemeberNotExported, fromMemberNotExported.getMemberNotExported);
export const getMemberActivitiesTab =
  createSelector(getParentMemeberNotExported, fromMemberNotExported.getMemberTab);

// user
export const getUserData = createSelector(getParentUserData, fromUserData.getUserData);


// header options
export const getHeaderOptions = createSelector(getParentHeaderOptions, fromHeaderOptions.getHeaderOptions);
