export const parametersForAdminActiveTable = {
    page: 0,
    size: 40,
    sort: 'lastExported,desc'
};

export const parametersForAdminLockedTable = {
    page: 0,
    size: 40,
    sort: 'lastExported,desc'
};

export const parametersForAdminFavoriteTable = {
    page: 0,
    size: 40,
    sort: 'lastExported,desc',
    favorite: true
};

export const parametersForExported = {
    page: 0,
    size: 40,
    sort: 'creationDate,desc',
    nullFavorite: null,
    booked: true
};

export const parametersForNotExported = {
    page: 0,
    size: 40,
    sort: 'creationDate,desc',
    nullFavorite: null,
    booked: false
};

export const parametersForMemberFavoriteProjects = {
    page: 0,
    size: 40,
    sort: 'lastEntry,desc',
    favorite: true
};

export const parametersForMemberActiveProjects = {
    page: 0,
    size: 40,
    sort: 'lastEntry,desc'
};

export const parametersForMemberLockedTable = {
    page: 0,
    size: 40,
    sort: 'lastEntry,desc'
};

