import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CostsCentersMaintenanceComponent } from './costs-centers-maintenance.component';
import { CostsCentersMaintenanceRoutingModule } from './costs-centers-maintenance.routing.module';
import { CreateEditCostsCenterComponent } from './create-edit-costs-center/create-edit-costs-center.component';
import { CostsCenterService } from './services/costs-center.service';
import {CxFloatingButtonModule} from "@bbraun/cortex/floating-button";

@NgModule({
  declarations: [CostsCentersMaintenanceComponent, CreateEditCostsCenterComponent],
    imports: [
        CommonModule,
        CostsCentersMaintenanceRoutingModule,
        SharedModule,
        CxFloatingButtonModule
    ],
  providers: [
    CostsCenterService
  ]
})
export class CostsCentersMaintenanceModule { }
