import { FooterOptionsModel } from './footer.model';

export class FooterFactory {
  public createFooterWithOptions(
    showFooter: boolean,
    projectsAdminShow?: boolean,
    projectsMemberShow?: boolean,
    exportShow?: boolean,
    activityLogsShow?: boolean,
    profileShowCostsAllocationIcon?: string
    ): FooterOptionsModel {

    return new FooterOptionsModel(
      showFooter,
      projectsAdminShow,
      projectsMemberShow,
      exportShow,
      activityLogsShow,
      profileShowCostsAllocationIcon
    );
  }
}
