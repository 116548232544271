import { IFooter } from './footer.interface';

export class FooterOptionsModel implements IFooter {
    constructor(
        public showFooter: boolean,
        public projectsAdminShow: boolean,
        public projectsMemberShow: boolean,
        public exportShow: boolean,
        public activityLogsShow: boolean,
        public profileShowCostsAllocationIcon: string
        ) {
    }
}
