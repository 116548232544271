import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IMemberDataContentSets } from '../../modules/shared/interfaces/member/member-set-paging.interface';
import * as fromApp from '../../store';
import { ForceHide, SetTableLoading } from '../actions/layout.actions';
import { MemberService } from './../../modules/member/services/member.service';
import * as activitiesExportedActions from './../actions/activities-exported.actions';

@Injectable()
export class ActivitiesExportedEffects {

  constructor(
    private actions$: Actions,
    private memberService: MemberService,
    private store: Store<fromApp.AppState>
    ) { }

    loadActivitiesExportedTable$ = createEffect(() => this.actions$.pipe(
      ofType(activitiesExportedActions.LoadActivitiesExported),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberService.getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          true,
          action.startDate,
          action.endDate).pipe(
            map((data: IMemberDataContentSets) => {
              this.store.dispatch(SetTableLoading({loading: false}));
              this.store.dispatch(ForceHide({forceHide: false}));
              return activitiesExportedActions.LoadActivitiesExportedDataSuccessAction({ exportedTableItems: data });
            }),
          catchError((error: Error) => {
            return EMPTY;
          }));
            }))
        );

    scrollActivitiesExportedTable$ = createEffect(() => this.actions$.pipe(
      ofType(activitiesExportedActions.ScrollActivitiesExported),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberService.getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          true,
          action.startDate,
          action.endDate)
          .pipe(
            map((data: IMemberDataContentSets) => {
              this.store.dispatch(SetTableLoading({loading: false}));
              return activitiesExportedActions.LoadActivitiesExportedDataSuccessAction({ exportedTableItems: data });
            }),
            catchError((error: Error) => {
              return EMPTY;
            })
          );
      }))
    );

    sortActivitiesExportedTable$ = createEffect(() => this.actions$.pipe(
      ofType(activitiesExportedActions.SortActivitiesExported),
      switchMap((action) => {
        return this.memberService.getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          true,
          action.startDate,
          action.endDate)
          .pipe(
            map((data: IMemberDataContentSets) => {
              return activitiesExportedActions.LoadActivitiesExportedDataSuccessAction({ exportedTableItems: data });
            }),
            catchError((error: Error) => {
              return EMPTY;
            })
          );
      }))
    );

    searchActivitiesExportedTable$ = createEffect(() => this.actions$.pipe(
      ofType(activitiesExportedActions.SearchActivitiesExported),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        this.store.dispatch(ForceHide({forceHide: true}));
        return this.memberService.getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          true,
          action.startDate,
          action.endDate,
          action.search)
          .pipe(
            map((data: IMemberDataContentSets) => {
              this.store.dispatch(SetTableLoading({loading: false}));
              this.store.dispatch(ForceHide({forceHide: false}));
              return activitiesExportedActions.LoadActivitiesExportedDataSuccessAction({ exportedTableItems: data });
            }),
            catchError((error: Error) => {
              return EMPTY;
            })
          );
      }))
    );

    searchSortActivitiesExportedTable$ = createEffect(() => this.actions$.pipe(
      ofType(activitiesExportedActions.SearchSortActivitiesExported),
      switchMap((action) => {
        return this.memberService.getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          true,
          action.startDate,
          action.endDate,
          action.search)
          .pipe(
            map((data: IMemberDataContentSets) => {
              return activitiesExportedActions.LoadActivitiesExportedDataSuccessAction({ exportedTableItems: data });
            }),
            catchError((error: Error) => {
              return EMPTY;
            })
          );
      }))
    );

    searchScrollActivitiesExportedTable$ = createEffect(() => this.actions$.pipe(
      ofType(activitiesExportedActions.SearchScrollActivitiesExported),
      switchMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
        return this.memberService.getMemberActivitiesTableData(
          action.page,
          action.size,
          action.sort,
          null,
          true,
          action.startDate,
          action.endDate,
          action.search)
          .pipe(
            map((data: IMemberDataContentSets) => {
              this.store.dispatch(SetTableLoading({loading: false}));
              return activitiesExportedActions.LoadActivitiesExportedDataSuccessAction({ exportedTableItems: data });
            }),
            catchError((error: Error) => {
              return EMPTY;
            })
          );
      }))
    );
}
