import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import * as fromApp from '../../store';
import { ForceHide, SetTableLoading } from '../actions/layout.actions';
import { AdminProjectsService } from './../../modules/admin-of-project/services/admin-projects.service';
import * as AdminProjectsLockedActions from './../actions/admin-projects-locked.actions';


@Injectable()
export class AdminProjectsLockedEffects {

  constructor(
    private actions$: Actions,
    private adminProjectsService: AdminProjectsService,
    private store: Store<fromApp.AppState>
  ) { }

    loadAdminProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsLockedActions.LoadAdminProjectsLocked
      ),
      flatMap((action) => {
          this.store.dispatch(ForceHide({forceHide: true}));
          this.store.dispatch(SetTableLoading({loading: true}));
          return this.adminProjectsService
        .getAdminTableData(action.page, action.size, action.sort, null, true)
        .pipe(
          map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
            this.store.dispatch(SetTableLoading({loading: false}));
          return AdminProjectsLockedActions.LoadAdminProjectsLockedDataSuccessAction({
            adminProjectLockedTableItems: data
          });
          }),
          catchError((err, caught) => {
             return EMPTY;
           })
        );
      }))
    );

    sortAdminProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsLockedActions.SortAdminProjectsLocked
      ),
      flatMap((action) => {
          return this.adminProjectsService
        .getAdminTableData(action.page, action.size, action.sort, null, true)
        .pipe(
          map(data => {
          return AdminProjectsLockedActions.LoadAdminProjectsLockedDataSuccessAction({
            adminProjectLockedTableItems: data
          });
          }),
          catchError((err, caught) => {
             return EMPTY;
           })
        );
      }))
    );

    scrollAdminProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsLockedActions.ScrollAdminProjectsLocked
      ),
      flatMap((action) => {
          this.store.dispatch(SetTableLoading({loading: true}));
          return this.adminProjectsService
        .getAdminTableData(action.page, action.size, action.sort, null, true)
        .pipe(
          map(data => {
            this.store.dispatch(SetTableLoading({loading: false}));
            return AdminProjectsLockedActions.LoadAdminProjectsLockedDataSuccessAction({
              adminProjectLockedTableItems: data
            });
          }),
          catchError((err, caught) => {
             return EMPTY;
           })
        );
      }))
    );

    searchAdminProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsLockedActions.SearchAdminProjectsLocked
      ),
      flatMap((action) => {
        this.store.dispatch(SetTableLoading({loading: true}));
          this.store.dispatch(ForceHide({forceHide: true}));
        return this.adminProjectsService
          .searchProjects(action.page, action.size, action.sort, null, true, action.search)
          .pipe(
            map(data => {
            this.store.dispatch(ForceHide({forceHide: false}));
            this.store.dispatch(SetTableLoading({loading: false}));
              return AdminProjectsLockedActions.LoadAdminProjectsLockedDataSuccessAction({
                adminProjectLockedTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchSortAdminProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsLockedActions.SearchSortAdminProjectsLocked
      ),
      flatMap((action) => {
        return this.adminProjectsService
          .searchProjects(action.page, action.size, action.sort, null, true, action.search)
          .pipe(
            map(data => {
              return AdminProjectsLockedActions.LoadAdminProjectsLockedDataSuccessAction({
                adminProjectLockedTableItems: data
              });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );

    searchScrollAdminProjectLockedTable$ = createEffect(() => this.actions$.pipe(
      ofType(
        AdminProjectsLockedActions.SearchScrollAdminProjectsLocked
      ),
      flatMap((action) => {
          this.store.dispatch(SetTableLoading({loading: true}));
          return this.adminProjectsService
          .searchProjects(action.page, action.size, action.sort, null, true, action.search)
          .pipe(
            map(data => {
            this.store.dispatch(SetTableLoading({loading: false}));
            return AdminProjectsLockedActions.LoadAdminProjectsLockedDataSuccessAction({
              adminProjectLockedTableItems: data
            });
            }),
            catchError((err, caught) => {
               return EMPTY;
             })
          );
      }))
    );
}
