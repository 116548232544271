import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromApp from '../../store';
import { ForceHide, SetTableLoading } from '../actions/layout.actions';
import { MemberService } from './../../modules/member/services/member.service';
import * as memberExportedActions from './../actions/member-exported.actions';

@Injectable()
export class MemberExportedEffects {

  constructor(
    private actions$: Actions,
    private memberService: MemberService,
    private store: Store<fromApp.AppState>
  ) { }

  loadMemberExportedTable$ = createEffect(() => this.actions$.pipe(
    ofType(
      memberExportedActions.LoadExportedMember,
      memberExportedActions.SortMemberActivitiesExported,
      memberExportedActions.ScrollMemberActivitiesExported
    ),
    switchMap((action) => {
      return this.memberService
      .getMemberActivitiesForProjectTableData(
        action.projectId,
        action.page,
        action.size,
        action.sort,
        null,
        true,
        action.startDate,
        action.endDate)
      .pipe(
        map(data => {
          this.store.dispatch(ForceHide({forceHide: false}));
          this.store.dispatch(SetTableLoading({loading: false}));
          return memberExportedActions.LoadExportedMemberDataSuccessAction({exportedMemberTableItems: data});
        }),
        catchError((err, caught) => {
           return EMPTY;
         })
      );
    })
  ));

}
