import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExportComponent } from './export/export.component';
import { LastExportedResolver } from './resolvers/lastExported.resolver';
import { UploadLogsComponent } from './upload-logs/upload-logs.component';


const routes: Routes = [
  { path: 'export', component: ExportComponent, resolve: { lastExported: LastExportedResolver } },
  { path: 'upload-logs', component: UploadLogsComponent },
  { path: '', pathMatch: 'full', redirectTo: 'export' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminExportRoutingModule { }
