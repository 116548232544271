import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SetHeaderWithOnlyTitle } from '../../store/actions/header-options.action';
import { SetUnsavedChangesAction } from '../../store/actions/layout.actions';
import { SetUserActive } from '../../store/actions/user.actions';
import { messages } from '../shared/constants/mesages.constants';
import { ICostsCenter } from '../shared/interfaces/ICostsCenter';
import { IUser } from '../shared/interfaces/user/user.interface';
import * as routeConstants from '../shared/models/router.constants';
import { SnackBarService } from '../shared/services/snack-bar/snack-bar.service';
import { UserService } from '../shared/services/user/user.service';
import * as fromApp from './../../store';
  import { IUserFormBody } from './../shared/interfaces/user/userFormBody.interface';
import { profile } from './../shared/models/router.constants';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<fromApp.AppState>,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private router: Router
  ) { }

  private optionsModel: Array<ICostsCenter>;
  public options: Array<ICostsCenter>;
  public mainForm: UntypedFormGroup;
  public messages = messages;

  public currentURL: string;
  public profileURL = profile.home;
  public routeConstants = routeConstants;
  public mainFormObject: IUserFormBody;

  public inputTermChanged = new Subject<string>();

  private onDestroy$ = new Subject<void>();

  public userInfoData: IUser;

  public isAdmin: boolean;


  ngOnInit() {
    this.store.dispatch(SetHeaderWithOnlyTitle({ title: messages.profile }));

    this.currentURL = this.router.url;
    this.isAdmin = this.currentURL.includes('admin-projects');

    this.store.select(fromApp.getUserData)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((data: IUser) => {
      if (data.user) {
        this.userInfoData = data;
        this.optionsModel = data.costsCenters.map(x => x);
        // add an empty costs center in case of user mistake
        this.optionsModel.unshift({costsCenterId: '', costsCenterLabel: ''});
        this.options = this.optionsModel.map(x => x);

        this.buildForm();

        this.mainForm.valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
            this.areUnsavedChanges(true);
            this.mainFormObject = res;
        });
        this.subscribeToOnTermChange();
      }
    });
  }

  ngOnDestroy() {
    this.areUnsavedChanges(false);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


  public onSubmit() {
    if (this.mainForm.valid) {
      const sendObj: IUserFormBody = this.mainForm.value;
      const costsCenterObjects = this.optionsModel.find(x => x.costsCenterLabel === sendObj.costCenter);
      if (!costsCenterObjects) {
        this.snackBarService.openSimpleSnackBar('Please select your Costs Center from the dropdown', 3000);
        return;
      }
      sendObj.costCenter = sendObj.costCenter ? costsCenterObjects.costsCenterId : null;

      // logic for always chargeable activities
      sendObj.chargeable = this.userInfoData.user.chargeable;

      this.userService.updateUserData(sendObj)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.snackBarService.openSimpleSnackBar('Profile updated', 2000);
          this.mainForm.markAsPristine();
          this.areUnsavedChanges(false);
          this.store.dispatch(new SetUserActive());
      }, err => {
        this.snackBarService.openSimpleSnackBar('Contact your administrator', 2000);
      }
    );
    }
  }

  private buildForm() {
    const searchedViewValue = this.optionsModel.find(x => x.costsCenterId === this.userInfoData.user.costCenter);
    const viewValue = searchedViewValue ? searchedViewValue.costsCenterLabel : '';
    this.mainForm = this.fb.group(
      {
        user: {value: this.userInfoData.user.username, disabled: true},
        costCenter: viewValue,
        // logic for always chargeable activities
        chargeable: {value: this.userInfoData.user.chargeable, disabled: true}
      }
    );

    this.mainFormObject = {
      costCenter: this.userInfoData.user.costCenter,
      chargeable: this.userInfoData.user.chargeable
    };
  }

  public onFocusInput() {
    this.options = this.optionsModel.map(x => x);
  }

  private subscribeToOnTermChange() {
    this.inputTermChanged
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((inputTerm: string) => {
        this.options = this.optionsModel.filter(x =>
          x.costsCenterLabel.toLocaleLowerCase().includes(inputTerm.toLocaleLowerCase()) ||
          x.costsCenterId.toLocaleLowerCase().includes(inputTerm.toLocaleLowerCase()));
      });
  }

  private areUnsavedChanges(value: boolean): void {
    this.store.dispatch(SetUnsavedChangesAction({areUnsavedChanges: value}));
  }

}
