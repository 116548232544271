<div class="container">
    <div class="filter-date">
            <mat-select class="data-select" (selectionChange)="onSelect($event)" [value]="selectedOpiton" disableOptionCentering>
                    <mat-select-trigger >
                        <div class="material-grey d-flex justify-content-end">
                                {{selectedOpiton}} &nbsp;
                                <i class="material-icons">list</i>
                        </div>
                    </mat-select-trigger>
                    <mat-option *ngFor="let value of optionsArr" [value]="value" class="data-select-option">{{value}}</mat-option>
                </mat-select>
        </div>
    <mat-tab-group class="tab-content" [selectedIndex]="currentTab" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Not Exported {{ !currentIsSearching ?  '(' + (timeDestribution?.openedTime | minutesToHours) + ')' : ''}}">
            <app-not-exported
            *ngIf="currentTab === enums.notExported"
            [data]="data"
            [currentSortOption]="currentSortOption"
            [loading]="loading | async"
            [noContentText]="noContentText"
            (dataSourceChangeInfinityScroll)="onDataSourceChangeInfinityScroll($event)"
            (sortOptions)="onSortOptions($event)"
            (goTo)="onGoToEntry($event)"
            (deleteEntry)="onDeleteEntry($event)"
            (copyEmitter)="onCopy($event)"
            ></app-not-exported>
        </mat-tab>
        <mat-tab label="Exported {{ !currentIsSearching ?  '(' + (timeDestribution?.bookedTime | minutesToHours) + ')' : ''}}">
            <app-exported
            *ngIf="currentTab === enums.exported"
            [data]="data"
            [currentSortOption]="currentSortOption"
            [loading]="loading | async"
            [noContentText]="noContentText"
            (dataSourceChangeInfinityScroll)="onDataSourceChangeInfinityScroll($event)"
            (sortOptions)="onSortOptions($event)"
            (copyEmitter)="onCopy($event)"
            ></app-exported>
        </mat-tab>
    </mat-tab-group>
    <app-footer-member></app-footer-member>
</div>
