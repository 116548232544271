import { createAction, props } from '@ngrx/store';

import { IMemberDataContentSets } from '../../modules/shared/interfaces/member/member-set-paging.interface';

export const LOAD_EXPORTED_ACTIVITY_DATA = '[EXPORTED_ACTIVITY] LOAD_EXPORTED_ACTIVITY_DATA';
export const LOAD_EXPORTED_ACTIVITY_DATA_SUCCESS = '[EXPORTED_ACTIVITY] LOAD_EXPORTED_ACTIVITY_DATA_SUCCESS';
export const SORT_EXPORTED_ACTIVITY = '[EXPORTED_ACTIVITY] SORT_EXPORTED_ACTIVITY';
export const SCROLL_EXPORTED_ACTIVITY = '[EXPORTED_ACTIVITY] SCROLL_EXPORTED_ACTIVITY';
export const SEARCH_EXPORTED_ACTIVITY = '[EXPORTED_ACTIVITY] SEARCH_EXPORTED_ACTIVITY';
export const SEARCH_SORT_EXPORTED_ACTIVITY = '[EXPORTED_ACTIVITY] SEARCH_SORT_EXPORTED_ACTIVITY';
export const SEARCH_SCROLL_EXPORTED_ACTIVITY = '[EXPORTED_ACTIVITY] SEARCH_SCROLL_EXPORTED_ACTIVITY';

export const LoadActivitiesExported = createAction(
  LOAD_EXPORTED_ACTIVITY_DATA,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const LoadActivitiesExportedDataSuccessAction = createAction(
  LOAD_EXPORTED_ACTIVITY_DATA_SUCCESS,
  props<{ exportedTableItems: IMemberDataContentSets }>()
);

export const ScrollActivitiesExported = createAction(
  SCROLL_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const SortActivitiesExported = createAction(
  SORT_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number
  }>()
);

export const SearchActivitiesExported = createAction(
  SEARCH_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number,
    search: string
  }>()
);

export const SearchSortActivitiesExported = createAction(
  SEARCH_SORT_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number,
    search: string
  }>()
);

export const SearchScrollActivitiesExported = createAction(
  SEARCH_SCROLL_EXPORTED_ACTIVITY,
  props<{
    page: number,
    size: number,
    sort: string,
    startDate: number,
    endDate: number,
    search: string
  }>()
);

