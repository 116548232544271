import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CostsCentersMaintenanceComponent } from './costs-centers-maintenance.component';
import { CreateEditCostsCenterComponent } from './create-edit-costs-center/create-edit-costs-center.component';

const routes: Routes = [
  { path: '', component: CostsCentersMaintenanceComponent },
  { path: 'new', component: CreateEditCostsCenterComponent },
  { path: ':id', component: CreateEditCostsCenterComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CostsCentersMaintenanceRoutingModule { }
