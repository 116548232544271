import { IUser } from '../../modules/shared/interfaces/user/user.interface';

export const SET_USER_ACTIVE_DATA = '[ADMIN-PROJECTS] SET_USER_ACTIVE_DATA';
export const LOAD_USER_ACTIVE_DATA_SUCCESS = '[ADMIN-PROJECTS] LOAD_USER_ACTIVE_DATA_SUCCESS';

export class SetUserActive {
    readonly type = SET_USER_ACTIVE_DATA;
}

export class LoadUserActiveDataSuccessAction {
  readonly type = LOAD_USER_ACTIVE_DATA_SUCCESS;
  constructor(public userData: IUser) {}
}

export type Action =
SetUserActive |
LoadUserActiveDataSuccessAction;
